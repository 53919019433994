import React from "react";

export default function TitleBlock({ title, modelType,brand,model }) {
  return (
    <div className="carpassport-subheader-title-block">
      <div className="carpassport-subheader-title">{title}</div>
      <span className="carpassport-subheader-subtitle">{brand+" "+model+" "+modelType}</span>
    </div>
  );
}
