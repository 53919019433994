import React from 'react';

function CalenderIcon(props) {
    return (
        <div>
            
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.666016 1.83317C0.666016 1.19555 1.19506 0.666504 1.83268 0.666504H9.99935C10.637 0.666504 11.166 1.19555 11.166 1.83317V9.99984C11.166 10.6375 10.637 11.1665 9.99935 11.1665H1.83268C1.19506 11.1665 0.666016 10.6375 0.666016 9.99984V1.83317ZM9.99935 1.83315H1.83269V2.99981H9.99935V1.83315ZM1.83269 4.1665H2.99935V5.33317H1.83269V4.1665ZM5.33268 4.1665H4.16602V5.33317H5.33268V4.1665ZM6.49935 4.1665H7.66601V5.33317H6.49935V4.1665ZM9.99935 4.1665H8.83268V5.33317H9.99935V4.1665ZM1.83269 6.49986H2.99935V7.66653H1.83269V6.49986ZM5.33268 6.49986H4.16602V7.66653H5.33268V6.49986ZM6.49935 6.49986H7.66601V7.66653H6.49935V6.49986ZM9.99935 6.49986H8.83268V7.66653H9.99935V6.49986ZM1.83269 8.83315H2.99935V9.99981H1.83269V8.83315ZM5.33268 8.83315H4.16602V9.99981H5.33268V8.83315ZM6.49935 8.83315H7.66601V9.99981H6.49935V8.83315ZM9.99935 8.83315H8.83268V9.99981H9.99935V8.83315Z" fill="#90A0B7"/>
</svg>
        </div>
    );
}

export default CalenderIcon;