import React from 'react'
import './waitingIcon.css'

function WaitingIcon() {
    return (
        <div className="auction-icon-waiting-header">
            
<svg width="190" height="145" viewBox="0 0 190 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.2">
<path fill-rule="evenodd" clip-rule="evenodd" d="M93.125 116.108L90.2296 121.363L90.2384 121.368L90.2248 121.372L91.8028 126.797L86.3691 128.37L86.373 128.384L86.3643 128.379L83.4737 133.625L93.984 139.416L96.8746 134.169L96.8658 134.165L96.8794 134.161L95.3062 128.727L100.735 127.163L100.731 127.149L100.74 127.154L103.635 121.899L93.125 116.108ZM94.8864 133.634L93.1974 136.699L86.1906 132.839L87.8795 129.773L93.3132 128.2L94.8864 133.634ZM93.7958 127.324L92.2226 121.89L93.9116 118.825L100.918 122.685L99.2295 125.751L93.7958 127.324Z" fill="white"/>
</g>
<g opacity="0.2">
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.8711 72.8521L48.9757 78.1072L48.9845 78.112L48.9709 78.116L50.5489 83.5409L45.1152 85.1141L45.1191 85.1277L45.1104 85.1229L42.2198 90.3693L52.7301 96.16L55.6207 90.9136L55.6119 90.9088L55.6255 90.9049L54.0523 85.4712L59.4812 83.9067L59.4773 83.8932L59.486 83.898L62.3814 78.6428L51.8711 72.8521ZM53.6325 90.3777L51.9435 93.4432L44.9366 89.5827L46.6256 86.5172L52.0593 84.944L53.6325 90.3777ZM52.5419 84.0681L50.9687 78.6344L52.6577 75.5689L59.6646 79.4294L57.9756 82.4949L52.5419 84.0681Z" fill="white"/>
</g>
<path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M146 53.2051V74.9551H146.036L146 74.9913L160.5 89.4551L146 103.955L146.036 103.991H146V125.705H189.5V103.991H189.464L189.5 103.955L175 89.4551L189.5 74.9913L189.464 74.9551H189.5V53.2051H146Z" fill="white"/>
<g opacity="0.2">
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.0254 5.55371L35.2806 10.9181L35.2896 10.916L35.2827 10.927L39.6937 13.6575L36.9543 18.0706L36.9653 18.0774L36.9564 18.0795L38.2095 23.435L48.9383 20.9245L47.6852 15.569L47.6762 15.5711L47.6831 15.5601L43.27 12.8207L46.0115 8.41654L46.0005 8.40969L46.0094 8.4076L44.7542 3.04322L34.0254 5.55371ZM45.9995 16.4256L46.7318 19.5548L39.5793 21.2284L38.847 18.0992L41.5865 13.6861L45.9995 16.4256ZM41.3772 12.7921L36.9642 10.0526L36.2319 6.92342L43.3844 5.24976L44.1167 8.37898L41.3772 12.7921Z" fill="white"/>
</g>
<g opacity="0.2">
<path fill-rule="evenodd" clip-rule="evenodd" d="M131.221 6.69629L132.058 12.6376L132.067 12.6363L132.059 12.6476L136.576 16.0407L133.173 20.5595L133.185 20.568L133.175 20.5694L134.01 26.5008L145.893 24.8272L145.057 18.8957L145.047 18.8971L145.056 18.8858L140.537 15.4828L143.942 10.9739L143.93 10.9654L143.94 10.964L143.103 5.02266L131.221 6.69629ZM143.145 19.6599L143.633 23.1257L135.712 24.2414L135.223 20.7756L138.626 16.2569L143.145 19.6599ZM138.487 15.2666L133.968 11.8636L133.48 8.3978L141.402 7.28205L141.89 10.7478L138.487 15.2666Z" fill="white"/>
</g>
<path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M81 28.9746L84.3844 39.7519L84.4024 39.7463L84.39 39.7699L93.8256 44.6806L88.8969 54.1217L88.9205 54.1341L88.9026 54.1397L92.2814 64.8991L113.836 58.1303L110.457 47.3709L110.439 47.3765L110.452 47.3529L101.01 42.4243L105.945 33.0011L105.921 32.9888L105.939 32.9831L102.555 22.2058L81 28.9746Z" fill="white"/>
<path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M0 51.6758L1.73533 55.2149L1.74123 55.212L1.73822 55.2208L5.25164 56.4175L4.0491 59.9338L4.05789 59.9368L4.05199 59.9397L5.78443 63.4729L12.8627 60.0023L11.1303 56.469L11.1244 56.4719L11.1274 56.4631L7.61107 55.2606L8.81651 51.7502L8.80772 51.7472L8.81361 51.7443L7.07829 48.2051L0 51.6758Z" fill="white"/>
<path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M15 114.676L16.7353 118.215L16.7412 118.212L16.7382 118.221L20.2516 119.417L19.0491 122.934L19.0579 122.937L19.052 122.94L20.7844 126.473L27.8627 123.002L26.1303 119.469L26.1244 119.472L26.1274 119.463L22.6111 118.261L23.8165 114.75L23.8077 114.747L23.8136 114.744L22.0783 111.205L15 114.676Z" fill="white"/>
</svg>

        </div>
    )
}

export default WaitingIcon;




