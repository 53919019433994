import React, { Fragment, useEffect } from "react";
import _ from "lodash";
import "./StockMashup.css";
import HTTPService from "../../services/HTTPService";

export const StockMashupComponent = () => {
  // get full token
  const pegaEncryptedhash = process.env.REACT_APP_PEGA_APP_ENCRYPTED_HASH;
  const pegaChannelID = process.env.REACT_APP_PEGA_APP_CHANNNEL_ID;
  const pegaAppID = process.env.REACT_APP_PEGA_APP_URL;
  const arrayParam = localStorage.getItem("url");
  const hostURL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const url = hostURL + "/api/Login/VerifySession";
    const token = JSON.parse(localStorage.getItem("Token"));

    HTTPService.send(url, token).then((response) => {
      if (!_.isEmpty(response.data.value)) {
        if (window.pega) {
          window.pega.web.api.setAuthenticationParameters("PegaGadget", {
            AuthorizationToken: arrayParam,
            AccessToken: response.data.value,
          });
          window.pega.web.api.doAction("PegaGadget", "load");
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="stockContainer">
        <div
          data-pega-encrypted="true"
          data-pega-encrypted-hash={pegaEncryptedhash}
          data-pega-gadgetname="PegaGadget"
          data-pega-action="display"
          data-pega-action-param-harnessname="Dashboard"
          data-pega-action-param-classname="Data-Portal"
          data-pega-action-param-model=""
          data-pega-action-param-readonly="false"
          data-pega-isdeferloaded="false"
          data-pega-applicationname="StockMgmt"
          data-pega-threadname="UserPortalMashupauth"
          data-pega-channelid={pegaChannelID}
          data-pega-resizetype="stretch"
          data-pega-url={pegaAppID}
          data-pega-action-param-parameters='{"pzSkinName":"BovemijCosmos","pyMashupSkeletonName":"pyDefaultMashupSkeleton"}'
        ></div>
      </div>
    </Fragment>
  );
};
