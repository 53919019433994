import React, { useRef, useState, useEffect } from "react";
import dateFormat from "dateformat";
import moment from "moment";
import InfoIcon from "../../Icons/infoIcon";
import RoundBlankCheckbox from "../../Icons/roundBlankCheckbox";
import RoundCheckbox from "../../Icons/roundCheckbox";
import "./sortOfCards.css";
import { Popover } from "antd";
import "antd/dist/antd.css";
import AuctionTimer from "../../../atoms/ProgressBar/auctionTimer";
import BrandLogo from "../../../shared/BrandLogo/brandLogo";
import _ from "lodash";
import InConsiderationComponent from "./inConsiderationComponent";

function SortOfCards(props) {
  const {
    id,
    brandLogos,
    openForBidding,
    enddayofbidding,
    status,
    description,
    profileName,
    firstSelection,
    onChange,
    auctionStatus,
  } = props;
  const manualClickId = useRef(null);
  const checkboxAuctionRunningRef = useRef(null);
  let [click, setClick] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const enddateRef = useRef(null);
  const cardRef = useRef(null);
  let now = moment(new Date());
  let end = moment(enddayofbidding);
  let start = moment(openForBidding);
  let bidStartDate = moment.duration(start.diff(now)).asHours();
  let remainingdays = moment.duration(end.diff(now)).days();
  let totalhoursleft = remainingdays * 24;
  const [ClickedID, setClickID] = useState(null);

  const content = (
    <div>
      <p>
        {!_.isEmpty(description) ? (
          description
        ) : (
          <span className="auction-card-popover-description">
            Beschrijving van de veiling is nu niet beschikbaar.
          </span>
        )}
      </p>
    </div>
  );

  useEffect(() => {
    if (totalhoursleft && totalhoursleft >= 2) {
      setFlag(!flag);
    }
    // console.log("firstSelection", firstSelection);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstSelection && firstSelection === id) {
      if (ClickedID && ClickedID.current) {
        ClickedID?.current.click();
      }
    }
  }, [firstSelection, id, ClickedID]);

  const individualCardHandler = (id, event) => {
    setClick(!click);
    onChange(id);
  };

  const handleMouseMove = () => {
    setIsHovered(!isHovered);
  };

  return (
    <React.Fragment>
      {auctionStatus === "OpenForBidding" && end > now
        ? openForBiddingComponent()
        : null}
      {auctionStatus === "Published" ? publishedComponent() : null}

      {auctionStatus === "InConsideration" ? (
        <InConsiderationComponent
          openForBidding={openForBidding}
          enddayofbidding={enddayofbidding}
          profileName={profileName}
          enddateRef={enddateRef}
          end={end}
          start={start}
        ></InConsiderationComponent>
      ) : null}
    </React.Fragment>
  );

  function openForBiddingComponent() {
    return (
      <label
        // className="auctionControlvalue"
        className={
          bidStartDate > 0
            ? "auctionControlvalue auction-upcoming"
            : "auctionControlvalue auction-running"
        }
        htmlFor="auctionControl"
        ref={cardRef}
        onMouseEnter={handleMouseMove}
        onMouseLeave={handleMouseMove}
      >
        <div
          className="auction-filter-container" data-testid="openforBidding"
          onClick={individualCardHandler.bind(null, id)}
          ref={(el) => {
            setClickID(el);
            manualClickId.current = el;
          }}
        >
          <div className="auction-filter-container-body">
            <div className="auction-filter-semi-body-title"></div>
            <div className="auction-filter-semi-body-body"></div>
            <div class="auction-filter-container-body-header">
              {status ? (
                <RoundCheckbox
                  id="auctionControl"
                  checkboxRef={checkboxAuctionRunningRef}
                  className="checked"
                />
              ) : (
                isHovered && (
                  <RoundBlankCheckbox
                    id="auctionControl"
                    checkboxRef={checkboxAuctionRunningRef}
                    className="non-checked"
                  />
                )
              )}
            </div>
            <div className="auction-filter-body-numberofcars">
              <span className="auction-filter-body-numberofcar-title">
                Veiling
              </span>
              <span className="auction-filter-body-numberofcar-subtitle">
                {profileName}
              </span>
            </div>
            <div className="auction-filter-body-startDate">
              <span className="auction-filter-body-startDate-title">
                Startdatum/tijd
              </span>
              <span className="auction-filter-body-startDate-body">
                {openForBidding || openForBidding === "undefined"
                  ? dateFormat(openForBidding, "dd-mm-yy")
                  : null}
              </span>
              <span className="auction-filter-body-startDate-body-subTitle">
                {openForBidding || openForBidding === "undefined"
                  ? dateFormat(openForBidding, "HH")
                  : null}
                :{dateFormat(start, "MM")}
              </span>
            </div>
            <div className="auction-filter-body-endDate">
              <span className="auction-filter-body-startDate-title">
                Einddatum/tijd
              </span>
              <span className="auction-filter-body-startDate-body">
                {dateFormat(enddayofbidding, "dd-mm-yy")}
              </span>
              <span
                className="auction-filter-body-startDate-body-subTitle"
                ref={enddateRef}
              >
                {dateFormat(enddayofbidding, "HH")}:{dateFormat(end, "MM")}
              </span>
            </div>

            <div className="auction-filter-semi-body-title">
              <span>
                <BrandLogo length={5} urls={brandLogos} />
              </span>
            </div>
            <div className="auction-filter-semi-body-body">
              {bidStartDate > 0 ? (
                <div>
                  <span className="auction-avaiablecar-status-date">
                    Aankomende
                  </span>
                </div>
              ) : null}
            </div>
            {/* changed to onHover */}
            <div className="auction-filter-semi-body-icon">
              <Popover
                placement="topRight"
                content={content}
                className="popover-auction-card"
                overlayClassName="sortOfCardInfo"
              >
                <div>
                  <InfoIcon color="#ffffff" />
                </div>
              </Popover>
            </div>

            {bidStartDate < 0 ? (
              <AuctionTimer enddayofbidding={enddayofbidding} />
            ) : null}
          </div>
        </div>
      </label>
    );
  }

  function publishedComponent() {
    return (
      <label
        className={
          bidStartDate > 0
            ? "auctionControlvalue auction-upcoming"
            : "auctionControlvalue auction-running"
        }
        htmlFor="auctionControl"
        ref={cardRef}
        onMouseEnter={handleMouseMove}
        onMouseLeave={handleMouseMove}
      >
        <div
          className="auction-filter-container" data-testid="Published"
          onClick={individualCardHandler.bind(null, id)}
          ref={(el) => {
            setClickID(el);
            manualClickId.current = el;
          }}
        >
          <div className="auction-filter-container-body">
            <div className="auction-filter-semi-body-title"></div>
            <div className="auction-filter-semi-body-body"></div>
            <div class="auction-filter-container-body-header">
              {status ? (
                <RoundCheckbox
                  id="auctionControl"
                  checkboxRef={checkboxAuctionRunningRef}
                  className="checked"
                />
              ) : (
                isHovered && (
                  <RoundBlankCheckbox
                    id="auctionControl"
                    checkboxRef={checkboxAuctionRunningRef}
                    className="non-checked"
                  />
                )
              )}
            </div>
            <div className="auction-filter-body-numberofcars">
              <span className="auction-filter-body-numberofcar-title">
                Veiling
              </span>
              <span className="auction-filter-body-numberofcar-subtitle">
                {profileName}
              </span>
            </div>
            <div className="auction-filter-body-startDate">
              <span className="auction-filter-body-startDate-title">
                Startdatum/tijd
              </span>
              <span className="auction-filter-body-startDate-body">
                {openForBidding || openForBidding === "undefined"
                  ? dateFormat(openForBidding, "dd-mm-yy")
                  : null}
              </span>
              <span className="auction-filter-body-startDate-body-subTitle">
                {openForBidding || openForBidding === "undefined"
                  ? dateFormat(openForBidding, "HH")
                  : null}
                :{dateFormat(start, "MM")}
              </span>
            </div>
            <div className="auction-filter-body-endDate">
              <span className="auction-filter-body-startDate-title">
                Einddatum/tijd
              </span>
              <span className="auction-filter-body-startDate-body">
                {dateFormat(enddayofbidding, "dd-mm-yy")}
              </span>
              <span
                className="auction-filter-body-startDate-body-subTitle"
                ref={enddateRef}
              >
                {dateFormat(enddayofbidding, "HH")}:{dateFormat(end, "MM")}
              </span>
            </div>

            <div className="auction-filter-semi-body-title">
              <span>
                <BrandLogo length={5} urls={brandLogos} />
              </span>
            </div>
            <div className="auction-filter-semi-body-body">
              <div>
                <span className="auction-avaiablecar-status-date">
                  Aankomende
                </span>
              </div>
            </div>
            {/* changed to onHover */}
            <div className="auction-filter-semi-body-icon">
              <Popover
                placement="topRight"
                content={content}
                className="popover-auction-card"
                overlayClassName="sortOfCardInfo"
              >
                <div>
                  <InfoIcon color="#ffffff" />
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </label>
    );
  }
}
export default SortOfCards;
