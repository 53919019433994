import React, { useCallback } from "react";
import "./middleHeader.css";
import TitleBlock from "./PlaceBidSubHeaders/TitleBlock";
import CarLicensePlate from "./PlaceBidSubHeaders/LicensePlate";
import MiddleInfoDisplay from "./PlaceBidSubHeaders/MiddleInfoDisplay";

import MenuSubTitle from "./PlaceBidSubHeaders/menuSubTitle";
import SliderPrevArrow from "../DamageModal/SliderPrevArrow";
import SliderNextArrow from "../DamageModal/SliderNextArrow";

function MiddleHeader({
  licencePlate,
  modelType,
  model,
  brand,
  coachType,
  jaar,
  milage,
  fuelType,
  mileageUnits,
  modelVersionFrom,
  transmission,
  hadDamage,
  updateActivTabFn,
  onNext,
  onPrevious,
  indexDetails = { currentIndex: 0, total: 0 },
  isNavigationNeeded,
}) {
  let isDisabledPrev = indexDetails.currentIndex + 1 === 1;
  let isDisabledNext = indexDetails.currentIndex + 1 === indexDetails.total;
  const getPrevActionContent = useCallback(() => {
    if (isNavigationNeeded) {
      return (
        <div
          style={{ cursor: `${isDisabledPrev ? "default" : "pointer"}` }}
          onClick={() => onPrevious()}
        >
          <SliderPrevArrow
            width={"16"}
            height={"26"}
            color={isDisabledPrev ? "#DADADA" : "#90A0B7"}
            content={`M14.82 2.98023L12 0L0 12.6818L12 25.3636L14.82 22.3834L5.66 12.6818L14.82 2.98023Z`}
          />
        </div>
      );
    }
  }, [isDisabledPrev, isNavigationNeeded, onPrevious]);

  const getNextActionContent = useCallback(() => {
    if (isNavigationNeeded) {
      return (
        <div
          style={{ cursor: `${isDisabledNext ? "default" : "pointer"}` }}
          onClick={() => onNext()}
        >
          <SliderNextArrow
            width={"16"}
            height={"40"}
            color={isDisabledNext ? "#DADADA" : "#90A0B7"}
            content={`M0.179512 22.6995L2.99951 25.6797L14.9995 12.9979L2.99951 0.316051L0.179512 3.29628L9.33951 12.9979L0.179512 22.6995Z`}
          />
        </div>
      );
    }
  }, [isDisabledNext, isNavigationNeeded, onNext]);

  return (
    <div>
      {isNavigationNeeded ? (
        <div className="countSection carpassportCount">
          {indexDetails.currentIndex + 1}/{indexDetails.total}
        </div>
      ) : null}
      <div className="carpassport-middle-header">
        {getPrevActionContent()}
        <div className="carpassport-middle-header-container">
          <div className="carpassport-header-title">
            <div className={"headingBlock"}>
              <div className="titleBlock">
                <TitleBlock
                  title="Car passport"
                  brand={brand}
                  model={model}
                  modelType={modelType}
                />
              </div>
            </div>
          </div>
          <div className="carpassport-header-subtitle">
            <div className="carpassport-header-subtitle-licence">
              <CarLicensePlate licencePlate={licencePlate} />
            </div>
            <MiddleInfoDisplay
              coachType={coachType}
              fuelType={fuelType}
              modelType={modelType}
              jaar={jaar}
              milage={milage}
              mileageUnits={mileageUnits}
              transmission={transmission}
              hadDamage={hadDamage}
            />
          </div>
          <div className="carpassport-header-menu">
            <MenuSubTitle updateActiveTab={updateActivTabFn} />
          </div>
        </div>

        {getNextActionContent()}
      </div>
    </div>
  );
}

export default MiddleHeader;
