import React from 'react';
import dateFormat from "dateformat";
import InfoIcon from "../../Icons/infoIcon";
function InConsiderationComponent(props) {
    return (
      <label
        className="auctionControlvalue auction-closed"
        htmlFor="auctionControl"
      >
        <div className="auction-filter-container" data-testid="InConsideration">
          <div className="auction-filter-container-body closed">
            <div className="auction-filter-semi-body-title"></div>
            <div className="auction-filter-semi-body-body"></div>
            <div class="auction-filter-container-body-header"></div>
            <div className="auction-filter-body-numberofcars">
              <span className="auction-filter-body-numberofcar-title">
                Veiling
              </span>
              <span className="auction-filter-body-numberofcar-subtitle closed">
                {props.profileName}
              </span>
            </div>
            <div className="auction-filter-body-startDate">
              <span className="auction-filter-body-startDate-title">
                Startdatum/tijd
              </span>
              <span className="auction-filter-body-startDate-body closed">
                {props.openForBidding || props.openForBidding === "undefined"
                  ? dateFormat(props.openForBidding, "dd-mm-yy")
                  : null}
              </span>
              <span className="auction-filter-body-startDate-body-subTitle closed">
                {props.openForBidding || props.openForBidding === "undefined"
                  ? dateFormat(props.openForBidding, "HH")
                  : null}
                :{dateFormat(props.start, "MM")}
              </span>
            </div>
            <div className="auction-filter-body-endDate">
              <span className="auction-filter-body-startDate-title">
                Einddatum/tijd
              </span>
              <span className="auction-filter-body-startDate-body closed">
                {dateFormat(props.enddayofbidding, "dd-mm-yy")}
              </span>
              <span
                className="auction-filter-body-startDate-body-subTitle closed"
                ref={props.enddateRef}
              >
                {dateFormat(props.enddayofbidding, "HH")}:
                {dateFormat(props.end, "MM")}
              </span>
            </div>
  
            <div className="auction-filter-semi-body-title">
              <span>{/* <BrandLogo length={5} urls={brandLogos} /> */}</span>
            </div>
            <div className="auction-filter-semi-body-body">
              <div>
                <span className="auction-avaiablecar-status-date closed">
                Gesloten
                </span>
              </div>
            </div>
            {/* changed to onHover */}
            <div className="auction-filter-semi-body-icon">
              <InfoIcon color="#ffffff" />
            </div>
          </div>
        </div>
      </label>
    );
  }

export default InConsiderationComponent;