import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import "./CarpassportDetails.css";
import SpecificationBlock from "./SpecificationBlock/specificationBlock";
import OptiesContainer from "./CarPassportBody/OptiesContainer/OptiesContainer";
import FinancienContainer from "./CarPassportBody/FinancienContainer/FinancienContainer";
import AnderenContainer from "./CarPassportBody/AnderenContainer/AnderenContainer";
import SpecificatiesContainer from "./CarPassportBody/SpecificatiesContainer/SpecificatiesContainer";
import InformatieOverBandenContainer from "./CarPassportBody/InformatieOverBandenContainer/InformatieOverBandenContainer";
import RegistratieInformatieContainer from "./CarPassportBody/RegistratieInformatieContainer/RegistratieInformatieContainer";
import Toelichting from "./CarPassportBody/ToelichtingSection/Toelichting";
import DamageContainer from "./Damage/DamageContainer/DamageContainer";
import DamageDialogComponent from "./DamageModal/DamageDialogComponent";

export const CarpassportDetails = (props) => {
  let noDataForField = "-";
  const [selectedVehicleId, setSelectedVehicleId] = useState({});
  const [groupedOpticalDamageList, setGroupedOpticalDamageList] = useState([]);
  const [sliderGroupOpticalDamageList, setSliderGroupedOpticalDamageList] =
    useState([]);
  const [isTechnicalDamageOpen, setTechnicalDamageOpen] = useState(false);
  const [isOpticalDamageOpen, setOpticalDamageOpen] = useState(false);
  const [techDamageSelectedIndex, setTechDamageSelectedIndex] = useState(0);
  const [opticalDamageSelectedIndex, setOpticalDamageSelectedIndex] =
    useState(0);

  const addUniqueIdInData = useCallback((vehicleList) => {
    if (_.get(vehicleList, "technicalDamages")) {
      const technicalDamage = vehicleList.technicalDamages.map(
        (damage, index) => {
          damage.id = index + Math.random();
          return damage;
        }
      );
      _.set(vehicleList, "technicalDamages", technicalDamage);
    }

    if (_.get(vehicleList, "opticalDamages")) {
      const opticalDamage = vehicleList.opticalDamages.map((damage, index) => {
        damage.id = index + Math.random();
        return damage;
      });
      _.set(vehicleList, "opticalDamages", opticalDamage);
    }
    setSelectedVehicleId(vehicleList);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(props)) {
      addUniqueIdInData(props);
      setSelectedVehicleId(props);
    }
  }, [addUniqueIdInData, props]);

  const getGroupedDamageByList = useCallback((damages = []) => {
    let slideOpticalList = [];
    const OpticalDamageTitle = damages.map(
      (damageDetails) => damageDetails.part
    );
    const uniqueOpticalDamageTitle = [...new Set(OpticalDamageTitle)];
    const groupedDamage = uniqueOpticalDamageTitle.map((title) => {
      let groupedDamageObj = {};
      let damagesList = damages.filter((damage) => damage.part === title);
      groupedDamageObj[title] = damagesList;
      slideOpticalList.push(...damagesList);
      return groupedDamageObj;
    });
    setSliderGroupedOpticalDamageList(slideOpticalList);
    setGroupedOpticalDamageList(groupedDamage);
  }, []);

  useEffect(() => {
    getGroupedDamageByList(
      selectedVehicleId && selectedVehicleId.opticalDamages
        ? selectedVehicleId.opticalDamages
        : []
    );
  }, [getGroupedDamageByList, selectedVehicleId]);

  const technicalDamageSliderHandler = (damagedItem) => {
    setTechnicalDamageOpen(true);
    const index = _.findIndex(selectedVehicleId.technicalDamages, damagedItem);
    setTechDamageSelectedIndex(index);
  };

  const opticalDamageSliderHandler = (damagedItem) => {
    setOpticalDamageOpen(true);
    const index = _.findIndex(sliderGroupOpticalDamageList, damagedItem);
    setOpticalDamageSelectedIndex(index);
  };

  let nonGenericOptions =
    selectedVehicleId.nonGenericOptions === undefined
      ? "Onbekend"
      : selectedVehicleId?.nonGenericOptions;

  return (
    <div className="carpassportGrid">
      <div className="carpassport-container-wrapper-main">
        <div className="carpassport-container-wrapper-first">
          <div className="carpassport-container-slider">
            <SpecificationBlock
              medias={
                !_.isEmpty(selectedVehicleId.allMedias)
                  ? selectedVehicleId?.allMedias
                  : null
              }
              modelType={selectedVehicleId?.modelType}
            />
          </div>
          <div className="carpassport-container-financien">
            <FinancienContainer
              nieuwprijs={
                _.isEmpty(selectedVehicleId.catalogPrice)
                  ? selectedVehicleId?.catalogPrice
                  : noDataForField
              }
              vatDeductable={
                _.isEmpty(selectedVehicleId.vatDeductable)
                  ? selectedVehicleId?.vatDeductable
                  : noDataForField
              }
              bpm={
                _.isEmpty(selectedVehicleId.bpm)
                  ? selectedVehicleId?.bpm
                  : noDataForField
              }
              restbpm={
                _.isEmpty(selectedVehicleId.restbpm)
                  ? selectedVehicleId?.restbpm
                  : noDataForField
              }
            />
          </div>
          <div className="carpassport-container-anderen">
            <AnderenContainer
              noDataForFieldProps={noDataForField}
              firstRegistration={
                !_.isEmpty(selectedVehicleId.firstRegistrationInt)
                  ? selectedVehicleId?.firstRegistrationInt
                  : noDataForField
              }
              modelVersionFrom={
                !_.isEmpty(selectedVehicleId.modelVersionFrom)
                  ? selectedVehicleId.modelVersionFrom
                  : noDataForField
              }
              currentCoutryOfRegistation={
                !_.isEmpty(selectedVehicleId.currentCoutryOfRegistation)
                  ? selectedVehicleId.currentCoutryOfRegistation
                  : noDataForField
              }
              firstRegistrationNat={
                !_.isEmpty(selectedVehicleId.firstRegistrationNat)
                  ? selectedVehicleId.firstRegistrationNat
                  : noDataForField
              }
              firstRegistrationInt={
                !_.isEmpty(selectedVehicleId.firstRegistrationInt)
                  ? selectedVehicleId.firstRegistrationInt
                  : noDataForField
              }
              wasEverTaxiCab={
                !_.isEmpty(selectedVehicleId.wasEverTaxiCab)
                  ? selectedVehicleId.wasEverTaxiCab
                  : noDataForField
              }
              wasEverDriverEducationCar={
                !_.isEmpty(selectedVehicleId.wasEverDriverEducationCar)
                  ? selectedVehicleId.wasEverDriverEducationCar
                  : noDataForField
              }
              wasEverGovermentCar={
                !_.isEmpty(selectedVehicleId.wasEverGovermentCar)
                  ? selectedVehicleId.wasEverGovermentCar
                  : noDataForField
              }
              hasWokStatusNl={
                !_.isEmpty(selectedVehicleId.hasWokStatusNl)
                  ? selectedVehicleId.hasWokStatusNl
                  : noDataForField
              }
              apkExpirationdateNl={
                !_.isEmpty(selectedVehicleId.apkExpirationdateNl)
                  ? selectedVehicleId.apkExpirationdateNl
                  : noDataForField
              }
            />
          </div>
          <div className="carpassport-container-Informatieoverbanden">
            <InformatieOverBandenContainer
              type={
                !_.isEmpty(selectedVehicleId.tyres)
                  ? selectedVehicleId.tyres
                  : noDataForField
              }
              lastMaintenance={
                !_.isEmpty(selectedVehicleId.lastMaintenance)
                  ? selectedVehicleId.lastMaintenance
                  : noDataForField
              }
              distributieriem={
                !_.isEmpty(selectedVehicleId.timingbeltReplacedDate) || !_.isEmpty(selectedVehicleId.timingbeltReplacedMilage)
                  ? "Ja"
                  :"Nee"
              }
              lotId={
                // selectedVehicleId.tyres
                !_.isEmpty(selectedVehicleId.lotID)
                  ? selectedVehicleId.lotID
                  : noDataForField
              }
            />
          </div>
        </div>
        <div className="carpassport-container-wrapper-second">
          <div className="carpassport-container-block-second">
            {/**Notes Sections Starts here* */}
            {selectedVehicleId && !_.isEmpty(selectedVehicleId.remarks) ? (
              <Toelichting remarks={selectedVehicleId.remarks} />
            ) : null}
            {/**Notes Sections Ends here* */}
            {/* optical */}
            <DamageDialogComponent
              damages={sliderGroupOpticalDamageList}
              isDialogOpen={isOpticalDamageOpen}
              setDialogOpen={setOpticalDamageOpen}
              selectedIndex={opticalDamageSelectedIndex}
              titleText={"Zichtbare Schade"}
              showRightReport={false}
            />
            <DamageContainer
              damageList={groupedOpticalDamageList}
              title={"Zichtbare schade"}
              noContentText="Geen zichtbare schade bekend"
              sliderHandler={opticalDamageSliderHandler}
              damageType={"optical"}
              damageCount={
                selectedVehicleId && selectedVehicleId.opticalDamages
                  ? selectedVehicleId && selectedVehicleId.opticalDamages.length
                  : 0
              }
            />

            {/* TechnicalDamage */}
            <DamageDialogComponent
              damages={selectedVehicleId.technicalDamages}
              isDialogOpen={isTechnicalDamageOpen}
              setDialogOpen={setTechnicalDamageOpen}
              selectedIndex={techDamageSelectedIndex}
              titleText={"Technische schade"}
              showRightReport={false}
            />
            <DamageContainer
              damageList={selectedVehicleId.technicalDamages}
              title={"Technische schade"}
              noContentText={"Geen technische schade bekend"}
              sliderHandler={technicalDamageSliderHandler}
              damageType={"technical"}
              damageCount={
                selectedVehicleId && selectedVehicleId.technicalDamages
                  ? selectedVehicleId &&
                    selectedVehicleId.technicalDamages.length
                  : 0
              }
            />
            <div className="carpassport-container-opties">
              <OptiesContainer
                nonGenericOptionsProp={
                  !_.isEmpty(nonGenericOptions)
                    ? nonGenericOptions
                    : noDataForField
                }
                airco={
                  _.isEmpty(selectedVehicleId?.genericOptions?.airco)
                    ? selectedVehicleId?.genericOptions?.airco
                    : noDataForField
                }
                ecc={
                  _.isEmpty(selectedVehicleId?.genericOptions?.ecc)
                    ? selectedVehicleId?.genericOptions?.ecc
                    : noDataForField
                }
                audioInstallation={
                  _.isEmpty(
                    selectedVehicleId?.genericOptions?.audioInstallation
                  )
                    ? selectedVehicleId?.genericOptions?.audioInstallation
                    : noDataForField
                }
                centralLock={
                  _.isEmpty(selectedVehicleId?.genericOptions?.centralLock)
                    ? selectedVehicleId?.genericOptions?.centralLock
                    : noDataForField
                }
                cruiseControl={
                  _.isEmpty(selectedVehicleId?.genericOptions?.cruiseControl)
                    ? selectedVehicleId?.genericOptions?.cruiseControl
                    : noDataForField
                }
                heatedSeats={
                  _.isEmpty(selectedVehicleId?.genericOptions?.heatedSeats)
                    ? selectedVehicleId?.genericOptions?.heatedSeats
                    : noDataForField
                }
                automatedSeats={
                  _.isEmpty(selectedVehicleId?.genericOptions?.automatedSeats)
                    ? selectedVehicleId?.genericOptions?.automatedSeats
                    : noDataForField
                }
                electricWindows={
                  _.isEmpty(selectedVehicleId?.genericOptions?.electricWindows)
                    ? selectedVehicleId?.genericOptions?.electricWindows
                    : noDataForField
                }
                navigationBig={
                  _.isEmpty(selectedVehicleId?.genericOptions?.navigationBig)
                    ? selectedVehicleId?.genericOptions?.navigationBig
                    : noDataForField
                }
                navigationSmall={
                  _.isEmpty(selectedVehicleId?.genericOptions?.navigationSmall)
                    ? selectedVehicleId?.genericOptions?.navigationSmall
                    : noDataForField
                }
                startStop={
                  _.isEmpty(selectedVehicleId?.genericOptions?.startStop)
                    ? selectedVehicleId?.genericOptions?.startStop
                    : noDataForField
                }
                powerSteering={
                  _.isEmpty(selectedVehicleId?.genericOptions?.powerSteering)
                    ? selectedVehicleId?.genericOptions?.powerSteering
                    : noDataForField
                }
                roofRail={
                  _.isEmpty(selectedVehicleId?.genericOptions?.roofRail)
                    ? selectedVehicleId?.genericOptions?.roofRail
                    : noDataForField
                }
                ledXenon={
                  _.isEmpty(selectedVehicleId?.genericOptions?.ledXenon)
                    ? selectedVehicleId?.genericOptions?.ledXenon
                    : noDataForField
                }
                alloywheels={
                  !_.isEmpty(selectedVehicleId?.genericOptions?.alloywheels)
                    ? selectedVehicleId?.genericOptions?.alloywheels
                    : noDataForField
                }
                airsuspension={
                  _.isEmpty(selectedVehicleId?.genericOptions?.airsuspension)
                    ? selectedVehicleId?.genericOptions?.airsuspension
                    : noDataForField
                }
                foglights={
                  _.isEmpty(selectedVehicleId?.genericOptions?.foglights)
                    ? selectedVehicleId?.genericOptions?.foglights
                    : noDataForField
                }
                pdc={
                  _.isEmpty(selectedVehicleId?.genericOptions?.pdc)
                    ? selectedVehicleId?.genericOptions?.pdc
                    : noDataForField
                }
                sunroof={
                  _.isEmpty(selectedVehicleId?.genericOptions?.sunroof)
                    ? selectedVehicleId?.genericOptions?.sunroof
                    : noDataForField
                }
                xenon={
                  _.isEmpty(selectedVehicleId?.genericOptions?.xenon)
                    ? selectedVehicleId?.genericOptions?.xenon
                    : noDataForField
                }
                led={
                  _.isEmpty(selectedVehicleId?.genericOptions?.led)
                    ? selectedVehicleId?.genericOptions?.led
                    : noDataForField
                }
              />
            </div>

            <div className="carpassport-container-specificaties">
              <SpecificatiesContainer
                kenteken={
                  !_.isEmpty(selectedVehicleId.licenceplate)
                    ? selectedVehicleId.licenceplate
                    : ""
                }
                merk={
                  !_.isEmpty(selectedVehicleId.brand)
                    ? selectedVehicleId.brand
                    : ""
                }
                model={
                  !_.isEmpty(selectedVehicleId.model)
                    ? selectedVehicleId.model
                    : ""
                }
                modelType={
                  !_.isEmpty(selectedVehicleId.modelType)
                    ? selectedVehicleId.modelType
                    : ""
                }
                coachType={
                  !_.isEmpty(selectedVehicleId.coachType)
                    ? selectedVehicleId.coachType
                    : ""
                }
                bouwjaar={
                  !_.isEmpty(selectedVehicleId.firstRegistrationInt)
                    ? selectedVehicleId.firstRegistrationInt
                    : ""
                }
                mileage={
                  _.isEmpty(selectedVehicleId.mileage)
                    ? selectedVehicleId.mileage
                    : ""
                }
                mileageUnits={
                  !_.isEmpty(selectedVehicleId.mileageUnits)
                    ? selectedVehicleId.mileageUnits
                    : ""
                }
                brandstof={
                  !_.isEmpty(selectedVehicleId.fuelType)
                    ? selectedVehicleId?.fuelType
                    : ""
                }
                transmission={
                  !_.isEmpty(selectedVehicleId.transmission)
                    ? selectedVehicleId.transmission
                    : ""
                }
                vinNumber={
                  !_.isEmpty(selectedVehicleId.vinNumber)
                    ? selectedVehicleId.vinNumber
                    : ""
                }
                numberOfKeys={
                  _.isEmpty(selectedVehicleId.numberOfKeys)
                    ? selectedVehicleId?.numberOfKeys
                    : ""
                }
                vehicleType={
                  !_.isEmpty(selectedVehicleId.vehicleType)
                    ? selectedVehicleId?.vehicleType
                    : ""
                }
                exteriorColor={
                  !_.isEmpty(selectedVehicleId.exteriorColor)
                    ? selectedVehicleId.exteriorColor
                    : ""
                }
                doors={
                  _.isEmpty(selectedVehicleId.doors)
                    ? selectedVehicleId.doors
                    : ""
                }
                energyLabel={
                  !_.isEmpty(selectedVehicleId.energyLabel)
                    ? selectedVehicleId?.energyLabel
                    : ""
                }
                cvoCo2={
                  _.isEmpty(selectedVehicleId.cvoCo2)
                    ? selectedVehicleId.cvoCo2
                    : ""
                }
                driveType={
                  !_.isEmpty(selectedVehicleId.driveType)
                    ? selectedVehicleId?.driveType
                    : ""
                }
                numberOfGears={
                  !_.isEmpty(selectedVehicleId.numberOfGears)
                    ? selectedVehicleId.numberOfGears
                    : ""
                }
                horsePower={
                  _.isEmpty(selectedVehicleId.power)
                    ? selectedVehicleId.power
                    : ""
                }
                cylinders={
                  _.isEmpty(selectedVehicleId.cylinders)
                    ? selectedVehicleId.cylinders
                    : ""
                }
                displacement={
                  _.isEmpty(selectedVehicleId.displacement)
                    ? selectedVehicleId?.displacement
                    : ""
                }
                torque={
                  !_.isEmpty(selectedVehicleId.torque)
                    ? selectedVehicleId.torque
                    : ""
                }
              />
            </div>

            <div className="carpassport-container-registrationinformation">
              <RegistratieInformatieContainer
                registrations={
                  !_.isEmpty(selectedVehicleId?.registrations)
                    ? selectedVehicleId?.registrations
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
