import React from 'react';

function GearboxIcon(props) {
    return (
        <div>
            
<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.29167 1.375C1.78541 1.375 1.375 1.78541 1.375 2.29167L1.375 2.29165C1.37525 2.61888 1.54993 2.92116 1.83332 3.08479L1.83333 4.96468C1.82515 5.01418 1.82515 5.06468 1.83333 5.11418V6.99943L1.83333 6.99943C1.55022 7.16288 1.37558 7.46475 1.375 7.79167H1.375C1.375 8.29793 1.78541 8.70833 2.29167 8.70833H2.29167C2.79793 8.70833 3.20833 8.29793 3.20833 7.79167V7.79168C3.20808 7.46445 3.03341 7.16217 2.75002 6.99854L2.75 5.5H5.04167V6.99853C4.75827 7.16215 4.58359 7.46443 4.58333 7.79167C4.58333 8.29793 4.99374 8.70833 5.5 8.70833H5.5C6.00626 8.70833 6.41667 8.29793 6.41667 7.79167V7.79167C6.41609 7.46476 6.24146 7.16289 5.95834 6.99943L5.95833 5.5H8.25V7.9161C7.96688 8.07955 7.79224 8.38142 7.79167 8.70833C7.79167 9.21459 8.20207 9.625 8.70833 9.625C9.21459 9.625 9.625 9.21459 9.625 8.70833C9.625 8.70833 9.625 8.70833 9.625 8.70833V8.70835C9.62475 8.38112 9.45007 8.07884 9.16668 7.91521L9.16667 5.11865V5.11865C9.17485 5.06916 9.17485 5.01865 9.16667 4.96916V3.08391V3.08391C9.44978 2.92045 9.62442 2.61858 9.625 2.29167V2.29167C9.625 1.78541 9.21459 1.375 8.70833 1.375C8.20207 1.375 7.79167 1.78541 7.79167 2.29167C7.79167 2.29167 7.79167 2.29167 7.79167 2.29167V2.29165C7.79192 2.61888 7.96659 2.92117 8.24998 3.08479L8.25 4.58333H5.95833V3.08391V3.08391C6.24145 2.92045 6.41609 2.61858 6.41667 2.29167V2.29167C6.41667 1.78541 6.00626 1.375 5.5 1.375C4.99374 1.375 4.58333 1.78541 4.58333 2.29167C4.58333 2.29167 4.58333 2.29167 4.58333 2.29167V2.29165C4.58359 2.61889 4.75826 2.92117 5.04165 3.08479L5.04167 4.58334H2.75V3.08391H2.75C3.03312 2.92045 3.20776 2.61858 3.20833 2.29167V2.29167C3.20833 1.78541 2.79793 1.375 2.29167 1.375L2.29167 1.375Z" fill="#90A0B7"/>
</svg>
        </div>
    );
}

export default GearboxIcon;