import * as React from 'react';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '../../Icons/infoIcon';
import { Typography } from '@mui/material';
import './popoverPopupState.css'

export default function PopoverPopupState() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}> */}
    
        {/* <Tooltip> */}
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            
          >
             <InfoIcon />

          </IconButton>
        {/* </Tooltip> */}
      {/* </Box> */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
       
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            bgcolor:'#000',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
              bgcolor: '#000',
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: '#000',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
     
      >
    
    <div style={{ fontSize: '13px', textAlign:'center',marginTop:'8px',marginBottom:'8px', width:'450px', padding:'0px 24px', backgroundColor:'#000000',color:'#fcfcfc',fontFamily: "GTWalsheimPro-Regular"}}>
      <Typography variant='h5' style={{color:'#fff', fontFamily: "GTWalsheimPro-Regular"}}>Kostenoverzicht</Typography>
     
      <p className="popover-place-bid-message-text">
        {" "}
        (Bod is altijd incl btw wanneer van toepassing.)
      </p>
      <hr/>
      <div style={{ marginTop: '6px',fontSize: '8px'}}>
      <p className="popover-content-text-info" style={{fontWeight:'600', fontSize:'14px'}}>
        {" "}
        Biedingsbedrag(incl btw) <span className="popover-text-value1">Kostenkoper (excl btw) </span>
      </p>
      <p className="popover-content-text-info-second">
      € 0,00 en € 5.000,-
        <span className="popover-text-value-second1"> € 280,- </span>
      </p>
      <p className="popover-content-text-info-third">
      € 5.001,- en € 10.000,-
        <span className="popover-text-value-second2">€ 330,- </span>
      </p>
      <p className="popover-content-text-info-fourth">
      € 10.001,- en € 15.000,-
        <span className="popover-text-value-second3">€ 405,- </span>
      </p>
      <p className="popover-content-text-info-fourth">
      € 15.001,- en € 20.000,- 
        <span className="popover-text-value-second4">€ 455,- </span>
      </p>
      <p className="popover-content-text-info-fourth">
      Meer dan € 20.001,-  
        <span className="popover-text-value-second5">€ 530,- </span>
      </p>
      <hr/>
      <p className="popover-content-text-info" style={{fontWeight:'600', fontSize:'14px'}}>Transportkosten (excl btw)</p>
      <p className="pocket-popover">
        <span>Personenauto</span>
        <span> € 99,-</span>
      </p>

      <p className="pocket-popover">
        <span>Kleine bedrijfswagen</span>
        <span> € 109,-</span>
      </p>
      <p className="pocket-popover">
        <span>Grote bedrijfswagen</span>
        <span> € 159,-</span>
      </p>

      <p className="pocket-popover" style={{paddingBottom:'24px'}}>
        Overige maten op basis van calculatie
      </p>

    </div>
     
    </div>
      </Menu>
    </React.Fragment>
  );
}