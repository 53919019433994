import React, { useEffect, useState } from "react";

function MybidsTotalValue() {
  const getToken = localStorage.getItem("Token");
  const getUserid = localStorage.getItem("userid");

  const [rowViewdata, setRowViewdata] = useState([]);
  const [rowCanceldata, setRowCanceldata] = useState([]);
  useEffect(() => {
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    const hostURL = process.env.REACT_APP_SERVER_URL;
    fetch(
      hostURL + `/api/bidmanager/Bids/All/${JSON.parse(getUserid)}`,
      requestGetOptions
    )
      .then((response) => response.json())
      .then((data) => {

        let activeBid = [];
        let cancelledBid = [];
        data?.pxResults.map((rowApi) => {
          if (
            rowApi.bidInfoPage.bidStatus === "Bid Placed" ||
            rowApi.bidInfoPage.bidStatus === "AWAITING RESULTS" ||
            rowApi.bidInfoPage.bidStatus === "To be Confirmed" ||
            rowApi.bidInfoPage.bidStatus === "Won" ||
            rowApi.bidInfoPage.bidStatus === "Lost" ||
            rowApi.bidInfoPage.bidStatus === "Fail"
          ) {
            setRowViewdata(activeBid.push(rowApi));
          }
          
          if (rowApi.bidInfoPage.bidStatus === "Cancelled") {
            setRowCanceldata(cancelledBid.push(rowApi));
          }
          
        });
      });
  }, []);
  
  return (
    <div data-testid = "mybids-total-value-container">
      {rowViewdata} biedingen | {rowCanceldata} gearchiveerd
    </div>
  );
}

export default MybidsTotalValue;
