import React from 'react';

function MilageIcon(props) {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M3.22222 3.3335C2.54817 3.3335 2 3.88166 2 4.55572V11.8891C2 12.5631 2.54817 13.1113 3.22222 13.1113H13C13.6741 13.1113 14.2222 12.5631 14.2222 11.8891V4.55572C14.2222 3.88166 13.6741 3.3335 13 3.3335H3.22222ZM3.22223 4.55572H13L13.0012 11.8891H3.2222V4.55572H3.22223ZM3.83333 10.6668V5.77794H4.75V10.6668H3.83333ZM6.27778 5.77794C5.94044 5.77794 5.66667 6.05172 5.66667 6.38905V10.0557C5.66667 10.3931 5.94044 10.6668 6.27778 10.6668H8.11111C8.44845 10.6668 8.72222 10.3931 8.72222 10.0557V6.38905C8.72222 6.05172 8.44845 5.77794 8.11111 5.77794H6.27778ZM9.33333 6.38905C9.33333 6.05172 9.60711 5.77794 9.94445 5.77794H11.7778C12.1151 5.77794 12.3889 6.05172 12.3889 6.38905V10.0557C12.3889 10.3931 12.1151 10.6668 11.7778 10.6668H9.94445C9.60711 10.6668 9.33333 10.3931 9.33333 10.0557V6.38905ZM6.58333 6.69461H7.80556V9.75016H6.58333V6.69461ZM11.4722 6.69461H10.25V9.75016H11.4722V6.69461Z" fill="#90A0B7"/>
</svg>
        </div>
    );
}

export default MilageIcon;