import React, { useState, useEffect } from "react";
import moment from "moment";
import Hours from "../../atoms/Timer/hours";
import Minutes from "../../atoms/Timer/minutes";
import Seconds from "../../atoms/Timer/seconds";

function MybidsTimer({
  totalhoursleft,
  hours,
  minutes,
  seconds,
  enddayofbidding,
}) {
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  // let _d = enddayofbidding._d

  let interval;
  // let end = moment(enddayofbidding);
  // let start = moment(new Date(openForBidding));
  // let startAuctiondateminutes = start.minutes();
  // let closeAuctiondateminutes = end.minutes();

  const startTimer = () => {
    let countDownDate = new Date(enddayofbidding).getTime();

    interval = setInterval(() => {
      // test case start
      let now = moment(new Date().getTime());

      const distance = countDownDate - now;
      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        var remainingtimesecondss = Math.floor(distance / 1000);
        var remainingtimeminutes = Math.floor(remainingtimesecondss / 60);
        var remainingtimehours = Math.floor(remainingtimeminutes / 60);


        remainingtimeminutes %= 60;
        remainingtimesecondss %= 60;

        setTimerHours(remainingtimehours);
        setTimerMinutes(remainingtimeminutes);
        setTimerSeconds(remainingtimesecondss);
        // till this
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
  });

  if (totalhoursleft <= 0 && hours <= 0) {
    // one test case
    hours = 0;
    minutes = 0;
    seconds = 0;
    // till this
  }

  return (
    <div className="mybids-timer-container" data-testid="timerTable-container">
      <Hours hours={timerHours !== undefined ? timerHours.toString() : ""} />
      :
      <Minutes
        hours={timerHours !== undefined ? timerHours.toString() : ""}
        minutes={timerMinutes !== undefined ? timerMinutes.toString() : ""}
      />
      :
      <Seconds
        hours={timerHours !== undefined ? timerHours.toString() : ""}
        seconds={timerSeconds !== undefined ? timerSeconds.toString() : ""}
      />
    </div>
  );
}

export default MybidsTimer;
