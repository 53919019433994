import React, { useState } from "react";
import "antd/dist/antd.css";
import { Card, Rate } from "antd";
import { StarOutlined } from "@ant-design/icons";

import "./singleLotsCard.css";
import CardCarousal from "./cardCarousal";
import LicencePlate from "../../shared/LicencePlate/licencePlate";
import SingleLotCardBody from "./singleLotCardBody";
import SingleLotCardFooter from "./singleLotCardFooter";

function SingleLotCard(props) {
  const [favrate, setFavorte] = useState(false);
  const checkHandler = () => {
    setFavorte(!favrate);
  };
  const customIcons = {
    1: <StarOutlined />,
  };

  return (
    <div data-testid="singleLotCardContainer">
      <Card
        style={{ width: "310px" }}
        className={
          (props.status === "OpenForBidding" && props.bidInfoPage === null) ||
          props.bidInfoPage?.bidAmount === null
            ? "running"
            : (props.status === "Published") ? "upcoming" : "update"
        }
      >
        <div className="singleLotCard-card-title">
          <CardCarousal medias={props.medias} />
          <LicencePlate licencePlate={props.licencePlate} />
          <Rate
            defaultValue={3}
            character={({ index }) => customIcons[index]}
          />
        </div>

        <div className="singleLotCard-card-body">
          <SingleLotCardBody
            id={props.id}
            lotID={props.lotID}
            vehiclesID={props.vehiclesID}
            model={props.model}
            modelType={props.modelType}
            brand={props.brand}
            coachType={props.coachType}
            fuelType={props.fuelType}
            jaar={props?.jaar}
            milage={props.milage}
            transmission={props.transmission}
            openForBidding={props.openForBidding}
            minBidExpirationDays={props.minBidExpirationDays}
            licencePlate={props.licencePlate}
            damagesCount={props.damagesCount}
            bidInfoPage={props.bidInfoPage}
            status={props.status}
            accesstoken={props.accesstoken}
            userid={props.userid}
            enddayofbidding={props.enddayofbidding}
            minBid={props.minBid}
            version={props?.version}
            vehiclesDetail={props?.vehiclesDetail}
          />
        </div>
        <div className="singleLotCard-card-footer">
          <SingleLotCardFooter />
        </div>
      </Card>
    </div>
  );
}

export default SingleLotCard;
