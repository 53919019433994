import React from 'react';
import './runningIcon.css'

function RunningIcon(props) {
    return (
        <div className="auction-icon-header">
            <svg width="344" height="139" viewBox="0 0 344 176" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.2">
<path fill-rule="evenodd" clip-rule="evenodd" d="M243.375 151.875H295.875V160.625H243.375V151.875ZM261.925 95.3062L274.306 82.925L336.169 144.787L323.787 157.169L261.925 95.3062ZM255.756 101.475L280.519 126.237L268.137 138.619L243.375 113.856L255.756 101.475ZM292.9 64.375L317.662 89.1375L305.281 101.519L280.519 76.7562L292.9 64.375Z" fill="white"/>
</g>
<g opacity="0.09">
<path fill-rule="evenodd" clip-rule="evenodd" d="M255.541 43.3748H274.041V46.4582H255.541V43.3748ZM262.078 23.4411L266.441 19.0782L288.24 40.8773L283.877 45.2402L262.078 23.4411ZM259.904 25.6148L268.63 34.3407L264.267 38.7036L255.541 29.9777L259.904 25.6148ZM272.993 12.5415L281.719 21.2673L277.356 25.6303L268.63 16.9044L272.993 12.5415Z" fill="white"/>
</g>
<g opacity="0.09">
<path fill-rule="evenodd" clip-rule="evenodd" d="M187.75 144.75H208.75V148.25H187.75V144.75ZM195.17 122.122L200.122 117.17L224.868 141.915L219.915 146.867L195.17 122.122ZM192.702 124.59L202.608 134.495L197.655 139.447L187.75 129.542L192.702 124.59ZM207.56 109.75L217.465 119.655L212.513 124.608L202.608 114.702L207.56 109.75Z" fill="white"/>
</g>
<g opacity="0.08">
<path fill-rule="evenodd" clip-rule="evenodd" d="M202.166 72.4998H216.166V74.8332H202.166V72.4998ZM207.113 57.4148L210.414 54.1132L226.911 70.6098L223.609 73.9115L207.113 57.4148ZM205.468 59.0598L212.071 65.6632L208.769 68.9648L202.166 62.3615L205.468 59.0598ZM215.373 49.1665L221.976 55.7698L218.674 59.0715L212.071 52.4682L215.373 49.1665Z" fill="white"/>
</g>
<g opacity="0.07">
<path fill-rule="evenodd" clip-rule="evenodd" d="M163.959 41.1252H175.459V43.0418H163.959V41.1252ZM168.022 28.7339L170.734 26.0218L184.285 39.5727L181.573 42.2847L168.022 28.7339ZM166.671 30.0852L172.095 35.5093L169.383 38.2214L163.959 32.7972L166.671 30.0852ZM174.807 21.9585L180.231 27.3827L177.519 30.0947L172.095 24.6706L174.807 21.9585Z" fill="white"/>
</g>
<g opacity="0.08">
<path fill-rule="evenodd" clip-rule="evenodd" d="M140.959 128.125H152.459V130.042H140.959V128.125ZM145.022 115.734L147.734 113.022L161.285 126.573L158.573 129.285L145.022 115.734ZM143.671 117.085L149.095 122.509L146.383 125.221L140.959 119.797L143.671 117.085ZM151.807 108.958L157.231 114.383L154.519 117.095L149.095 111.671L151.807 108.958Z" fill="white"/>
</g>
<g opacity="0.08">
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.666 79.9998H144.666V81.3332H136.666V79.9998ZM139.493 71.3798L141.379 69.4932L150.806 78.9198L148.919 80.8065L139.493 71.3798ZM138.553 72.3198L142.326 76.0932L140.439 77.9798L136.666 74.2065L138.553 72.3198ZM144.213 66.6665L147.986 70.4398L146.099 72.3265L142.326 68.5532L144.213 66.6665Z" fill="white"/>
</g>
<g opacity="0.07">
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.666 26.9998H119.666V28.3332H111.666V26.9998ZM114.493 18.3798L116.379 16.4932L125.806 25.9198L123.919 27.8065L114.493 18.3798ZM113.553 19.3198L117.326 23.0932L115.439 24.9798L111.666 21.2065L113.553 19.3198ZM119.213 13.6665L122.986 17.4398L121.099 19.3265L117.326 15.5532L119.213 13.6665Z" fill="white"/>
</g>
</svg>
        </div>
    );
}

export default RunningIcon;