import React, { Fragment } from "react";
import { StockMashupComponent } from "../components/StockMashup/StockMashupComponent";
import { Helmet } from "react-helmet";

const StockManagementMashup = () => {
  return (
    <Fragment>
      <div data-testid = "Stock-Management-Mashup-container">
        <StockMashupComponent />
      </div>
    </Fragment>
  );
};

export default StockManagementMashup;