import React from "react";

function ArrowNextCarpassportCarousal(props) {
  return (
    <div
      className="custom-slick-next"
      onClick={props.onClick}
      style={props?.styleProp}
    >
      <svg
        width={props?.modal === true ? "25" : "24"}
        height={props?.modal === true ? "40" : "25"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="next-icon-image"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.49023 20.1296L8.26023 21.8996L18.1602 11.9996L8.26023 2.09961L6.49023 3.86961L14.6202 11.9996L6.49023 20.1296H6.49023Z"
          fill={props?.modal === true ? "#333333" : "#90a0b7"}
        />
      </svg>
    </div>
  );
}

export default ArrowNextCarpassportCarousal;
