import React from 'react';
import './titleHeader.css'

function Titleheader({titleheader,title,children,background}) {
    return (
        <div className={`${titleheader}-container-top-header`} style={{ background: background}} data-testid = "mybids-title-header-component">
            <div className="title-heading-right-block">{title}</div>
            <div className="title-heading-left-block">{children}</div>
        </div>
    );
}

export default Titleheader;