import React, { Fragment, useCallback, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DamageSlide from "./DamageSlide";
import "./DamageDialogComponent.css";
import flagImage from "../../../images/flag.svg";

const DamageDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DamageDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 6,
            zIndex: 10,
            "&:hover": { backgroundColor: "transparent" },
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            sx={{
              width: 20,
              height: 30,
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

DamageDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DamageDialogComponent(props) {
  const {
    damages,
    isDialogOpen,
    setDialogOpen,
    selectedIndex,
    titleText,
    showRightReport,
  } = props;
  const [activeSlide, setActiveSlide] = useState(selectedIndex);
  const [title, setTitle] = useState("");

  const handleClose = () => {
    setDialogOpen(false);
  };

  const getFooterRightContent = useCallback(() => {
    return (
      <>
        <span className="footerCounterClass">Report</span>
        <img alt="flage icon" src={flagImage} className="footerFlagIcon"></img>
      </>
    );
  }, []);

  return (
    <Fragment>
      <DamageDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        setMaxWidth="md"
        open={isDialogOpen}
      >
        <DamageDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="dialogTitle"
        >
          <h2>{title ? `${titleText}: ${_.capitalize(title)}` : titleText}</h2>
        </DamageDialogTitle>
        <DialogContent data-testid="damage-Dialog-content">
          <DamageSlide
            {...props}
            setActiveSlideForParent={setActiveSlide}
            setTitle={setTitle}
          />
        </DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 16,
          }}
        >
          <div className="footerCounterClass">
            {titleText} <span className="sliderCountText">{activeSlide}</span>
            {" op "}
            <span className="sliderCountText">
              {damages ? damages.length : 0}
            </span>
          </div>
          <div style={{ display: "flex", float: "right", right: 10 }}>
            {showRightReport ? getFooterRightContent() : ""}
          </div>
        </div>
      </DamageDialog>
    </Fragment>
  );
}
