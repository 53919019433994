import React from "react";

const testURL = process.env.REACT_APP_TESTAPI_URL;

function BrandLogo(props) {

  return (
    <div className="brandlogo-body">
      {props?.urls.map((logoURL, index) => {
        if (index < props?.length - 1) {
          return (
            <img
              className="brandlogo-body-logos"
              src={testURL + logoURL}
              alt="logo1"
              key={index}
            ></img>
          );
        } else if (
          index === props?.length - 1 &&
          props?.urls?.length === props?.length
        ) {
          return (
            <img
              className="brandlogo-body-logos"
              src={testURL + logoURL}
              alt="logo1"
              key={index}
            ></img>
          );
        } else if (index === props?.length - 1) {
          return (
            <div class="counter">
              <img
                className="brandlogo-body-logos-black"
                src={testURL + logoURL}
                alt="logo1"
                key={index}
              ></img>
              <div class="centered-cards">
                +{props.urls.length - props.length + 1}
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default BrandLogo;
