import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import moment from "moment";
import _ from "lodash";
import "./style.css";
import "./imageSlider.css";

import UpcomingBidHeader from "./carpassport/UpcomingBidHeader/UpcomingBidHeader";
import MiddleHeader from "./MiddleHeader/middleHeader";

import { CarpassportDetails } from "./CarpassportDetails";

function UpcomingCarPassport(props) {
  const { stateValue, vehicleDetail, onPrevious, onNext } = props;
  const { state } = useLocation();
  const getToken = localStorage.getItem("Token");
  const [selectedVehicleId, setSelectedVehicleId] = useState({});

  useEffect(() => {
    /**to resolve half scroll on loading from available car issue */
    window.scrollTo(0, 0);
    /**to resolve half scroll on loading from available car issue ends */
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    const hostURL = process.env.REACT_APP_SERVER_URL;
    let vehicleID = props.isNavigationNeeded ? _.get(vehicleDetail, "itemInfo.id") : state.vehicleId;

    fetch(
      hostURL + `/api/auction/VehicleById/${vehicleID}`,
      requestGetOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (!_.isEmpty(data)) {
          setSelectedVehicleId(data);
        }
      }).catch(err=>{
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let startDate = moment(state?.OpenForBidding);
  let startBiddingDate = dateFormat(state?.OpenForBidding, "dd-mm-yy");
  let startBiddinghours = dateFormat(state?.OpenForBidding, "HH");
  let startBiddingminute = startDate.minutes();

  let endDate = moment(state?.enddayofbidding);

  let endBiddingDate = dateFormat(state?.enddayofbidding, "dd-mm-yy");
  let endBiddinghours = dateFormat(state?.enddayofbidding, "HH");
  let endBiddingminute = endDate.minutes();

  let checkDamageStatus = (selectedVehicle) => {
    if (selectedVehicle) {
      let hasTechnicalDamage =
        selectedVehicle.technicalDamages &&
        selectedVehicle.technicalDamages.length > 0;
      let hasOpticalDamage =
        selectedVehicle.opticalDamages &&
        selectedVehicle.opticalDamages.length > 0;
      return hasTechnicalDamage || hasOpticalDamage;
    }
  };

  return (
    <div className="carpassport-container-wrapper">
      <div>
        <div
          class="carpassport-container-wrapper-upcomingBid"
          data-testid="carpassport-container"
        >
           {props.isNavigationNeeded ? (
            <UpcomingBidHeader
            
           
            endBiddingDate={dateFormat(_.get(
              vehicleDetail,
              "auctionParameters.closedForBidding"
            ), "dd-mm-yy")}
            endBiddinghours={dateFormat(_.get(
              vehicleDetail,
              "auctionParameters.closedForBidding"
            ), "HH")}
            endBiddingminute={moment(
              _.get(vehicleDetail, "auctionParameters.closedForBidding")
            ).minutes()}
              accesstoken={stateValue.accesstoken}
              userid={stateValue.userid}
              vehicleId={_.get(vehicleDetail, "itemInfo.id")}
              catalogID={_.get(vehicleDetail, "catalogID")}
              lotID={_.get(vehicleDetail, "lotId")}
              bidStatus={_.get(vehicleDetail, "status")}
              openForBidding={_.get(vehicleDetail, "openForBidding")}
              startBiddingDate={dateFormat(
                _.get(vehicleDetail, "openForBidding"),
                "dd-mm-yy"
              )}
              startBiddinghours={dateFormat(
                _.get(vehicleDetail, "openForBidding"),
                "HH"
              )}
              startBiddingminute={moment(_.get(vehicleDetail, "openForBidding")).minutes()}
              openForBiddingminute={dateFormat(
                _.get(vehicleDetail, "openForBidding"),
                "MM"
              )}
              expectedDeliveryDate={
                selectedVehicleId.expectedDeliveryDate !== undefined ||
                null
                  ? selectedVehicleId.expectedDeliveryDate
                  : ""
              }
              licencePlate={_.get(vehicleDetail, "itemInfo.licenceplate")}
              modelType={_.get(vehicleDetail, "itemInfo.modelType")}
              brand={_.get(vehicleDetail, "itemInfo.brand")}
              enddayofbidding={_.get(
                vehicleDetail,
                "auctionParameters.closedForBidding"
              )}
              updateSelectedVehicleId={vehicleDetail}
              transmission={_.get(vehicleDetail, "itemInfo.transmission")}
              minBid={_.get(vehicleDetail, "priceSettings.minBid")}
              isNavigationNeeded={
                props.isNavigationNeeded ? props.isNavigationNeeded : false
              }
              vehiclesList={props.vehiclesList}
              bidAmount={_.get(vehicleDetail, "bidInfoPage.bidAmount")}
              bidId={_.get(vehicleDetail, "bidID")}
              bidInfoPage={_.get(vehicleDetail, "bidInfoPage")}
            />
          ) : (<UpcomingBidHeader
            bidAmount={state?.bidAmount}
            lotID={state?.lotID}
            bidId={state?.bidId}
            accesstoken={state?.accesstoken}
            userid={state?.userid}
            catalogID={state?.catalogID}
            bidStatus={state?.bidStatus}
            openForBidding={state?.OpenForBidding}
            startBiddingDate={startBiddingDate}
            startBiddinghours={startBiddinghours}
            startBiddingminute={startBiddingminute}
            endBiddingDate={endBiddingDate}
            endBiddinghours={endBiddinghours}
            endBiddingminute={endBiddingminute}
            expectedDeliveryDate={selectedVehicleId.expectedDeliveryDate}
            licencePlate={selectedVehicleId.licenceplate}
            modelType={state?.modelType}
            brand={state?.brand}
            enddayofbidding={state.enddayofbidding}
            updateSelectedVehicleId={selectedVehicleId}
            minBid={state.minBid}
            isNavigationNeeded={props.isNavigationNeeded? props.isNavigationNeeded :false}
            vehiclesList = {props.vehiclesList}
          />)}





         
        </div>

        <div className="carpassport-container-wrapper-won-middleHeader">
          <MiddleHeader
            licencePlate={selectedVehicleId?.licenceplate}
            model={selectedVehicleId?.model}
            brand={selectedVehicleId?.brand}
            modelType={selectedVehicleId?.modelType}
            coachType={selectedVehicleId?.coachType}
            fuelType={selectedVehicleId?.fuelType}
            milage={selectedVehicleId?.mileage}
            mileageUnits={selectedVehicleId?.mileageUnits}
            modelVersionFrom={selectedVehicleId?.modelVersionFrom}
            transmission={selectedVehicleId?.transmission}
            jaar={selectedVehicleId?.firstRegistrationInt}
            hadDamage={checkDamageStatus(selectedVehicleId)}
            onPrevious={() => onPrevious()}
            onNext={() => onNext()}
            indexDetails={props?.indexDetails}
            isNavigationNeeded={props.isNavigationNeeded? props.isNavigationNeeded :false}
          />
        </div>
      </div>
      {/** carpassport Details */}
      <CarpassportDetails {...selectedVehicleId} />
      {/** carpassport Details ends */}{" "}
    </div>
  );
}

export default UpcomingCarPassport;
