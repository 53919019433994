import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import Slider from "@mui/material/Slider";
import "./RangeFilterComponent.css";

const RangeFilterComponent = forwardRef((props, ref) => {
  const {
    heading,
    minRange = 0,
    maxRange = 10,
    marks = null,
    labelFormattext = "",
    getRange,
    steps,
    selectedRange,
    unit = "",
    isFilterApplied = false,
  } = props;
  const minDistance = steps;

  const valuetext = (value) => {
    return `${value} ${labelFormattext}`;
  };
  const [range, setRange] = useState([minRange, maxRange]);
  const handleRangeSliderChnage = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRange([Math.min(newValue[0], range[1] - minDistance), range[1]]);
      getRange([Math.min(newValue[0], range[1] - minDistance), range[1]]);
    } else {
      setRange([range[0], Math.max(newValue[1], range[0] + minDistance)]);
      getRange([range[0], Math.max(newValue[1], range[0] + minDistance)]);
    }
  };
  useImperativeHandle(ref, () => ({
    updateKmSelection(min = minRange, max = maxRange) {
      setRange([min, max]);
    },
  }));

  useEffect(() => {
    if (!isFilterApplied) {
      setRange(selectedRange);
    }
  }, [selectedRange,isFilterApplied]);

  const kmFormatter = new Intl.NumberFormat("nl-NL", {
    unit: "kilometer",
  });
  const getSelectionText = () => {
    if (minRange === maxRange) {
      return heading === "Kilometerstand" ? `${kmFormatter.format(minRange)} ${unit}-${kmFormatter.format(maxRange)} ${unit}` :`${minRange} ${unit}-${maxRange} ${unit}`;
    } else {
      return heading === "Kilometerstand" ? `${kmFormatter.format(range[0])} ${unit}- ${kmFormatter.format(range[1])} ${unit}` :`${range[0]} ${unit}- ${range[1]} ${unit}`;
    }

  };

  return (
    <div className="rangeFilterWraper" data-testid = "range-filter-component-parent">
      <div className="rangeFilterHeader">
        <span className="rangeFilterHeading">{heading}</span>
      </div>
      <div
        className={`rangeFilterBody ${
          minRange == maxRange ? "fullWidthSlider" : null
        }`}
      >
        <div className="slectionText">
          <span className="selectionTitle">Selectie bereik: </span>
          <span className="slectedYearSection">{getSelectionText()}</span>
        </div>
        <Slider
          getAriaLabel={() => "Minimum distance"}
          value={range}
          onChange={handleRangeSliderChnage}
          valueLabelDisplay="off"
          getAriaValueText={valuetext}
          min={minRange}
          max={maxRange}
          step={steps}
          disableSwap
          valueLabelFormat={valuetext}
          disabled={minRange == maxRange ? true : false}
        />
        {marks ? marks : null}
      </div>
    </div>
  );
});

export default RangeFilterComponent;
