import React from "react";

function StockIcon({navAction}) {
  return (
    <div>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={navAction==='stockoverview'?'active':""}
      >
        <g opacity="0.8">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1055 4H5.10547C3.44547 4 2.10547 5.34 2.10547 7V15C2.10547 16.66 3.44547 18 5.10547 18L4.10547 19V20H5.10547L7.10547 18H9.10547V13H4.10547V6H13.1055V8H15.1055V7C15.1055 5.34 13.7655 4 12.1055 4ZM5.10547 14C5.65547 14 6.10547 14.45 6.10547 15C6.10547 15.55 5.65547 16 5.10547 16C4.55547 16 4.10547 15.55 4.10547 15C4.10547 14.45 4.55547 14 5.10547 14ZM20.6755 9.66C20.5355 9.26 20.1555 9 19.7055 9H12.5155C12.0555 9 11.6855 9.26 11.5355 9.66L10.1155 13.77V19.28C10.1155 19.66 10.4255 20 10.8055 20H11.4255C11.8055 20 12.1055 19.62 12.1055 19.24V18H20.1055V19.24C20.1055 19.62 20.4155 20 20.7955 20H21.4055C21.7855 20 22.0955 19.66 22.0955 19.28L22.1055 17.91V13.77L20.6755 9.66ZM20.1055 16C19.5555 16 19.1055 15.55 19.1055 15C19.1055 14.45 19.5555 14 20.1055 14C20.6555 14 21.1055 14.45 21.1055 15C21.1055 15.55 20.6555 16 20.1055 16ZM12.1055 16C11.5555 16 11.1055 15.55 11.1055 15C11.1055 14.45 11.5555 14 12.1055 14C12.6555 14 13.1055 14.45 13.1055 15C13.1055 15.55 12.6555 16 12.1055 16ZM12.5155 10H19.7055L20.7355 13H11.4855L12.5155 10Z"
            fill="#90A0B7"
          />
        </g>
      </svg>
    </div>
  );
}

export default StockIcon;
