import { useRef, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import Slider from "react-slick";
import "./CarPassportSlider.css";
import CarPassport from ".";
import { useState } from "react";
import UpdateCarPassport from "./updateIndex";
import UpcomingCarPassport from "./upcomingIndex";
import WaitingCarPassport from "./waitingIndex";
import LostCarPassport from "./lostindex";
import WonCarPassport from "./wonIndex";

export default function CarPassportSlider() {
  const { state } = useLocation();
  const { vehiclesDetail, vehicleId, sortData } = state;
  let currentIndex = state.currentIndex ? state.currentIndex : 0;
  const [selectedIndex, setSelectedIndex] = useState(currentIndex);
  const currentSlide = useRef();
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    speed: 0,
    draggable: false,
    initialSlide: selectedIndex,
    lazyLoad: "progressive",
    accessibility: false
  };

  const goToPrevious = useCallback(() => {
    currentSlide.current.slickPrev();
  }, []);

  const goToNext = useCallback(() => {
    currentSlide.current.slickNext();
  }, []);

  const getSelectedIndex = useCallback(() => {
    const selIndex = _.findIndex(vehiclesDetail, (vDetail) => {
      return _.isEqual(_.get(vDetail, "itemInfo.id"), vehicleId);
    });
    return selIndex;
  }, [vehicleId, vehiclesDetail]);

  useEffect(() => {
    if (currentSlide.current) {
      const selectedInd = state.currentIndex
        ? state.currentIndex
        : getSelectedIndex();
      setSelectedIndex(selectedInd);
      currentSlide.current.slickGoTo(selectedInd);
    }
  }, [getSelectedIndex]);

  const getIndexFile = (vehDetail, vehiclesDetail, index) => {
    if (
      vehDetail.status === "OpenForBidding" &&
      (vehDetail.bidInfoPage === null ||
        vehDetail.bidInfoPage?.bidAmount === null)
    ) {
      return (
        <CarPassport
          stateValue={state}
          vehicleDetail={vehDetail}
          indexDetails={{
            total: vehiclesDetail.length,
            currentIndex: index,
            selectedIndex: selectedIndex,
          }}
          onPrevious={() => goToPrevious()}
          onNext={() => goToNext()}
          isNavigationNeeded={true}
          vehiclesList={vehiclesDetail}
          isFromMyBid={state.isFromMyBid ? true : false}
          sortData={sortData}
        />
      );
    } else if (vehDetail.bidInfoPage?.bidStatus === "Won") {
      return (
        <WonCarPassport
          stateValue={state}
          vehicleDetail={vehDetail}
          indexDetails={{
            total: vehiclesDetail.length,
            currentIndex: index,
            selectedIndex: selectedIndex,
          }}
          onPrevious={() => goToPrevious()}
          onNext={() => goToNext()}
          isNavigationNeeded={true}
          vehiclesList={vehiclesDetail}
          isFromMyBid={state.isFromMyBid ? true : false}
          sortData={sortData}
        />
      );
    } else if (vehDetail.bidInfoPage?.bidStatus === "AWAITING RESULTS") {
      return (
        <WaitingCarPassport
          stateValue={state}
          vehicleDetail={vehDetail}
          indexDetails={{
            total: vehiclesDetail.length,
            currentIndex: index,
            selectedIndex: selectedIndex,
          }}
          onPrevious={() => goToPrevious()}
          onNext={() => goToNext()}
          isNavigationNeeded={true}
          vehiclesList={vehiclesDetail}
          isFromMyBid={state.isFromMyBid ? true : false}
          sortData={sortData}
        />
      );
    } else if (vehDetail.bidInfoPage?.bidStatus === "Lost") {
      return (
        <LostCarPassport
          stateValue={state}
          vehicleDetail={vehDetail}
          indexDetails={{
            total: vehiclesDetail.length,
            currentIndex: index,
            selectedIndex: selectedIndex,
          }}
          onPrevious={() => goToPrevious()}
          onNext={() => goToNext()}
          isNavigationNeeded={true}
          vehiclesList={vehiclesDetail}
          isFromMyBid={state.isFromMyBid ? true : false}
          sortData={sortData}
        />
      );
    } else if (
      (vehDetail.status === "OpenForBidding" ||
        vehDetail.bidInfoPage?.bidStatus === "AWAITING RESULTS-P" ||
        vehDetail.status === "Closed" ||
        vehDetail.bidInfoPage?.bidStatus === "Bid Placed" ||
        vehDetail.bidInfoPage?.bidStatus === "To be Confirmed" ||
        vehDetail.bidInfoPage?.bidStatus === "Fail") &&
      (vehDetail.bidInfoPage !== null ||
        vehDetail.bidInfoPage?.bidAmount !== null)
    ) {
      return (
        <UpdateCarPassport
          stateValue={state}
          vehicleDetail={vehDetail}
          indexDetails={{
            total: vehiclesDetail.length,
            currentIndex: index,
            selectedIndex: selectedIndex,
          }}
          onPrevious={() => goToPrevious()}
          onNext={() => goToNext()}
          isNavigationNeeded={true}
          vehiclesList={vehiclesDetail}
          isFromMyBid={state.isFromMyBid ? true : false}
          sortData={sortData}
        />
      );
    } else if (
      vehDetail.status === "Published" &&
      (vehDetail?.bidInfoPage === null ||
        vehDetail?.bidInfoPage !== null ||
        vehDetail?.bidInfoPage == null)
    ) {
      return (
        <UpcomingCarPassport
          stateValue={state}
          vehicleDetail={vehDetail}
          indexDetails={{
            total: vehiclesDetail.length,
            currentIndex: index,
            selectedIndex: selectedIndex,
          }}
          onPrevious={() => goToPrevious()}
          onNext={() => goToNext()}
          isNavigationNeeded={true}
          vehiclesList={vehiclesDetail}
          isFromMyBid={state.isFromMyBid ? true : false}
          sortData={sortData}
        />
      );
    }
  };

  return (
    <div className="container" data-testid="carpassport-slider">
      {vehiclesDetail ? (
        <Slider
          {...sliderSettings}
          ref={(slider) => (currentSlide.current = slider)}
          className="carpassportSlider"
        >
          {vehiclesDetail.map((vehDetail, index) => {
            return <div>{getIndexFile(vehDetail, vehiclesDetail, index)}</div>;
          })}
        </Slider>
      ) : (
        <div>loooo</div>
      )}
    </div>
  );
}
