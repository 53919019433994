import React from "react";
import dateFormat from "dateformat";

import CarUspIcon from "../../../Icons/caruspIcon";
import CarkmIcon from "../../../Icons/carkmIcon";
import CarFuelIcon from "../../../Icons/carfuelIcon";
import CarMilageIcon from "../../../Icons/carMilageIcon";
import CarcalenderIcon from "../../../Icons/carcalenderIcon";
import CarTransmissionIcon from "../../../Icons/carTransmissionIcon";
import DamageHeaderSection from "../../../DamageHeaderSection/DamageHeaderSection";

export default function MiddleInfoDisplay(props) {
  const mileageFormatter = new Intl.NumberFormat("nl-NL", {
    // style: 'unit',
    unit: "kilometer",
  });

  let transmission =
    props?.transmission === "Other"
      ? "Onbekend"
      : props?.transmission === "Manual"
      ? "Handgeschakeld"
      : props?.transmission === "Automatic"
      ? "Automaat"
      : props?.transmission === "Semiautomatic"
      ? "Semiautomatic"
      : "-";

  let brandstof =
    props?.fuelType === "Unknown"
      ? "Onbekend"
      : props?.fuelType === "Diesel"
      ? "Diesel"
      : props?.fuelType === "Electric"
      ? "Elektrisch"
      : props?.fuelType === "Electricgasoline"
      ? "Elektrisch/benzine"
      : props?.fuelType === "Electricdiesel"
      ? "Elektrisch/diesel"
      : props?.fuelType === "CNG"
      ? "Aardgas"
      : props?.fuelType === "Gasoline"
      ? "Benzine"
      : props?.fuelType === "LPG"
      ? "LPG"
      : props?.fuelType === "Other"
      ? "Onbekend"
      : props?.fuelType === "Ethanol"
      ? "Ethanol"
      : props?.fuelType === "Hydrogen"
      ? "Hydrogen"
      : "-";

  return (
    <div className="carpassportItemMainBlock">
      <DamageHeaderSection isDamage={props.hadDamage} />

      <div className="carpassportItemWraper">
        <span className="carpassport-body-property-icon">
          <CarUspIcon />
        </span>
        <div className="carpassport-body-property-block">
          <span className="carpassport-body-property-title">
            Type Carosserie
          </span>
          <span className="carpassport-body-property-body">
            {props.coachType === null ? "-" : props.coachType}
          </span>
        </div>
      </div>

      <div className="carpassportItemWraper">
        <span className="carpassport-body-property-icon">
          <CarkmIcon />
        </span>
        <div className="carpassport-body-property-block">
          <span className="carpassport-body-property-title">Km.Stand</span>
          <span className="carpassport-body-property-body">
            {props.milage === null
              ? "-"
              : mileageFormatter.format(props.milage)}
          </span>
        </div>
      </div>

      <div className="carpassportItemWraper">
        <span className="carpassport-body-property-icon">
          <CarFuelIcon />
        </span>
        <div className="carpassport-body-property-block">
          <span className="carpassport-body-property-title">Brandstof</span>
          <span className="carpassport-body-property-body">{brandstof}</span>
        </div>
      </div>

      <div className="carpassportItemWraper">
        <span className="carpassport-body-property-icon">
          <CarcalenderIcon />
        </span>
        <div className="carpassport-body-property-block">
          <span className="carpassport-body-property-title">Bouwjaar</span>
          <span className="carpassport-body-property-body">
            {props.jaar === null ? "-" : dateFormat(props.jaar, "yyyy")}
          </span>
        </div>
      </div>

      <div className="carpassportItemWraper">
        <span className="carpassport-body-property-icon">
          <CarTransmissionIcon />
        </span>
        <div className="carpassport-body-property-block">
          <span className="carpassport-body-property-title">Transmissie</span>
          <span className="carpassport-body-property-body">
            {transmission === null ? "-" : transmission}
          </span>
        </div>
      </div>

      {/* </div> */}
    </div>
  );
}
