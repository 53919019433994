import React from "react";

function CancelIcon(props) {
  const cancelHandleClick = (id, e) => {
    props.onChange(id);
  };
  return (
    <div>
      <span onClick={cancelHandleClick.bind(null, props.lotid)} className="cancel_runing">
        <svg width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg" 
        >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0.5C4.47 0.5 0 4.97 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.97 15.53 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM13.59 5.5L10 9.09L6.41 5.5L5 6.91L8.59 10.5L5 14.09L6.41 15.5L10 11.91L13.59 15.5L15 14.09L11.41 10.5L15 6.91L13.59 5.5Z"
          fill="#90A0B7"
        />
      </svg>
    </span>
  </div>
  );
}

export default CancelIcon;
