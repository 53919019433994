import React from "react";
import "./wonbidHeader.css";
import "../style.css";
import RegistratieIcon from "../../Icons/registratieIcon";
import BackButtonCarpassportHeaderIcon from "../../Icons/backButtonCarpassportHeaderIcon";
import wonbidimages from "../../../../src/images/wonbidimages.gif";
import paymentimg from "../../../../src/images/paymentimg.png";
import registrationimg from "../../../../src/images/registrationimg.png";
import transportimg from "../../../../src/images/transportimg.png";
import Confetti from "react-confetti";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export default function WonBidHeader({
  bidAmount,
  vehiclesList,
  isNavigationNeeded,
  isFromMyBid
}) {
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
  let width = window.innerWidth - 300;
  let height = "auto";
  const history = useHistory();
  const backButtonOnclick = () => {
    if(isFromMyBid){
      let sortData;
      if(localStorage.getItem("sortData")){
        sortData = JSON.parse(localStorage.getItem("sortData"));
        localStorage.removeItem("sortData");
      }
      history.push({
        pathname:  "/mybids",
        state:{
          sortData,
          vehiclesList
        }
        
      });
    }else{
      const selectedUniqueVehicle = _.uniqBy(vehiclesList, "catalogID");
      const catalogIDs = selectedUniqueVehicle.map(
        (vehicle) => vehicle.catalogID
      );

      history.push({
        pathname: "/AvailableCars",
        state: {
          catalogIDs: catalogIDs,
          vehiclesList: vehiclesList,
        },
      });
    }
  };

  return (
    <React.Fragment>
      {isNavigationNeeded ? (
        <button
          className="carpassport-back-btn-header"
          onClick={() => backButtonOnclick()}
        >
          <BackButtonCarpassportHeaderIcon />
          <span className="carpassport-back-btn-title">Terug</span>
        </button>
      ) : null}
      <div className="won-bid-header-col-second span_1_of_3">
        <Confetti width={width} height={height} />

        <div className="carpassport-won-bid-header-image-container">
          <img
            alt="wonbidImage"
            src={wonbidimages}
            className="header-won-bid-image"
          ></img>
        </div>
        <div className="carpassport-won-bid-header-form-label">
          <span className="carpassport-won-bid-header-field-label">
            JE HEBT HET BOD GEWONNEN
          </span>
        </div>
        <div className="carpassport-won-bid-header-minimum-amount-container">
          <div className="carpassport-won-bid-header-minimum-amount-container-title-block">
            <span className="carpassport-won-bid-value">Jouw bod</span>
            <span className="carpassport-won-bid-rate">
              {formatter.format(bidAmount)}
            </span>

            <div className="carpassport-won-bid-header-form-description">
              <span className="carpassport-won-bid-header-form-description-title">
                Koperskosten vanaf € 280,- + transportkosten vanaf € 99,-
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="won-bid-header-col-second-middle span_1_of_3">
        <span className="won-bid-options-icon-title">Volgende stappen: </span>

        <span className="carpassport-form-description-subtitle-payment-icon">
          <img alt="paymentImage" src={paymentimg}></img>
          <p className="payment-icon-title">
            Betaling
            <RegistratieIcon />
          </p>
        </span>

        <div className="carpassport-form-description-subtitle-registration-icon">
          <span className="won-bid-registration-icon-title">
            <img alt="registrationImage" src={registrationimg}></img>
            <p className="regstration-icon-title">
              Registratie
              <RegistratieIcon />
            </p>
          </span>
        </div>
        <span className="carpassport-form-description-subtitle-transport-icon">
          <img alt="transportImage" src={transportimg}></img>
          <p className="transport-icon-title">Transport</p>
        </span>
      </div>
    </React.Fragment>
  );
}
