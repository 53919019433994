import React from 'react';
import { Link } from 'react-router-dom';
import './pageNotFound.css';

const PageNotFound = () => {
return( 
  <div className='mainContainer'>
      <div className='pageContent'>
        <h1>404 - Page Not Found!</h1>
         <Link to="/">Go Home</Link>

      </div>
  </div>
);
}

export default PageNotFound;