import React from 'react';

function GasFuelIcon(props) {
    return (
        <div>
            
<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.69796 1.0835C3.89131 1.0835 3.22684 1.74797 3.22684 2.55461V10.8909H2.24609V11.8717H10.0921V10.8909H9.11131V9.41983H10.0921C10.8987 9.41983 11.5632 8.75536 11.5632 7.94871V4.31306L9.94839 2.69828L9.25497 3.39169L10.0921 4.22878V5.00648C10.0921 5.27716 10.3117 5.49685 10.5824 5.49685V7.94871C10.5824 8.22579 10.3691 8.43908 10.0921 8.43908H9.11131V2.55461C9.11131 1.74797 8.44684 1.0835 7.64019 1.0835H4.69796ZM4.69795 2.06424H7.64019C7.91726 2.06424 8.13056 2.27754 8.13056 2.55462V3.53536H4.20758V2.55462C4.20758 2.27754 4.42088 2.06424 4.69795 2.06424ZM8.13056 4.51611H4.20758V10.891H8.13056V4.51611Z" fill="#90A0B7"/>
</svg>
        </div>
    );
}

export default GasFuelIcon;