import React, { useRef, useState ,useEffect} from "react";
// import { useHistory } from "react-router-dom";

import "./updatebutton.css";

export default function ArchivalUpdateButton({
  bidInfoPage,
  bidId,
  accesstoken,
  disableOption,
  minBid,
}) {
  // const history = useHistory();
  const updateBidAmountRef = useRef();
  // const locationHistory = window.location.search;
  // const [bidinfo, setBidInfo] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let updateBidAmt = bidInfoPage?.bidAmount
    ? parseInt(bidInfoPage?.bidAmount, 10)
    : 0;
  minBid = minBid !== undefined || null ? minBid : 0;
  const [bidAmounts, setBidAmount] = useState(0);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setBidAmount(updateBidAmt)
   
  }, [updateBidAmt])

  let incrementBidAmount = () => {
    setBidAmount(bidAmounts >= minBid ? Number(bidAmounts) + 50 : minBid);
    setDisable(false);
    setErrorMessage("");
  };
  let decrementBidAmount = () => {
    if (bidAmounts <= minBid + 50 || bidAmounts <= minBid) {
      setErrorMessage("Bid amount must be greater than minimum bid amount");

      setDisable(true);
    } else if (bidAmounts > minBid) {
      setErrorMessage("");
    } else {
      setErrorMessage("");
    }
    setBidAmount(bidAmounts > minBid ? Number(bidAmounts) - 50 : minBid);
    setDisable(false);
    
  };

  // const handleSubmit = (e) => {
  //   let bidValue = updateBidAmountRef.current.innerHTML;
  //   bidValue = bidValue.substring(1);

  //   setBidAmount(bidAmounts);
  //   const params = {
  //     BidId: `BVE-DMP-BIDMGMT-WORK ${bidId}`,
  //     caseTypeID: "BVE-DMP-BidMgmt-Work-BidMgmt",
  //     processID: "pyStartCase",
  //     content: {
  //       CaseData: {
  //         LastAmount: updateBidAmt,
  //         BidAmount: bidAmounts > minBid ? bidAmounts : minBid,
  //         minBid: minBid,
  //         KeyIndicator: "Modify",
  //       },
  //     },
  //   };

  //   const requestOptionsvalue = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: accesstoken,
  //     },
  //     body: JSON.stringify(params),
  //   };
  //   const hostURL = process.env.REACT_APP_SERVER_URL;
  //   fetch(hostURL + `/api/bidmanager/Bids/Updatebid`, requestOptionsvalue)
  //     .then((response) => response.json())
  //     .then((data) => setBidInfo(data));
  //   history.push(`/MyBids${locationHistory}`);
  // };

  let [setInputValue] = useState(bidAmounts);

  const re = /[^0-9]/g;
  function myChangeFunction(event) {
    if (event.target.value < minBid) {
      setDisable(true);
      setBidAmount(updateBidAmt);
    } else {
      setDisable(false);
    }

    setBidAmount(event.target.value.replace(/[^0-9]/g, ""));
    setInputValue(bidAmounts);

    if (((event.target.value.match(re) === null) === true) && ((event.target.value.match(re) < 0) === true)) {
      setInputValue(event.target.value.replace(/[^0-9]/g, ""));
      setErrorMessage("Amount should contain only numbers");
      
    } else {
      setErrorMessage("");
     
      setDisable(false);
    }
    
  }
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });

  return (
    <React.Fragment>
      <div
      data-testid = "archive-update-button-parent"
        className={
          disableOption
            ? "full-container-update disable"
            : "full-container-update"
        }
      >
        <button className="dec-amt" onClick={decrementBidAmount} type="button">
          {" "}
          <div className="material-icons">remove</div>
        </button>
        <div id="input-block-border">
          <input
            id="amount-input-validation"
            ref={updateBidAmountRef}
            className="bid-amount-container"
            required
            onChange={myChangeFunction}
            value={formatter.format(bidAmounts)}
            type="text"
            disabled={disable}
          ></input>
        </div>
        <button className="inc-amt" onClick={incrementBidAmount}>
          {" "}
          <div className="material-icons">add</div>
        </button>
        {/* <span className="material-icons-outlined" onClick={(e) => handleSubmit(e)}>
          save
        </span> */}
      </div>
      <p className="error-message">{errorMessage}</p>
    </React.Fragment>
  );
}
