import React from "react";
import "./UpcomingBidHeader.css"
import "../../style.css"
import dateFormat from "dateformat";
import StopWatchIcon from "../../../Icons/stopwatchIcon.js"
import { useHistory } from "react-router-dom";
import _ from "lodash";
import BackButtonCarpassportHeaderIcon from "../../../Icons/backButtonCarpassportHeaderIcon";
export default function UpcomingBidHeader({
  accesstoken,
  userid,
  catalogID,
  lotID,
  bidAmount,
  bidId,
  bidStatus,
  openForBidding,
  startBiddingDate,
  startBiddinghours,
  endBiddingDate,
  startBiddingminute,
 endBiddinghours,
  endBiddingminute,
  enddayofbidding,
  updateSelectedVehicleId,
  minBid,
  vehiclesList,  isNavigationNeeded,
}){
  const minBidAmounts = minBid !== undefined ? minBid : 0;
  const history = useHistory();

  const backButtonOnclick = () => {
    const selectedUniqueVehicle = _.uniqBy(
      vehiclesList,
      "catalogID"
    );
    const catalogIDs = selectedUniqueVehicle.map((vehicle)=>vehicle.catalogID);
    history.push({
      pathname:  "/AvailableCars",
      state: {
        catalogIDs: catalogIDs,
        vehiclesList:vehiclesList
      } 
   });
  }

  return (
    <React.Fragment>
       {isNavigationNeeded ? (
        <button
          className="carpassport-back-btn-header"
          onClick={() => backButtonOnclick()}
        >
          <BackButtonCarpassportHeaderIcon />
          <span className="carpassport-back-btn-title">Terug</span>
        </button>
      ) : null}
      {/* <div className = "carpassport-upcoming-bid-header-container"> */}
      <div class="upcoming-bid-header-col-second span_1_of_3">
          <div className="carpassport-upcoming-bid-header-form-label">
            <span className="carpassport-upcoming-bid-header field-label">START BINNENKORT</span>
          </div>
          <div className="carpassport-upcoming-bid-header-minimum-amount-container">
            <div className="carpassport-upcoming-bid-header-minimum-amount-container-title-block">
              <span className="carpassport-upcoming-bid-value">Minimum bodbedrag</span>
              <span className="carpassport-upcoming-bid-rate">{minBidAmounts}</span>
            </div>
            <div className="carpassport-upcoming-bid-header-form-description">
              <span className="carpassport-upcoming-bid-header-form-description-title">
              Koperskosten vanaf € 280,- + transportkosten vanaf € 99,-
              </span>
            
            </div>
          </div>
        </div>
        {/* <div className="upcoming-bid-header-col-second-middle span_1_of_3">
           <button className="carpassport-upcoming-bid-header-confirm-bid-btn">MAIL MIJ
           <span style = {{textAlign:"center",padding: '0 0px 0 9px'}}><BellIcon /></span>
           </button>
        </div> */}
        <div className="upcoming-bid-header-col-second-end span_1_of_3">
        <div className="carpassport-upcoming-bid-header-timer-label">
                <StopWatchIcon width="55px" height="51px" color = "#000000"/>
            </div>
            <div className="carpassport-upcoming-bid-header-timer-start">
                
                <div className="carpassport-upcoming-bid-header-start-date">{dateFormat(openForBidding,'dd-mm-yy')}  {dateFormat(openForBidding,'HH')}:{dateFormat(startBiddingminute,"MM")}</div>
                <span className="carpassport-upcoming-bid-header-start-date-title">
                Startdatum en -tijd
                
                </span>
            </div>
        <div className="carpassport-upcoming-bid-header-timer-start">
                <div className="carpassport-upcoming-bid-header-start-date">{endBiddingDate}  {endBiddinghours}:{dateFormat(endBiddingminute,"MM")}</div>
                <span className="carpassport-upcoming-bid-header-start-date-title">
                Einddatum en -tijd
                </span>
            </div>
        </div>
        
    </React.Fragment>
  );
}
