import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./cardCarousal.css";
import CarousalIcon from "../../images/bovag_1.png";
import SlickNext from "../Icons/AvailableCarIcons/slickNext";
import SlickPrev from "../Icons/AvailableCarIcons/slickPrev";

function CardCarousal({ medias }) {
  
  const testURL = process.env.REACT_APP_TESTAPI_URL;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <div>
        <SlickNext />
      </div>
    ),
    prevArrow: (
      <div>
        <SlickPrev />
      </div>
    ),
  };
  return (
    <div className="cardCarousal" data-testid="cardCarousal">
      <div className="card-carousalIcon-container">
        <img src={CarousalIcon} alt="CarousalIcon" />
      </div>
      <Slider {...settings}>
        {medias.map((slide) => {
          return (
            <div className="slick-slide" key={slide.id}>
              <img
                className="slick-slide-image"
                data-testid="slider-img"
                src={testURL +`${slide.url}`}
                alt="slide-imag"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default CardCarousal;
