import React from "react";

function UpcomingAuctionIcon2() {
  return (
    <div>
      <svg
        width="56"
        height="56"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.4" width="55" height="56" rx="26" fill="#71BCE2" />

        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.5737 16.333H19.4256C17.9997 16.333 16.833 17.4997 16.833 18.9256V37.0737C16.833 38.4997 17.9997 39.6663 19.4256 39.6663H37.5737C38.9997 39.6663 40.1663 38.4997 40.1663 37.0737V18.9256C40.1663 17.4997 38.9997 16.333 37.5737 16.333ZM37.5737 37.0737H19.4256V18.9256H37.5737V37.0737ZM32.3886 29.296H34.9812V34.4812H32.3886V29.296ZM22.0182 25.4071H24.6108V34.4812H22.0182V25.4071ZM27.2034 21.5182H29.796V34.4812H27.2034V21.5182Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default UpcomingAuctionIcon2;
