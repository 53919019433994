import React from "react";
import './roundCheckbox.css'

function RoundBlankCheckbox({checkboxAuctionRunningRef}) {
  return (
    <div ref={checkboxAuctionRunningRef} className="checkbox-blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#C6CBD4"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      </svg>
    </div>
  );
}

export default RoundBlankCheckbox;
