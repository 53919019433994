import React from 'react';

function ClockIcon({clockRef,labelStyles}) {
  
    return (
      // <div style={{ marginTop: '-20px', marginLeft:'288px'}}>
   <div className=""> 
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill={labelStyles.color}
            xmlns="http://www.w3.org/2000/svg"
            ref={clockRef}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.99984 0.166687C2.7915 0.166687 0.166504 2.79169 0.166504 6.00002C0.166504 9.20835 2.7915 11.8334 5.99984 11.8334C9.20817 11.8334 11.8332 9.20835 11.8332 6.00002C11.8332 2.79169 9.20817 0.166687 5.99984 0.166687ZM5.99984 10.6667C3.42734 10.6667 1.33317 8.57252 1.33317 6.00002C1.33317 3.42752 3.42734 1.33335 5.99984 1.33335C8.57234 1.33335 10.6665 3.42752 10.6665 6.00002C10.6665 8.57252 8.57234 10.6667 5.99984 10.6667ZM6.2915 3.08335H5.4165V6.58335L8.44984 8.45002L8.9165 7.69169L6.2915 6.11669V3.08335Z"
              fill={labelStyles.color}
            />
          </svg>
      </div>
    );
}

export default ClockIcon;