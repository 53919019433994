import React from "react";
import { useState } from "react";

function MenuSubTitle(props) {
  const [activeTab, setActiveTab] = useState(0);
  const handleChange = (value) => {
    setActiveTab(value);
    props.updateActiveTab(value);
  };
  const isActive = (index) => {
    if (index === activeTab) {
      return "carpassport-subheader-tags-block-active";
    } else {
      return "carpassport-subheader-tags-block";
    }
  };

  return (
    <React.Fragment>
      <div className={isActive(0)} onClick={() => handleChange(0)}>
        Voertuigdetails
      </div>
      <div className={isActive(1)}>Inruilen</div>
      <div className={isActive(2)}>Slimme voorraad</div>
      <div className={isActive(3)}>Online Marketing</div>
      <div className={isActive(4)}>Verkoop</div>
      <div className={isActive(5)}>Contract</div>
      <div className={isActive(6)}>Financieel</div>
      <div className={isActive(7)}>Consumentenzorg</div>
    </React.Fragment>
  );
}

export default MenuSubTitle;
