import React from "react";
import './Toelichting.css'

const Toelichting = (props) => {
  return (
    <div className="toelichting">
      <span className="toelichting-container-title">
        Toelichting
      </span>
      <div className="toelichting-content" data-testid="toelichting-content">
        {props.remarks}
      </div>
    </div>
  );
};

export default Toelichting;
