import React from "react";

function UpcomingAuctionsIcon1() {
  return (
    <div>
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="27" cy="27" r="26" fill="#AF8007" />

        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.8251 14.5C19.9684 14.5 14.416 20.1 14.416 27C14.416 33.9 19.9684 39.5 26.8251 39.5C33.6943 39.5 39.2591 33.9 39.2591 27C39.2591 20.1 33.6943 14.5 26.8251 14.5ZM26.8376 37C21.3472 37 16.9003 32.525 16.9003 27C16.9003 21.475 21.3472 17 26.8376 17C32.3279 17 36.7748 21.475 36.7748 27C36.7748 32.525 32.3279 37 26.8376 37ZM27.4586 20.75H25.5954V28.25L32.1167 32.1875L33.0483 30.65L27.4586 27.3125V20.75Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default UpcomingAuctionsIcon1;
