import React from "react";

export default function SliderPrevArrow(props) {
  const { height, width, color, content } = props;
  return (
    <div className="slick-prev-icon">
      <svg
        width={width ? width : "24"}
        height={height ? height : "24"}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="prev-arrow-image"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d={content? content :"M20.7429 0.263672L25.5 4.96367L10.0479 20.2637L25.5 35.5637L20.7429 40.2637L0.5 20.2637L20.7429 0.263672Z"}
          fill-opacity="0.6"
          fill= {color ? color : "white"}
        />
      </svg>
    </div>
  );
}
