import React, { useState, useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Joyride, { STATUS, EVENTS, ACTIONS } from "react-joyride";
import { BrowserRouter, Link, useLocation, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Modal from "@mui/material/Modal";
import { ModalBody } from "react-bootstrap";
import "./MinDrawer.css";
import { Avatar, Collapse, IconButton } from "@material-ui/core";
import { ListItemAvatar, ListItemButton, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logo from "../../images/logo.png";
import shortLogo from "../../images/shorttick.svg";
import PrivateRoute from "../../routes/privateroute";
import AuctionDashboard from "../../containers/auction-dashboard";
import AvailableCars from "../../containers/AvailableCars";

import CarPassport from "../Carpassports/index";
import LostCarPassport from "../Carpassports/lostindex";
import WaitingCarPassport from "../Carpassports/waitingIndex";
import UpcomingCarPassport from "../Carpassports/upcomingIndex";
import UpdateCarPassport from "../Carpassports/updateIndex";
import OnboardingModalImage from "../../images/Auctions/Auctions.png";
import "../../atoms/OnboardingTour_slider/onboardingTourSlider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { steps } from "../stepsContainer";
import MyBids from "../../containers/MyBids";
import UserProfile from "../../containers/user-profile";
import UserOrganization from "../../containers/user-organization";
import WonCarPassport from "../Carpassports/wonIndex";
import StockManagementMashup from "../../containers/StockManagementMashup";
import ProfileIcon from "../Icons/profileIcon";
import LogoutIcon from "../Icons/logoutIcon";
import StockIcon from "../Icons/stockIcon";
import { ReactComponent as Logo } from "../../assets/Logo.svg"; 
import HTTPService from "../../services/HTTPService";
import PageNotFoundComponent from '../../components/PageNotFound/PageNotFound';
import _ from 'lodash';
import CarPassportSlider from "../Carpassports/CarPassportSlider";

const drawerWidth = 240;
const USER_ROLE_AUCTION = 'AUC';
const USER_ROLE_SSM = "SSM";
const USER_ROLE_AUCTION_SSM = "SSM$$AUC"
const AUCTION_MENU_ITEMS = [
  '/AuctionDashboard',
  '/mybids',
  '/AvailableCars',
  '/landingpage'
];
const SSM_MENU_ITEMS = [
  '/stockmanagement'
]


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  // const theme = useTheme();

  const hostURL = process.env.REACT_APP_SERVER_URL;
  const logoutURL = process.env.REACT_APP_LOGOUTAPI_URL;
  const hostLogoutURL = process.env.REACT_APP_LOGOUT_HOST_URL;
  const [open, setOpen] = React.useState(true);
  const [veligenlistOpen, setVeligenListOpen] = useState(true);
  const [stocklistOpen, setStockListOpen] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [getToken, setGetToken] = useState();
  const [logoutAuthentication, setLogoutAuthentication] = useState([]);
  const [mobiIdaccessToken, setMobiIdaccessToken] = useState("");
  const [toggletour, setToggleTour] = useState(false);
  const [selectindex, setSelectIndex] = useState(null);
  const [run, setRun] = useState(false);
  const [navAction, setNavAction] = useState();
  const [userRole, setUserRole] = useState('');
  const history = useHistory();
  const currentLocation = useLocation();

  const setUserRoleBasedOnCondition = useCallback(() => {
    let role = JSON.parse(localStorage.getItem("UserRole"));
    if(role.includes("$$")) {
      switch(true) { //  Add if need more condition
        case (role.includes(USER_ROLE_AUCTION) && role.includes(USER_ROLE_SSM)) :
          role = USER_ROLE_AUCTION_SSM
          break;
          case (role.includes(USER_ROLE_SSM) && role.includes(USER_ROLE_AUCTION)) :
          role = USER_ROLE_AUCTION_SSM
          break;
        default: 
         break;
      }
    }
    setUserRole(role);
  }, []);
 
  useEffect(() => {
    setFirstName(JSON.parse(localStorage.getItem("given_name")));
    setLastName(JSON.parse(localStorage.getItem("family_name")));
    setGetToken(JSON.parse(localStorage.getItem("Token")));
    setNavAction("auction-dashboard");
    setUserRoleBasedOnCondition();
    // less than 720px sidebar condition.
    if (document.documentElement.clientWidth < 720) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  const handleOverViewClick = useCallback(
    (action) => {
      setNavAction(action);
      history.push("/stockmanagement");
      // localStorage.removeItem("stockFlag");
      // setTimeout(() => {
       window.location.reload();
      // }, 500);
    },
    [history]
  );

  const toggelListOpen = useCallback((key) => {
    switch (key) {
      case "Veligen":
        setVeligenListOpen(!veligenlistOpen);

        break;
      case "Stock":
        setStockListOpen(!stocklistOpen);
        break;

      default:
        setVeligenListOpen(!veligenlistOpen);
    }
  }, [stocklistOpen, veligenlistOpen]);

// less than 720px sidebar condition for onclick toggle function of sidebar.
const handleDrawerToggle = () => {
  if (document.documentElement.clientWidth < 720) {
    setOpen(false);
  } else {
    setOpen(!open);
  }
};
  // Profile Menu

  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
    setAnchorEl(event.currentTarget);
    // setToggleTour(!toggletour);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleOnboardingClose = () => {
    setToggleTour(!toggletour);
  };

  const checkUserAuthentication = useCallback(() => {
    const url = hostURL + "/api/Login/VerifySession";
    const token = JSON.parse(localStorage.getItem("Token"));

    HTTPService.send(url, token).then((response) => {

      setMobiIdaccessToken(response.data.value);


    });
    // setMobiIdaccessToken('MobiIdaccessToken')
  }, [hostURL]);

  // console.log(mobiIdaccessToken,'mobiIdaccessToken');

  useEffect(() => {
  
    if(navAction && navAction !== "stockoverview" && !_.isUndefined(navAction)){
      checkUserAuthentication()

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[navAction]);
  

  function handleLogoutClick(e) {
    // to ensure stock storage is removed on logout
    // localStorage.removeItem("StockReload");
    const requestOptionsvalue = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken,
      },
    };

    fetch(hostURL + `/api/logout/logout`, requestOptionsvalue).then(
      (response) => {
        setLogoutAuthentication(response.status);
        window.localStorage.clear();
        window.location.href =
          // `https://login-a.mobi-id.nl/login/api/terminate_session?post_logout_redirect_uri=` + hostURL;
          logoutURL +
          `/login/api/terminate_session?post_logout_redirect_uri=` +
          hostLogoutURL;
      }
    );
  }
  const onVelingenSelection = useCallback(() => {
    let className = "minidrawer-list-wrapper";

    if (
      navAction === "auction-dashboard" ||
      navAction === "auction-available-cars" ||
      navAction === "mybid"
    ) {
      className = "minidrawer-list-wrapper active";
    }

    return className;
  }, [navAction]);

  const onVooradSelection = useCallback(() => {
    return navAction === "stockoverview"
      ? "minidrawer-list-wrapper active"
      : "minidrawer-list-wrapper";
  }, [navAction]);

  const handleOnboarding = () => {
    setToggleTour(!toggletour);
    // console.log("toggletour", toggletour);
  };

  const setMenuSelection = useCallback(() => {
    // console.log("currentLocation", currentLocation.pathname);

    switch (currentLocation.pathname) {
      case "/AuctionDashboard":
        setNavAction("auction-dashboard");

        break;

      case "/AvailableCars":
        setNavAction("auction-available-cars");

        break;

      case "/mybids":
        setNavAction("mybid");

        break;

      case "/stockmanagement":
        setNavAction("stockoverview");
        break;

      default:
        // This is for intialtime load from landing page
        if(userRole === USER_ROLE_SSM) {
          setNavAction("stockoverview");
        } else{
          setNavAction("auction-dashboard");
        }
        break;
    }
  }, [currentLocation.pathname, userRole]);

  
  useEffect(() => {
    switch (currentLocation.pathname) {
      case "/AuctionDashboard":
        setNavAction("auction-dashboard");

        break;

      case "/AvailableCars":
        setNavAction("auction-available-cars");

        break;

      case "/mybids":
        setNavAction("mybid");

        break;

      case "/stockmanagement":
        setNavAction("stockoverview");

        break;

      default:
        break;
    }
  }, [currentLocation.pathname]);

  useEffect(() => {
    if(userRole === USER_ROLE_SSM 
      && AUCTION_MENU_ITEMS.includes(currentLocation.pathname)
      ) {
      handleOverViewClick('stockoverview');
    } else if(userRole === USER_ROLE_AUCTION
      && SSM_MENU_ITEMS.includes(currentLocation.pathname)
      ) {
      history.push('/AuctionDashboard');
      window.location.reload();
    }
    setMenuSelection();
  }, [currentLocation.pathname, handleOverViewClick, history, setMenuSelection, userRole]);

  const handleCarousel = () => {
    switch (currentLocation) {
      case "/AvailableCars":
        return 1;

      case "/AuctionDashboard":
        return 0;

      case "/MyBids":
        return 2;

      default:
        return 0;
    }

    // }
  };
  const handleClickStart = (e, index = 0) => {
    e.preventDefault();

    setSelectIndex(index);

    setToggleTour(!toggletour);

    // console.log("toggletour====>", toggletour);
    setRun({
      run: true,
      selectindex: setSelectIndex(index),
    });
  };

  const handleClickCarousel = (index) => {
    if (index === 0) {
      setRun({ run: true, stepIndex: 0 });
    }
  };
  const getElement = (element) => {
    /* istanbul ignore else */
    if (element === "string") {
      return document.querySelector(element);
    }

    return element;
  };
  const handleJoyrideCallback = (data) => {
    const { status, type, controlled, index, step } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.RUNNING];
    const eventStatus = [EVENTS.TARGET_NOT_FOUND];

    const target = getElement(step?.target);

    if (!controlled && status === finishedStatuses && index === 0 && !target) {
      this.store.update({ index: index + 1 });

      this.callback({
        ...this.state,
        type: eventStatus,
        step,
      });
    }

    if (finishedStatuses.includes(status)) {
      setRun({ run: true });
    }

    if (step.stepIndex === 15) {
      setRun({ run: true });
    }

    console.count(type);
    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };
 
  const getAuctionNavMenu = useCallback(() => {
    return (
      <div>
        <ListItemButton
          onClick={() => toggelListOpen("veligen")}
          className="heading-navbar"
        >
          <ListItemIcon style={{ minWidth: "57px" }}>
            {/* <AccessTimeIcon /> */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={onVelingenSelection()}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 21H13V23H1V21ZM5.24 8.07L8.07 5.24L22.21 19.38L19.38 22.21L5.24 8.07ZM3.83 9.48L9.49 15.14L6.66 17.97L1 12.31L3.83 9.48ZM12.32 1L17.98 6.66L15.15 9.49L9.49 3.83L12.32 1Z"
                fill="#90A0B7"
              />
            </svg>
          </ListItemIcon>
          <ListItemText
            primary="Veilingen"
            className={onVelingenSelection()}
          />
          {veligenlistOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse
          in={veligenlistOpen}
          timeout="auto"
          unmountOnExit
          className="minidrawer-list-wrapper"
        >
          <List component="div" disablePadding>
            <div
              className={
                navAction === "auction-dashboard"
                  ? "navbarItem-active"
                  : "navbarItem"
              }
            >
              <Link
                to={"/AuctionDashboard"}
                className={
                  navAction === "auction-dashboard"
                    ? "menuCustomLink active"
                    : "menuCustomLink"
                }
                id="auction-dashboard"
                onClick={() => setNavAction("auction-dashboard")}
              >
                <ListItemButton sx={{ pl: 3 }} className="heading-navbar">

                  <ListItemIcon>
                    {/* <AccessTimeIcon /> */}
                    <svg
                      id="auction-dashboard-fill"
                      className={
                        navAction === "auction-dashboard"
                          ? "active"
                          : "navbar-fill"
                      }
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="1"
                        width="14"
                        height="6"
                        rx="3"
                        stroke="#90A0B7"
                        stroke-width="2"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />

                </ListItemButton>
              </Link>
            </div>
            <div
              className={
                navAction === "auction-available-cars"
                  ? "navbarItem-active"
                  : "navbarItem"
              }
            >
              <Link
                to={"/AvailableCars"}
                id="auction-available-cars"
                className={
                  navAction === "auction-available-cars"
                    ? "menuCustomLink active"
                    : "menuCustomLink"
                }
                onClick={() => setNavAction("auction-available-cars")}
              >
                <ListItemButton sx={{ pl: 3 }} className="heading-navbar">

                  <ListItemIcon>
                    {/* <AccessTimeIcon /> */}
                    <svg
                      id="auction-available-cars-fill"
                      className={
                        navAction === "auction-available-cars"
                          ? "active"
                          : "navbar-fill"
                      }
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="1"
                        width="14"
                        height="6"
                        rx="3"
                        stroke="#90A0B7"
                        stroke-width="2"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary="Beschikbare voertuigen" />

                </ListItemButton>
              </Link>
            </div>
            <div
              className={
                navAction === "mybid" ? "navbarItem-active" : "navbarItem"
              }
            >
              <Link
                to={"/mybids"}
                id="mybid"
                className={
                  navAction === "mybid"
                    ? "menuCustomLink active"
                    : "menuCustomLink"
                }
                onClick={() => setNavAction("mybid")}
              >
                <ListItemButton
                  sx={{ pl: 3, mr: 0.5 }}
                  className="heading-navbar"
                >

                  <ListItemIcon>
                    <svg
                      id="mybid-fill"
                      className={
                        navAction === "mybid" ? "active" : "navbar-fill"
                      }
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="1"
                        width="14"
                        height="6"
                        rx="3"
                        stroke="#90A0B7"
                        stroke-width="2"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary="Mijn biedingen" />

                </ListItemButton>
              </Link>
            </div>
          </List>
        </Collapse>
      </div>
    );
  }, [navAction, onVelingenSelection, toggelListOpen, veligenlistOpen]);

  const getSSMNavMenu = useCallback(() => {
    return (
      <div>
        <ListItemButton
          onClick={() => toggelListOpen("Stock")}
          className="heading-navbar"
        >
          <ListItemIcon style={{ minWidth: "57px" }}>
            <StockIcon navAction={navAction} />
          </ListItemIcon>
          <ListItemText primary="Voorraad" className={onVooradSelection()} />
          {stocklistOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse
          in={stocklistOpen}
          timeout="auto"
          unmountOnExit
          className="minidrawer-list-wrapper"
        >
          <List component="div" disablePadding>
            <div
              className={
                navAction === "stockoverview"
                  ? "navbarItem-active"
                  : "navbarItem"
              }
            >
              <Link
                to={"/stockmanagement"}
                id="stockoverview"
                className={
                  navAction === "stockoverview"
                    ? "menuCustomLink active"
                    : "menuCustomLink"
                }
                onClick={() => handleOverViewClick("stockoverview")}
              >
                <ListItemButton sx={{ pl: 3 }} className="heading-navbar">

                  <ListItemIcon >
                    {/* <AccessTimeIcon /> */}
                    <svg
                      id="stockoverview-fill"
                      className={
                        navAction === "stockoverview"
                          ? "active"
                          : "navbar-fill"
                      }
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="1"
                        width="14"
                        height="6"
                        rx="3"
                        stroke="#90A0B7"
                        stroke-width="2"
                      />
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary="Overzicht" />

                </ListItemButton>
              </Link>
            </div>
          </List>
        </Collapse>
      </div>
    );
  }, [handleOverViewClick, navAction, onVooradSelection, stocklistOpen, toggelListOpen]);

  const loadAllMenuItems = useCallback(() => {
    return (
      <div>
        {getAuctionNavMenu()}
        {/* {getSSMNavMenu()} */}
      </div>
    )
  }, [getAuctionNavMenu]);

  const getNavigationMenus = useCallback(() => {
    switch(userRole) {
      case USER_ROLE_AUCTION:
        return getAuctionNavMenu();
      case USER_ROLE_SSM:
        return getAuctionNavMenu();
      case USER_ROLE_AUCTION_SSM:
        return loadAllMenuItems();
      default: 
      return getAuctionNavMenu();
    }
  }, [ getAuctionNavMenu, loadAllMenuItems, userRole]);

  const isSSMUser = userRole === USER_ROLE_SSM;
  // console.log(isSSMUser);
  return (
    <BrowserRouter>
      <Box sx={{ display: "flex" }} className="drawerContainer" data-testid = "mini-drawer-component">
        <CssBaseline />
        <AppBar position="fixed" open={open} className="appBar" elevation={1}>
          <Toolbar>
            <div>
              {!open ? (
                <img
                  src={shortLogo}
                  alt=""
                  style={{ width: "72px", height: "64px" }}
                />
              ) : null}
            </div>

            <Typography
              variant="h6"
              noWrap
              component="div"
              className="headerTopBar"
            >
              Contact: 020-5497850 of via@bovemij.nl
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader className="drawerLogoContainer">
            <Logo  style={{width:'179px', marginRight:'25px'}} />
          </DrawerHeader>
          <div
            style={{
              height: '1px',
              background: '#EBEFF2'
            }}></div>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  alt="Dummy Image"
                  src="http://reactapp-viabovemij-acc-001.azurewebsites.net/static/media/profile_image.3c7e851f.jpg"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="h5"
                      color="text.primary"
                    >
                      {firstName?.length > 5
                        ? `${firstName[0]}. `
                        : firstName + " "}{" "}
                      {lastName}
                    </Typography>
                  </React.Fragment>
                }
              />

              <MoreVertIcon
                onClick={handleClick}
                className="moreverticon-dropdown"
                style={{ fontSize: "20px"}}
              />
              <Menu
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    width: "165",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem className="profilemenuCustomLink">
                  <Link to={`/userprofile`}>
                    <ProfileIcon /> Mijn profiel
                  </Link>
                </MenuItem>

                <Divider />
                <MenuItem
                  className="profilemenuCustomLink"
                  onClick={() => handleLogoutClick()}
                >
                  <LogoutIcon /> Uitloggen
                </MenuItem>
              </Menu>

              {/* <Tooltip title="Account settings">
              <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <MoreVertIcon />
          </IconButton>
          </Tooltip> */}
              {/* </ListItemIcon> */}
            </ListItem>
            {getNavigationMenus()}

            {/* <ListItemButton>
              <ListItemIcon style={{ minWidth: "57px" }}>
                <QuestionCircleFilled
                  style={{ fontSize: "2rem", fill: "#fff" }}
                />
              </ListItemIcon>
              <ListItemText
                onClick={handleOnboarding}
                primary="Onboarding"
                className="minidrawer-list-wrapper"
              />
            </ListItemButton> */}
          </List>

          <List className="menu-toggle">
            <ListItem
              button
              onClick={handleDrawerToggle}
              className="toggleButton"
            >
              <ListItemIcon>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="#C6CBD4"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 0C0.906937 0 0 0.906937 0 2V16C0 17.0931 0.906937 18 2 18H16C17.0931 18 18 17.0931 18 16V2C18 0.906937 17.0931 0 16 0H2ZM3 2C3.552 2 4 2.448 4 3C4 3.552 3.552 4 3 4C2.448 4 2 3.552 2 3C2 2.448 2.448 2 3 2ZM6 2C6.552 2 7 2.448 7 3C7 3.552 6.552 4 6 4C5.448 4 5 3.552 5 3C5 2.448 5.448 2 6 2ZM9 2H16V16H9V2ZM3 5H6C6.552 5 7 5.448 7 6C7 6.552 6.552 7 6 7H3C2.448 7 2 6.552 2 6C2 5.448 2.448 5 3 5Z"
                    fill="#C6CBD4"
                  ></path>
                </svg>
              </ListItemIcon>
              <ListItemText
              className="toggle-name"
                primary={"Menu wisselen"}
                style={{ color: "rgb(198, 203, 212)" }}
              />
            </ListItem>
          </List>
        </Drawer>

        <Modal
          open={toggletour}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className="modal-dialog-container modalclass"
        >
          <ModalBody>
            <Carousel
              autoPlay={false}
              autoFocus={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <span
                    onClick={onClickHandler}
                    title={label}
                    className="previous-icon-image-navbar"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.51 3.86998L15.73 2.09998L5.84 12L15.74 21.9L17.51 20.13L9.38 12L17.51 3.86998Z"
                        fill="#FCFCFC"
                      />
                    </svg>
                  </span>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <span
                    onClick={onClickHandler}
                    title={label}
                    className="next-icon-image-navbar"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.48999 18.13L2.25999 19.9L12.16 9.99998L2.25999 0.0999756L0.48999 1.86998L8.61999 9.99998L0.48999 18.13H0.48999Z"
                        fill="#FCFCFC"
                      />
                    </svg>
                  </span>
                )
              }
              selectedItem={handleCarousel()}
            >
              <div className="modal-body onboarding-container">
                <div className="onboarding-container-styleImage">
                  <img
                    // style=/{style}
                    src={OnboardingModalImage}
                    alt="foto beschikbaar"
                  ></img>
                </div>
                <div>
                  <div
                    // style={styleText}
                    className="onboarding-container-styleTextModal"
                  >
                    <div className="styleTextModal-container">
                      <h1 className="styletextmodal-title">Het dashboard</h1>
                      <span className="styletextmodal-subtitle">
                        <span style={{ textAlign: "left" }}>
                          {" "}
                          Het dashboard geeft snel inzicht in de status van
                          biedingen en veilingen.{" "}
                        </span>
                        <ul className="list">
                          <li style={{ listStyle: "disc" }}>
                            Bovenaan de pagina vindt u enkele kengetallen
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Het overzicht biedingen toont degene die als eerst
                            eindigen
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            De veiling kaartjes heeft een overzicht van de
                            lopende, aankomende of recent gesloten veilingen.
                          </li>
                        </ul>
                      </span>
                    </div>
                    <div className="skip-container-wrapper">
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOnboardingClose}
                          className="skip-container"
                        >
                          Sla over
                        </Button>
                      </span>
                      <span className="footer_button">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => handleClickStart(e)}
                        >
                          Start tour
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body onboarding-container">
                <div className="onboarding-container-styleImage">
                  <img
                    // style=/{style}
                    src={OnboardingModalImage}
                    alt="foto beschikbaar"
                  ></img>
                </div>
                <div>
                  <div
                    // style={styleText}
                    className="onboarding-container-styleTextModal"
                  >
                    <div className="styleTextModal-container">
                      <h1 className="styletextmodal-title">
                        Welkom bij de autoveiling.
                      </h1>
                      <span className="styletextmodal-subtitle">
                        <span>
                          Deze veiling is exclusief voor Bovag- en Bovemij
                          leden.{" "}
                        </span>
                        <span>
                          Periodiek wordt er een veiling georganiseerd met
                          auto’s die zijn verkocht door consumenten op{" "}
                          <Link to="https://www.viabovag.nl/">viaBovag.nl</Link>
                          .
                        </span>
                        <ul>
                          <li style={{ listStyle: "disc" }}>
                            Het betreft een gesloten veiling met een vaste
                            looptijd. Binnen deze tijd kan er op één of meerdere
                            auto’s.
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Zodra de veiling gesloten is worden alle biedingen
                            beoordeeld en binnen 24 uur worden de resultaten
                            bekend gemaakt.
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Wanneer een auto is toegekend wordt er contact
                            opgenomen door ons partner Biddo voor de afhandeling
                            van de aankoop.
                          </li>
                        </ul>
                      </span>
                    </div>
                    <div className="skip-container-wrapper-auction">
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOnboardingClose}
                          className="skip-container"
                        >
                          Sla over
                        </Button>
                      </span>
                      <span className="footer_button">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => handleClickStart(e, 5)}
                        >
                          Start tour
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body onboarding-container">
                <div className="onboarding-container-styleImage">
                  <img
                    // style=/{style}
                    // src={OnboardingMyBidsModalImage}
                    alt="foto beschikbaar"
                  ></img>
                </div>
                <div>
                  <div
                    // style={styleText}
                    className="onboarding-container-styleTextModal"
                  >
                    <div className="styleTextModal-container">
                      <h1 className="styletextmodal-title">Mijn biedingen</h1>
                      <span className="styletextmodal-subtitle">
                        <li style={{ marginBottom: "6px" }}>
                          Dit geeft inzicht in de status van biedingen in
                          lopende en gesloten veilingen. Dit geeft inzicht in de
                          status van biedingen in lopende en gesloten veilingen.{" "}
                        </li>
                        <li style={{ marginBottom: "6px" }}>
                          Lopende biedingen kunnen hier ook worden aangepast of
                          ingetrokken.
                        </li>
                        <li style={{ marginBottom: "6px" }}>
                          Alle biedingen, inclusief de geannuleerde biedingen
                          komen in het archief terecht.
                        </li>
                      </span>
                    </div>
                    <div className="skip-container-wrapper">
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOnboardingClose}
                          className="skip-container"
                        >
                          Sla over
                        </Button>
                      </span>
                      <span className="footer_button">
                        <Button
                          variant="contained"
                          color="primary"
                        // onClick={(e) => handleClickStart(e, 15)}
                        >
                          Start tour
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </ModalBody>
        </Modal>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Joyride
            onClickItem={handleClickCarousel}
            // selectedItem={handleCarousel()}
            callback={handleJoyrideCallback}
            continuous={true}
            scrollToSteps={true}
            keyboardNavigation={true}
            scrollOffset={10}
            run={run}
            scrollToFirstStep={false}
            showProgress={false}
            showSkipButton={true}
            stepIndex={selectindex}
            steps={steps}
            styles={{
              options: {
                zIndex: 10000,
              },
              beacon: {
                offsetX: 10,
                offsetY: 10,
                inner: "#000",
                outer: "#000",
              },
            }}
          />
          <DrawerHeader />
          <div className="mainContent">
          <Switch>
            <PrivateRoute
              path={"/landingpage"}
              component= {isSSMUser ?  StockManagementMashup:AuctionDashboard }
            
            // onClick={handleOpen}
            ></PrivateRoute>

            {window.location.hostname !== "via-bovemij.azurewebsites.net" && window.location.hostname !== "reactapp-viabovemij-acc-001.azurewebsites.net" &&
             window.location.pathname === '/landingpage' ? (
              <Route
                path="/landingpage"
                render={() => (
                  <Redirect from="/landingpage" to={isSSMUser ? "/stockManagement" : "/AuctionDashboard"}/>
                )}
              />
            ) : null}

            <PrivateRoute
              path="/AuctionDashboard"
              component={AuctionDashboard}
            />

            {/* <PrivateRoute path="/Dashboard" component={Dashboard} /> */}
            <PrivateRoute exact path={`/AvailableCars`} component={AvailableCars} />

            <PrivateRoute exact path="/mybids" component={MyBids} />
            <PrivateRoute
              exact
              path={`/carpassport/vehicleById/:id`}
              component={CarPassport}
            />
            <PrivateRoute
              exact
              path={`/carpassportslider/vehicleById/:id`}
              component={CarPassportSlider}
            />
            <PrivateRoute
              exact
              path={`/upcoming/vehicleById/:id`}
              component={UpcomingCarPassport}
            />
            <PrivateRoute
              exact
              path={`/update/vehicleById/:id`}
              component={UpdateCarPassport}
            />
            <PrivateRoute
              exact
              path={`/update/lotId/:id`}
              component={UpdateCarPassport}
            />
            <PrivateRoute
              exact
              path={`/waiting/vehicleById/:id`}
              component={WaitingCarPassport}
            />
            <PrivateRoute
              exact
              path={`/waiting/lotId/:id`}
              component={WaitingCarPassport}
            />
            <PrivateRoute
              exact
              path={`/lost/vehicleById/:id`}
              component={LostCarPassport}
            />
            <PrivateRoute
              exact
              path={`/lost/lotId/:id`}
              component={LostCarPassport}
            />
            <PrivateRoute path="/userprofile" component={UserProfile} />
            <PrivateRoute
              path="/userOrganization"
              component={UserOrganization}
            />
            <PrivateRoute
              exact
              path={`/won/vehicleById/:id`}
              component={WonCarPassport}
            />
            {/* ----StockRoute---- */}
            <PrivateRoute
              path={"/stockManagement"}
              exact
              component={StockManagementMashup}
              // component={() => <StockManagementMashup/> }
              // globalStore="wkoejdweoi"
              // render={() => <StockManagementMashup globalStore="test1" /> }
            />
            <PrivateRoute
              component={PageNotFoundComponent}
            />
            </Switch>
            {/* ----StockRoute---- */}
          </div>
        </Box>
      </Box>
    </BrowserRouter>
  );
}
