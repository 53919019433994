import React from "react";
import DamageAccordion from "../Accordion/DamageAccordion";
import "./DamageContainer.css";
import CarPlaceholder from "../../../../images/Damage-part-photo-not-available-small.svg"

const DamageContainer = (props) => {
  let damages = props.damageList ? props.damageList : [];

  let damageCount = props.damageCount;
  let hasDamage = damageCount > 0;
  let damageType = props.damageType;

  const getImageUrl = (damageDetails) => {
    const testURL = process.env.REACT_APP_TESTAPI_URL;
    if (
      damageDetails &&
      damageDetails.media &&
      damageDetails.media.urlFullSize
    ) {
      return `${testURL}${damageDetails.media.urlFullSize}`;
    } else {
      //  when no images are coming
      return CarPlaceholder;
    }
  };

  const getDamageContent = () => {
    if (damages.length > 0) {
      return (
        <div
          className={`damageWrapperContent ${
            damageType === "optical" ? "opticalWrapper" : "technicalWrapper"
          }`}
        >
          {damages.map((damageDetails, index) => {
            let damageList =
              damageType === "optical"
                ? Object.values(damageDetails)[0]
                : [damageDetails];
            let damageTitle =
              damageList[0] && damageList[0].part ? damageList[0].part : null;
            return (
              <DamageAccordion
                key={index}
                index={index}
                sliderHandler={props.sliderHandler}
                getImageFunc={getImageUrl}
                title={damageTitle}
                damageContent={damageList}
                damageType={damageType}
              />
            );
          })}
        </div>
      );
    } else {
      return <div className="noContentText">{props.noContentText}</div>;
    }
  };
  return (
    <div className="damageWrapper">
      <div className="damageHeader">
        <span className="damageTitle">{props.title}</span>
        <span data-testid="countContent"
          className={`damageCount ${hasDamage ? "damageColor" : "normalColor"}`}
        >{`(${damageCount})`}</span>
      </div>
      <div data-testid="damageWrapperContent">{getDamageContent()}</div>
    </div>
  );
};

export default DamageContainer;
