import React,{useState, useEffect} from 'react';
import { Tabs } from "antd";
import './user-organization.css';
import Titleheader from "../atoms/TitleHeader/titleheader";
import UserorganizationComponent from '../components/User-Organization/userorganizationComponent';
import axios from 'axios';
const { TabPane } = Tabs;

function callback(key) {
  
}

function UserOrganization(props) {
  const hostURL = process.env.REACT_APP_SERVER_URL;
  const [userorganization,setUserorganization] =useState();
const [getToken, setGetToken] = useState(localStorage.getItem("Token"));
const [getUserid, setGetUserid] = useState(localStorage.getItem("userid"));

useEffect(() => {
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };
    
    fetch(`https://viabovemij-api.azurewebsites.net/api/CompanyProfile/Profie/${JSON.parse(getUserid)}`,
      requestGetOptions
    )
     .then((response) => response.json())
      .then((data) => {
        setUserorganization(data);
       
      })
}, []);


    return (
     
          <div className="wrapper" data-testid = "user-organization-container">
          <div className="mybids-container">
            <section>
              <div
                className="container-top-header"
                style={{ background: "#fcfcfc" }}
              >
                <div className="title-heading-right-block">Mijn organisatie</div>
                <div className="title-heading-left-block"></div>
              </div>
            </section>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Organisatiedetails" key="1">
                {/* <UserprofileComponent /> */}
       
                <UserorganizationComponent userorganization={userorganization}/>
              </TabPane>
              {/* <TabPane tab="Abonnementen" key="2">
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab="Locaties" key="3">
                Content of Tab Pane 3
              </TabPane>
              <TabPane tab="Gebruikers" key="4">
                Content of Tab Pane 4
              </TabPane> */}
            </Tabs>
          </div>
        </div>
   
       
    );
}

export default UserOrganization;