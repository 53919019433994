import React, { useRef, useState } from "react";
import { Overlay, Tooltip, Button } from "react-bootstrap";

function InfoIcon({ props, children }) {
  // const [show, setShow] = useState(false);
  // const target = useRef(null);
  return (
    <>
      {/* <div
        ref={target}
        onClick={() => setShow(!show)}
        // onMouseOver={() => setShow(!show)}
        // onMouseLeave={() => setShow(show)}
      > */}
        <svg
          width="15.33"
          height="15.33"
          viewBox="0 0 20 20"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          // style={{marginTop:'11px'}}
        >
          <path
            fillRule="ffffff"
            clipRule="ffffff"
            d="M9.16699 9.1665H10.8337V14.1665H9.16699V9.1665ZM9.16699 5.83317H10.8337V7.49984H9.16699V5.83317ZM10.0003 1.6665C5.40033 1.6665 1.66699 5.39984 1.66699 9.99984C1.66699 14.5998 5.40033 18.3332 10.0003 18.3332C14.6003 18.3332 18.3337 14.5998 18.3337 9.99984C18.3337 5.39984 14.6003 1.6665 10.0003 1.6665ZM10.0003 16.6665C6.32533 16.6665 3.33366 13.6748 3.33366 9.99984C3.33366 6.32484 6.32533 3.33317 10.0003 3.33317C13.6753 3.33317 16.667 6.32484 16.667 9.99984C16.667 13.6748 13.6753 16.6665 10.0003 16.6665Z"
            fill="#ffffff"
          />
        </svg>
      {/* </div> */}
      {/* <Overlay target={target.current} show={show} placement="top">
        {(props) => <Tooltip {...props}>{children}</Tooltip>}
      </Overlay> */}
    </>
  );
}

export default InfoIcon;
