import React from "react";
import DamageCarIcons from "../Icons/DamageIcon/damageCarIcons";
import "./DamageHeaderSection.css";

const DamageHeaderSection = ({ isDamage }) => {
  let damageText = isDamage ? "Beschadigd" : "Geen";
  let textColorClass = isDamage ? "damagedTextColor" : "safeTextColor";
  return (
    <div className="damageSectionContainer">
      <div className="damageIconContainer" data-testid="headerIconSection">
        <DamageCarIcons isDamage={isDamage} />
      </div>
      <div className="damageTextContainer">
        <span className="damageSectionTitle" data-testid="headerTitle">Schade</span>
        <span className={`damageSectionSubtitle ${textColorClass}`} data-testid="headerDescription">
          {damageText}
        </span>
      </div>
    </div>
  );
};

export default DamageHeaderSection;
