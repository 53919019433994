import React from 'react';
import AuctionImage from "../../../shared/AuctionImage/auctionImage";
import RoundBlankCheckbox from "../../Icons/roundBlankCheckbox";
import dateFormat from "dateformat";

function InconsiderationAuctionComponent(props) {
    return (
      <div
        className="auction-dashboard-matrics-card closed" 
        data-testid = "inconsideration-auction-parent"
      >
        <div className="auction-dashboard-hot-matrics-header">
          {props.isHovered && (
            <RoundBlankCheckbox
              id="auctionControl"
              checkboxRef={props.checkboxAuctionRunningRef}
              className="non-checked"
            />
          )}
          <div className="auction-dashboard-header-vehicle-name">
            <span className="auction-vehicle-name-heading">Veiling</span>
            <span className="auction-vehicle-name-subheading-closed">
              {props.auctionapi?.profile?.name}
            </span>
          </div>
          <div className="auction-dashboard-header-start-date">
            <span className="auction-vehicle-name-heading">Startdatum/tijd</span>
            <span className="auction-vehicle-name-subheading-closed">
              <span>
                {dateFormat(props.auctionapi.openForBidding, "dd-mm-yy")}
              </span><br/>
              <span>
                {props.auctionapi.openForBidding ||
                props.auctionapi.openForBidding === "undefined"
                  ? dateFormat(props.auctionapi.openForBidding, "HH")
                  : null}
                :
                {props.auctionapi.openForBidding ||
                props.auctionapi.openForBidding === "undefined"
                  ? `${dateFormat(props.start, "MM")}`
                  : null}
              </span>
            </span>
          </div>
          <div className="auction-dashboard-header-end-date">
            <span className="auction-vehicle-name-heading">Einddatum/tijd</span>
            <span className="auction-vehicle-name-subheading-closed">
              <span>
                {dateFormat(
                  props.auctionapi?.auctionParameters?.closedForBidding,
                  "dd-mm-yy"
                )}
              </span><br/>
              <span>
                {dateFormat(
                  props.auctionapi?.auctionParameters?.closedForBidding,
                  "HH"
                )}
                :{dateFormat(props.end, "MM")}
              </span>
            </span>
          </div>
        </div>
        <div className="auction-dashboard-hot-matrics-description">
          <div className="auction-dashboard-description-title">Omschrijving</div>
          <div className="auction-dashboard-description-subtitle">
            {props.auctionapi?.description !== "string" ? (
              props.auctionapi.description
            ) : (
              <span>
                Lorem Ipsum is gewoon een proeftekst van de drukkerij- en
                zetindustrie.
              </span>
            )}
          </div>
        </div>
        <div className="auction-dashboard-hot-matrics-lots-image-closed">
          <AuctionImage
            length={5}
            carLogos={
              props.auctionapi.status === "InConsideration" &&
              props.filterRunningCarByCatalog(props.auctionapi)
            }
          />
  
          <div>
            <span className="auction-status-date-closed">Gesloten</span>
          </div>
        </div>
      </div>
    );
  }

export default InconsiderationAuctionComponent;