import React, { useCallback, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import "./sidebarOverlay.css";
import { Drawer, Breadcrumb } from "antd";
import FilterIcon from "../Icons/filterIcon";
import FilterCheckboxComponent from "./FilterComponents/FilterCheckboxComponent";
import RangeFilterComponent from "./FilterComponents/RangeFilterComponent/RangeFilterComponent";

function SidebarOverlayComponent(props) {
  const childRef = useRef();
  const yearRef = useRef();

  const {
    vehicleList,
    auctionList,
    selectedAuctionIds,
    applyFilterValues,
    defaultFilterAuctionSelectedCatelogId,
  } = props;
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const locationHistory = window.location.search;
  const [visible, setVisible] = useState(false);
  const [auctionFilterValue, setAuctionFilterValue] = useState([]);
  const [overdragenFilterValue, setOverdragenFilterValue] = useState([]);
  const [brandFilterValue, setBrandFilterValue] = useState([]);
  const [makeFilterValue, setMakeFilterValue] = useState([]);
  const [andreFilterValue, setAndreFilterValue] = useState([]);
  const [auctionSelectedValue, setAuctionSelectedValue] = useState([]);
  const [defaultAuctionSelectedValue, setdefaultAuctionSelectedValue] =
    useState([]);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterSelectdValues, setFilterSelectedValues] = useState({});
  const [lastAppliedFilterSelection, setLastAppliedFilterSelection] = useState(
    {}
  );
  const [minYearRange, setMinYearRange] = useState();
  const [maxYearRange, setMaxYearRange] = useState();
  const [minKMRange, setMinKMRange] = useState();
  const [maxKMRange, setMaxKMRange] = useState();

  const onClose = useCallback(
    (
      e,
      filterApplied = false,
      lastAppliedFilter = lastAppliedFilterSelection
    ) => {
      setVisible(false);
      setFilterApplied(filterApplied);
      if (
        !filterApplied &&
        _.isEmpty(_.get(lastAppliedFilterSelection, "auction"))
      ) {
        _.set(filterSelectdValues, "overdragen", []);
        _.set(filterSelectdValues, "maker", []);
        _.set(filterSelectdValues, "brandstof", []);
        _.set(filterSelectdValues, "andere", []);
        _.set(filterSelectdValues, "year", []);
        _.set(filterSelectdValues, "km", []);

        const selectedAuctionNames = defaultFilterAuctionSelectedCatelogId.map(
          (defaultauction) => {
            const selectedAuctionList = _.filter(auctionList, (auction) => {
              return auction.catalogID === defaultauction;
            });
            return _.get(selectedAuctionList[0], "profile.name");
          }
        );
        setAuctionSelectedValue(_.flatMap(selectedAuctionNames));
        filterSelectdValues["auctionName"] = _.flatMap(selectedAuctionNames);
        setFilterSelectedValues(filterSelectdValues);
        // let vehicleList = getFinalFiteredVehicleList();

        // yearRef?.current?.updateKmSelection();
        // childRef?.current?.updateKmSelection();
        // fetchMinMaxYearRange(vehicleList);
      }
      if (
        filterApplied ||
        !_.isEmpty(_.get(lastAppliedFilterSelection, "auction"))
      ) {
        setFilterSelectedValues(_.clone(lastAppliedFilter), () => {
          setAuctionSelectedValue(_.get(lastAppliedFilter, "auctionName"));
          // if (lastAppliedFilterSelection && lastAppliedFilterSelection.year) {
          //   getSelectedYearRange(lastAppliedFilterSelection.year);
          // }

          // if (lastAppliedFilterSelection && lastAppliedFilterSelection.km) {
          //   getSelectedKMRange(lastAppliedFilterSelection.km);
          // }
        });
      }
    },
    [
      auctionList,
      defaultFilterAuctionSelectedCatelogId,
      filterSelectdValues,
      lastAppliedFilterSelection,
    ]
  );

  const getFilterValue = useCallback((details, filterParam) => {
    let filteredValue = [];
    if (details) {
      filteredValue = details.map((info) => {
        return {
          value: _.get(info, filterParam),
          label: _.get(info, filterParam),
        };
      });
      filteredValue = _.uniqBy(filteredValue, "value");
    }
    return filteredValue;
  }, []);

  const getMakeFilterValue = useCallback(
    (details, filterParam1, filterParam2) => {
      let filteredValue = [];
      if (details) {
        _.forEach(details, (info, index) => {
          const duplicateIndex = _.findIndex(
            filteredValue,
            (e) => e.value === _.get(info, filterParam1)
          );
          if (duplicateIndex >= 0) {
            const duplicateValues = filteredValue[duplicateIndex];
            if (
              !_.some(
                duplicateValues,
                (e) => e.value === _.get(info, filterParam2)
              )
            ) {

              filteredValue[duplicateIndex].children.push({
                value: `${_.get(info, filterParam2)}$$${_.get(
                  info,
                  filterParam1
                )}$$${_.get(info,'itemInfo.id')}`,
                label: _.capitalize(_.get(info, filterParam2)),
              });
            }
          } else {
            const filteredItem = {
              value: _.get(info, filterParam1),
              label: _.capitalize(_.get(info, filterParam1)),
              children: [
                {
                  value: `${_.get(info, filterParam2)}$$${_.get(
                    info,
                    filterParam1
                  )}$$${_.get(info,'itemInfo.id')}`,
                  label: _.capitalize(_.get(info, filterParam2)),
                },
              ],
            };
            filteredValue.push(filteredItem);
          }
        });
      }
      return filteredValue;
    },
    []
  );

  const getSelectedAuctionVehicleList = useCallback(
    (selectedCatologIds) => {
      const vehicleSelectedAuction = selectedCatologIds.map((catalogId) => {
        const filteredVehicleList = _.filter(vehicleList, (vehicle) => {
          return vehicle.catalogID === catalogId;
        });
        return filteredVehicleList;
      });
      return _.flatMap(vehicleSelectedAuction);
    },
    [vehicleList]
  );

  const getSchadeFilterValues = useCallback((details, filterValue) => {
    let filteredValue = [];
    let damageFilter = [];
    if (details) {
      filteredValue = details.map((info) => {
        return {
          damageCount: _.get(info, filterValue),
        };
      });
      filteredValue = _.uniqBy(filteredValue, "damageCount");

      const isDamanged = _.some(
        filteredValue,
        (itemInfo) => itemInfo.damageCount > 0
      );
      const isNotDamanged = _.some(
        filteredValue,
        (itemInfo) => itemInfo.damageCount === 0
      );
      if (isDamanged) {
        damageFilter.push({
          value: "Schade",
          label: "Schade",
        });
      }
      if (isNotDamanged) {
        damageFilter.push({
          value: "Geen Schade",
          label: "Geen Schade",
        });
      }
    }
    return damageFilter;
  }, []);

  const getFilteredValue = useCallback(
    (filterCheckedvalue, filterdVehicle, vehicleNode) => {
      if (!_.isEmpty(filterCheckedvalue)) {
        const filteredVehicleList = filterCheckedvalue.map((filterParam) => {
          const filteredvalue = _.filter(filterdVehicle, (vehicle) => {
            return _.get(vehicle, vehicleNode) === filterParam;
          });
          return filteredvalue;
        });
        return _.flatMap(filteredVehicleList);
      }
      return filterdVehicle;
    },
    []
  );
  const getDamageFilteredValue = useCallback(
    (filterCheckedvalue, filterdVehicle, vehicleNode) => {
      if (!_.isEmpty(filterCheckedvalue)) {
        const filteredVehicleList = filterCheckedvalue.map((filterValue) => {
          const filteredvalue = _.filter(filterdVehicle, (vehicle) => {
            if (filterValue === "Schade") {
              return _.get(vehicle, vehicleNode) > 0;
            } else {
              return _.get(vehicle, vehicleNode) === 0;
            }
          });
          return filteredvalue;
        });
        return _.flatMap(filteredVehicleList);
      }
      return filterdVehicle;
    },
    []
  );

  const getMakeFilteredValue = useCallback(
    (
      filterCheckedvalue,
      filterdVehicle,
      vehicleParentNode,
      vehicleChildNode
    ) => {
      if (!_.isEmpty(filterCheckedvalue)) {
        const filteredVehicleList = filterCheckedvalue.map((filterParam) => {
          const splitArr = _.split(filterParam, "$$");
          const model = splitArr[0];
          const brand = splitArr[1];

          const filteredvalue = _.filter(filterdVehicle, (vehicle) => {
            return (
              _.get(vehicle, vehicleParentNode) === brand &&
              _.get(vehicle, vehicleChildNode) === model
            );
          });
          return filteredvalue;
        });
        return _.flatMap(filteredVehicleList);
      }
      return filterdVehicle;
    },
    []
  );

  const getKMRangeFilteredValue = useCallback(
    (selectedRange, vehicleList, comparingNode) => {
      return vehicleList.filter((vehicle) => {
        let vehicleMilage = _.get(vehicle, comparingNode);

        return (
          vehicleMilage >= selectedRange[0] && vehicleMilage <= selectedRange[1]
        );
      });
    },
    []
  );

  const getYearRangeFilteredValue = useCallback(
    (selectedRange, vehicleList, comparingNode) => {
      return vehicleList.filter((vehicle) => {
        let vehicleModelYear = new Date(
          _.get(vehicle, comparingNode)
        ).getFullYear();

        return (
          vehicleModelYear >= selectedRange[0] &&
          vehicleModelYear <= selectedRange[1]
        );
      });
    },
    []
  );

  const getFinalFiteredVehicleList = useCallback(() => {
    const auctionFilterValue = _.get(filterSelectdValues, "auction");
    const overdragenFilterValue = _.get(filterSelectdValues, "overdragen");
    const makerFilterValue = _.get(filterSelectdValues, "maker");
    const brandstofFilterValue = _.get(filterSelectdValues, "brandstof");
    const yearFilterValue = _.get(filterSelectdValues, "year");
    const kmFilterValue = _.get(filterSelectdValues, "km");

    const andereFilterValue = _.get(filterSelectdValues, "andere");
    let finalFilteredValue = [];

    const vehicleListBasedonAuction = auctionFilterValue?.map((catalogId) => {
      const filteredVehicleList = _.filter(vehicleList, (vehicle) => {
        return vehicle.catalogID === catalogId;
      });
      return filteredVehicleList;
    });
    finalFilteredValue = _.flatMap(vehicleListBasedonAuction);
    finalFilteredValue = getFilteredValue(
      overdragenFilterValue,
      finalFilteredValue,
      "itemInfo.transmission"
    );
    finalFilteredValue = getFilteredValue(
      brandstofFilterValue,
      finalFilteredValue,
      "itemInfo.fuelType"
    );
    finalFilteredValue = getMakeFilteredValue(
      makerFilterValue,
      finalFilteredValue,
      "itemInfo.brand",
      "itemInfo.model"
    );

    finalFilteredValue = getDamageFilteredValue(
      andereFilterValue,
      finalFilteredValue,
      "itemInfo.damagesCount"
    );
    if (yearFilterValue) {
      finalFilteredValue = getYearRangeFilteredValue(
        yearFilterValue,
        finalFilteredValue,
        "itemInfo.modelYear"
      );
    }
    if (kmFilterValue) {
      finalFilteredValue = getKMRangeFilteredValue(
        kmFilterValue,
        finalFilteredValue,
        "itemInfo.mileage"
      );
    }

    return finalFilteredValue;
  }, [
    filterSelectdValues,
    getDamageFilteredValue,
    getFilteredValue,
    getKMRangeFilteredValue,
    getMakeFilteredValue,
    getYearRangeFilteredValue,
    vehicleList,
  ]);

  const fetchMinMaxKMRange = useCallback(
    (filteredVehicles, initialCall = false) => {
      // let yearFilterList = filteredVehicles;
      // if (
      //   filterSelectdValues &&
      //   !_.isEmpty(filterSelectdValues["year"])
      // ) {
      //   yearFilterList = getYearRangeFilteredValue(
      //     filterSelectdValues["year"],
      //     filteredVehicles,
      //     "itemInfo.modelYear"
      //   );
      // }

      let mileageList = filteredVehicles.map((vehicleInfo) =>
        _.get(vehicleInfo, "itemInfo.mileage")
      );
      setMinKMRange(Math.min.apply(null, mileageList));
      setMaxKMRange(Math.max.apply(null, mileageList));

      // getSelectedKMRange([Math.min.apply(null, mileageList),Math.max.apply(null, mileageList)]);

      // }
    },
    []
  );

  const getSelectedYearRange = useCallback(
    (selectedRange) => {
      filterSelectdValues["year"] = selectedRange;
      filterSelectdValues["km"] = null;

      setFilterSelectedValues(filterSelectdValues);
      // childRef?.current?.updateKmSelection();

      let vehicleList = getFinalFiteredVehicleList();
      if (!_.isEmpty(vehicleList)) {
        setAndreFilterValue(
          getSchadeFilterValues(vehicleList, "itemInfo.damagesCount")
        );
        fetchMinMaxKMRange(vehicleList);
      }
    },
    [
      fetchMinMaxKMRange,
      filterSelectdValues,
      getFinalFiteredVehicleList,
      getSchadeFilterValues,
    ]
  );

  const fetchMinMaxYearRange = useCallback((filteredVehicles) => {
    let yearsList = filteredVehicles.map((vehicleInfo) =>
      new Date(_.get(vehicleInfo, "itemInfo.modelYear")).getFullYear()
    );
    setMinYearRange(Math.min.apply(null, yearsList));
    setMaxYearRange(Math.max.apply(null, yearsList));

    // let min = Math.min.apply(null, yearsList);
    // let max = Math.max.apply(null, yearsList);
    // getSelectedYearRange([min, max]);
  }, []);

  const getSelectedKMRange = useCallback(
    (selectedRange) => {
      filterSelectdValues["km"] = selectedRange;
      setFilterSelectedValues(filterSelectdValues);
      // childRef?.current?.updateKmSelection(selectedRange[0], selectedRange[1]);

      let vehicleList = getFinalFiteredVehicleList();
      if (!_.isEmpty(vehicleList)) {
        setAndreFilterValue(
          getSchadeFilterValues(vehicleList, "itemInfo.damagesCount")
        );
      }
    },
    [filterSelectdValues, getFinalFiteredVehicleList, getSchadeFilterValues]
  );

  const getOverdragenValues = useCallback(
    (selectedValue) => {
      filterSelectdValues["overdragen"] = selectedValue;
      setFilterSelectedValues(filterSelectdValues);
      const vehicleList = getFinalFiteredVehicleList();
      if (!_.isEmpty(vehicleList)) {
        setBrandFilterValue(getFilterValue(vehicleList, "itemInfo.fuelType"));
        setMakeFilterValue(
          getMakeFilterValue(vehicleList, "itemInfo.brand", "itemInfo.model")
        );
        setAndreFilterValue(
          getSchadeFilterValues(vehicleList, "itemInfo.damagesCount")
        );
        fetchMinMaxYearRange(vehicleList);
        fetchMinMaxKMRange(vehicleList);
      }
    },
    [
      fetchMinMaxKMRange,
      fetchMinMaxYearRange,
      filterSelectdValues,
      getFilterValue,
      getFinalFiteredVehicleList,
      getMakeFilterValue,
      getSchadeFilterValues,
    ]
  );
  const getBrandstofValues = useCallback(
    (selectedValue) => {
      filterSelectdValues["brandstof"] = selectedValue;
      setFilterSelectedValues(filterSelectdValues);

      const vehicleList = getFinalFiteredVehicleList();
      if (!_.isEmpty(vehicleList)) {
        setMakeFilterValue(
          getMakeFilterValue(vehicleList, "itemInfo.brand", "itemInfo.model")
        );
        setAndreFilterValue(
          getSchadeFilterValues(vehicleList, "itemInfo.damagesCount")
        );
        fetchMinMaxYearRange(vehicleList);
        fetchMinMaxKMRange(vehicleList);
      }
    },
    [
      fetchMinMaxKMRange,
      fetchMinMaxYearRange,
      filterSelectdValues,
      getFinalFiteredVehicleList,
      getMakeFilterValue,
      getSchadeFilterValues,
    ]
  );
  const getMakerValues = useCallback(
    (selectedValue) => {
      filterSelectdValues["maker"] = selectedValue;
      // filterSelectdValues["year"] = null;

      setFilterSelectedValues(filterSelectdValues);

      const vehicleList = getFinalFiteredVehicleList();
      if (!_.isEmpty(vehicleList)) {
        setAndreFilterValue(
          getSchadeFilterValues(vehicleList, "itemInfo.damagesCount")
        );
        fetchMinMaxYearRange(vehicleList);
        fetchMinMaxKMRange(vehicleList);
      }
    },
    [
      fetchMinMaxKMRange,
      fetchMinMaxYearRange,
      filterSelectdValues,
      getFinalFiteredVehicleList,
      getSchadeFilterValues,
    ]
  );
  const getAndereValues = useCallback(
    (selectedValue) => {
      _.set(filterSelectdValues, "andere", selectedValue);
      setFilterSelectedValues(filterSelectdValues);
    },
    [filterSelectdValues]
  );
  useEffect(() => {
    const selectedVehiclesDetails =
      getSelectedAuctionVehicleList(selectedAuctionIds);

    if (!_.isEmpty(auctionList)) {
      setAuctionFilterValue(getFilterValue(auctionList, "profile.name"));
    }
    if (!_.isEmpty(selectedVehiclesDetails)) {
      setOverdragenFilterValue(
        getFilterValue(selectedVehiclesDetails, "itemInfo.transmission")
      );
      setBrandFilterValue(
        getFilterValue(selectedVehiclesDetails, "itemInfo.fuelType")
      );
      setMakeFilterValue(
        getMakeFilterValue(
          selectedVehiclesDetails,
          "itemInfo.brand",
          "itemInfo.model"
        )
      );
      setAndreFilterValue(
        getSchadeFilterValues(selectedVehiclesDetails, "itemInfo.damagesCount")
      );
      fetchMinMaxYearRange(selectedVehiclesDetails);
      // getSelectedYearRange([min, max]);

      fetchMinMaxKMRange(selectedVehiclesDetails);
    }

    if (!_.isEmpty(auctionList)) {
      const selectedUniqueVehicle = _.uniqBy(
        selectedVehiclesDetails,
        "catalogID"
      );
      const selectedAuctionNames = selectedUniqueVehicle.map((vehicle) => {
        const selectedAuctionCatelogId = _.get(vehicle, "catalogID");
        const selectedAuctionList = _.filter(auctionList, (auction) => {
          return auction.catalogID === selectedAuctionCatelogId;
        });
        return _.get(selectedAuctionList[0], "profile.name");
      });
      setAuctionSelectedValue(selectedAuctionNames);
      if (_.isEmpty(defaultAuctionSelectedValue)) {
        setdefaultAuctionSelectedValue(selectedAuctionNames);
      }
    }
  }, [
    getFilterValue,
    getMakeFilterValue,
    auctionList,
    selectedAuctionIds,
    vehicleList,
    getSelectedAuctionVehicleList,
    filterSelectdValues,
    getSchadeFilterValues,
    defaultAuctionSelectedValue,
    fetchMinMaxYearRange,
    fetchMinMaxKMRange,
  ]);

  const getAuctionCheckedValues = useCallback(
    (checkedVaues) => {
      if (!_.isEmpty(auctionList)) {
        const selectedCatalogIds = checkedVaues.map((selectedvalue) => {
          const selectedAuctionList = _.filter(auctionList, (auction) => {
            return auction.profile.name === selectedvalue;
          });
          return _.get(selectedAuctionList[0], "catalogID");
        });
        filterSelectdValues["auction"] = selectedCatalogIds;
        filterSelectdValues["auctionName"] = checkedVaues;
        setAuctionSelectedValue(checkedVaues);
        setFilterSelectedValues(filterSelectdValues);
        const vehicleSelectedAuction =
          getSelectedAuctionVehicleList(selectedCatalogIds);
        setOverdragenFilterValue(
          getFilterValue(vehicleSelectedAuction, "itemInfo.transmission")
        );
        setBrandFilterValue(
          getFilterValue(vehicleSelectedAuction, "itemInfo.fuelType")
        );
        setMakeFilterValue(
          getMakeFilterValue(
            vehicleSelectedAuction,
            "itemInfo.brand",
            "itemInfo.model"
          )
        );
        setAndreFilterValue(
          getSchadeFilterValues(vehicleSelectedAuction, "itemInfo.damagesCount")
        );
        fetchMinMaxYearRange(vehicleSelectedAuction);
        fetchMinMaxKMRange(vehicleSelectedAuction);
      }
    },
    [
      auctionList,
      fetchMinMaxKMRange,
      fetchMinMaxYearRange,
      filterSelectdValues,
      getFilterValue,
      getMakeFilterValue,
      getSchadeFilterValues,
      getSelectedAuctionVehicleList,
    ]
  );

  const onApplyFilter = useCallback(() => {

    if (filterSelectdValues["year"] && !filterSelectdValues["year"]) {
      filterSelectdValues["km"] = [minKMRange, maxKMRange];
      setFilterSelectedValues(filterSelectdValues);
    }
    const finalFilteredValue = getFinalFiteredVehicleList();
    const auctionFilterValue = _.get(filterSelectdValues, "auction");
    setLastAppliedFilterSelection(_.clone(filterSelectdValues));
    applyFilterValues(auctionFilterValue, finalFilteredValue);
    setFilterApplied(true);
    onClose("", true, filterSelectdValues);
  }, [
    applyFilterValues,
    filterSelectdValues,
    getFinalFiteredVehicleList,
    onClose,
  ]);

  const kmFormatter = new Intl.NumberFormat("nl-NL", {
    unit: "kilometer",
  });
  
  return (
    <>
      <div data-testid="sidebar-overlay-breadcrumb-parent">
        <Breadcrumb className="availablecar-sidebar-title">
          <Breadcrumb.Item>
            <Link to={`/Menubar`}>Beschikbare voertuigen</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {/* <div
      className="availablecar-sidebar-menu-bars-filtersort"
      data-testid="availablecar-sidebar-menu-bars-filtersort"
    ></div> */}
      <div
        className="availablecar-sidebar-menu-bars-filtersort"
        onClick={showSidebar}
        style={{ display: _.isEmpty(auctionList) ? "none" : "block" }}
      >
        <div
          className="availablecar-sidebar-menu-bars-filtersort-sub"
          onClick={() => {
            setVisible({ visible: true });
          }}
        >
          <div className="availablecar-sidebar-label-icon-block">
            <Link
              to={`/AvailableCars${locationHistory}`}
              className="availablecar-sidebar-menu-bars"
            >
              <span className="sidebar-filter-title"> Filter</span>
            </Link>
          </div>

          <FilterIcon />
          {/* <SecondSettingsFilterIcon />
        <SettingsFilterIcon />  */}
        </div>
      </div>

      <div className="filtersort-sidebar-container1 availableCarsFilterSection">
        <Drawer
          className="filtersort-sidebar-container-title availableCarFilterDrawer"
          title="Filter"
          placement="right"
          onClose={onClose}
          visible={visible}
          maskClosable={true}
          width={313}
        >
          <div className={"filterWrapper"}>
            <div className="filterContentBody">
              <div className="filter-transmission-container">
                <FilterCheckboxComponent
                  heading={"Veilingen"}
                  nodes={auctionFilterValue}
                  selectedValues={auctionSelectedValue}
                  getCheckedValues={getAuctionCheckedValues}
                  isFilterApplied={isFilterApplied}
                  showSelectAllIcon={true}
                />
              </div>
              <div className="filter-transmission-container">
                <FilterCheckboxComponent
                  heading={"Transmissie"}
                  selectedValues={_.get(filterSelectdValues, "overdragen")}
                  nodes={overdragenFilterValue}
                  getCheckedValues={getOverdragenValues}
                  isFilterApplied={isFilterApplied}
                  showSelectAllIcon={true}
                />
              </div>
              <div className="filter-transmission-container">
                <FilterCheckboxComponent
                  heading={"Brandstof"}
                  selectedValues={_.get(filterSelectdValues, "brandstof")}
                  nodes={brandFilterValue}
                  getCheckedValues={getBrandstofValues}
                  isFilterApplied={isFilterApplied}
                  showSelectAllIcon={true}
                />
              </div>
              <div className="filter-transmission-container">
                <FilterCheckboxComponent
                  heading={"Merk"}
                  selectedValues={_.get(filterSelectdValues, "maker")}
                  nodes={makeFilterValue}
                  getCheckedValues={getMakerValues}
                  isFilterApplied={isFilterApplied}
                  showSelectAllIcon={true}
                />
              </div>
              {_.isInteger(minYearRange) && _.isInteger(maxYearRange) ? (
                <div className="filter-range-container yearRange">
                  <RangeFilterComponent
                    heading="Jaar"
                    minRange={minYearRange}
                    maxRange={maxYearRange}
                    labelFormattext={""}
                    steps={1}
                    ref={yearRef}
                    getRange={getSelectedYearRange}
                    isFilterApplied={isFilterApplied}
                    selectedRange={
                      !_.isEmpty(
                        _.get(filterSelectdValues, "year", [
                          minYearRange,
                          maxYearRange,
                        ])
                      )
                        ? _.get(filterSelectdValues, "year", [
                            minYearRange,
                            maxYearRange,
                          ])
                        : [minYearRange, maxYearRange]
                    }
                    marks={
                      <div className="rangeMark">
                        <div className="markerWraper" key={Math.random()}>
                          {/* {`${
                            _.get(filterSelectdValues, "year", [
                              minYearRange,
                              maxYearRange,
                            ])
                              ? _.get(filterSelectdValues, "year", [
                                  minYearRange,
                                  maxYearRange,
                                ])[0]
                              : minYearRange
                          }`} */}
                          {minYearRange}
                        </div>
                        <div
                          className="markerWraper endWraper"
                          key={Math.random()}
                        >
                          {/* {`${
                            _.get(filterSelectdValues, "year", [
                              minYearRange,
                              maxYearRange,
                            ])
                              ? _.get(filterSelectdValues, "year", [
                                  minYearRange,
                                  maxYearRange,
                                ])[0]
                              : maxYearRange
                          }`} */}
                          {maxYearRange}
                        </div>
                      </div>
                    }
                  />
                </div>
              ) : null}
              {_.isInteger(minKMRange) && _.isInteger(maxKMRange) ? (
                <div className="filter-range-container kmRange">
                  <RangeFilterComponent
                    heading="Kilometerstand"
                    minRange={minKMRange}
                    maxRange={maxKMRange}
                    labelFormattext="km"
                    isFilterApplied={isFilterApplied}
                    mark={null}
                    steps={100}
                    getRange={getSelectedKMRange}
                    ref={childRef}
                    selectedRange={
                      !_.isEmpty(
                        _.get(filterSelectdValues, "km", [
                        minKMRange,
                          maxKMRange,
                        ])
                      )
                        ? _.get(filterSelectdValues, "km", [
                          minKMRange,
                            maxKMRange,
                          ])
                        : [minKMRange, maxKMRange]
                    }
                    marks={
                      <div className="rangeMark">
                        <div className="markerWraper" key={Math.random()}>
                          {kmFormatter.format(minKMRange)}
                        </div>
                        <div
                          className="markerWraper endWraper"
                          key={Math.random()}
                        >
                          {kmFormatter.format(maxKMRange)}
                        </div>
                      </div>
                    }
                  />
                </div>
              ) : null}
              <div className="filter-transmission-container">
                <FilterCheckboxComponent
                  heading={"Overige filters"}
                  selectedValues={_.get(filterSelectdValues, "andere")}
                  nodes={andreFilterValue}
                  getCheckedValues={getAndereValues}
                  isFilterApplied={isFilterApplied}
                  showSelectAllIcon={true}
                />
              </div>
            </div>

            <div className="sidebar-overlay-footer-buttons-container">
              <div className="sidebar-overlay-footer">
                {/* needed in future- <button
              className="btn btn-reset filterFooterBtn"
              type="reset"
              onClick={onResetClick}
            >
              RESETTEN
            </button> */}

                <button
                  id="saveButton"
                  className="btn btn-sucess filterFooterBtn"
                  onClick={onApplyFilter}
                  type="button"
                >
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default SidebarOverlayComponent;
