import React from "react";
import './wonContainer.css'

function ReclaimContainer(props) {
  return (
    <div>
      <div className="quoteBreakdownComponent-header">
        <h4 style={{ marginTop: "25px" }}>Reclamatie periode</h4>
      </div>
      <div className="resistrationContainer-report-detail">
        Nadat de auto is afgeleverd gaat de 48 uur reclamatie periode in. Binnen
        dit termijn kun je eventuele reclamatie indienen bij Biddo.
      </div>
    </div>
  );
}

export default ReclaimContainer;
