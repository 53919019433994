import React from 'react';

function CarUspIcon(props) {
    return (
        <div>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 5.33301H6.66669C4.45335 5.33301 2.66669 7.11967 2.66669 9.33301V19.9997C2.66669 22.213 4.45335 23.9997 6.66669 23.9997L5.33335 25.333V26.6663H6.66669L9.33335 23.9997H12V17.333H5.33335V7.99967H17.3334V10.6663H20V9.33301C20 7.11967 18.2134 5.33301 16 5.33301ZM6.66669 18.6663C7.40002 18.6663 8.00002 19.2663 8.00002 19.9997C8.00002 20.733 7.40002 21.333 6.66669 21.333C5.93335 21.333 5.33335 20.733 5.33335 19.9997C5.33335 19.2663 5.93335 18.6663 6.66669 18.6663ZM27.4267 12.8797C27.24 12.3463 26.7334 11.9997 26.1334 11.9997H16.5467C15.9334 11.9997 15.44 12.3463 15.24 12.8797L13.3467 18.3597V25.7063C13.3467 26.213 13.76 26.6663 14.2667 26.6663H15.0934C15.6 26.6663 16 26.1597 16 25.653V23.9997H26.6667V25.653C26.6667 26.1597 27.08 26.6663 27.5867 26.6663H28.4C28.9067 26.6663 29.32 26.213 29.32 25.7063L29.3334 23.8797V18.3597L27.4267 12.8797ZM26.6667 21.333C25.9334 21.333 25.3334 20.733 25.3334 19.9997C25.3334 19.2663 25.9334 18.6663 26.6667 18.6663C27.4 18.6663 28 19.2663 28 19.9997C28 20.733 27.4 21.333 26.6667 21.333ZM16 21.333C15.2667 21.333 14.6667 20.733 14.6667 19.9997C14.6667 19.2663 15.2667 18.6663 16 18.6663C16.7334 18.6663 17.3334 19.2663 17.3334 19.9997C17.3334 20.733 16.7334 21.333 16 21.333ZM16.5467 13.333H26.1334L27.5067 17.333H15.1734L16.5467 13.333Z" fill="#C6CBD4"/>
</svg>
        </div>
    );
}

export default CarUspIcon;