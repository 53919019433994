import React from "react";
import "./wonContainer.css";

function QuoteBreakdownComponent({ bidAmount }) {
  return (
      <div className="quoteBreakdownComponent-header">
        <h4>Stap 1. Facturen en klaarzetten voor handelsvrijwaren </h4>
        <span className="quote-subtitle">
        Je ontvangt een factuur per e-mail met daarin een specificatie van de kosten en informatie over de betaling. Ook zal Autoplatform Nederland de auto via de RDW dienst handelsverkopen aanbieden. 
        </span>
      </div>    
  );
}

export default QuoteBreakdownComponent;
