import React from 'react';

function CarcalenderIcon(props) {
    return (
        <div>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.6667 3.99967H25.3333V1.33301H22.6667V3.99967H9.33334V1.33301H6.66667V3.99967H5.33334C3.86667 3.99967 2.66667 5.19967 2.66667 6.66634V27.9997C2.66667 29.4663 3.86667 30.6663 5.33334 30.6663H26.6667C28.1333 30.6663 29.3333 29.4663 29.3333 27.9997V6.66634C29.3333 5.19967 28.1333 3.99967 26.6667 3.99967ZM26.6667 27.9997H5.33334V13.333H26.6667V27.9997ZM26.6667 10.6663H5.33334V6.66634H26.6667V10.6663Z" fill="#C6CBD4"/>
</svg>
        </div>
    );
}

export default CarcalenderIcon;