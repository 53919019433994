import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ClockIcon from "../../components/Icons/clockIcon";
import "./auctionTimer.css";

export default function AuctionTimer({ enddayofbidding }) {
  const containerRef = useRef(null);
  const [barColor, setBarColor] = useState("#009e19");
  const [barWidth, setBarWidth] = useState(0);
  const [enableStatus, setEnableStatus] = useState(true);

  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval;
  let distance;
  let timerFunction = () => {
    let countDownDate = new Date(enddayofbidding).getTime();
    let now = moment(new Date().getTime());
    distance = countDownDate - now;
    if (distance < 0) {
      clearInterval(interval?.current);
    } else {
      let remainingtimesecondss = Math.floor(distance / 1000);
      let remainingtimeminutes = Math.floor(remainingtimesecondss / 60);
      let remainingtimehours = Math.floor(remainingtimeminutes / 60);
      
      remainingtimeminutes %= 60;
      remainingtimesecondss %= 60;
      setTimerHours(remainingtimehours);
      setTimerMinutes(remainingtimeminutes);
      setTimerSeconds(remainingtimesecondss);
      if(remainingtimehours<10){ 
        let customHours=remainingtimehours.toString().split("")
        customHours[1]=customHours[0]
        customHours[0]=0
        const finalCustomHours=customHours[0]+customHours[1];
        setTimerHours(finalCustomHours);
       }
       if(remainingtimeminutes<10){ 
        let customMinutes=remainingtimeminutes.toString().split("")
        customMinutes[1]=customMinutes[0]
        customMinutes[0]=0
        const finalCustomMinutes=customMinutes[0]+customMinutes[1];
        setTimerMinutes(finalCustomMinutes);
       }
      if(remainingtimesecondss<10){ 
      let customSeconds=remainingtimesecondss.toString().split("")
      customSeconds[1]=customSeconds[0]
      customSeconds[0]=0
      const finalCustomSeconds=customSeconds[0]+customSeconds[1];
      setTimerSeconds(finalCustomSeconds);
     }
      let refValue = containerRef?.current?.clientWidth;
      let miliToSeconds = distance !== "undefined" ? distance : null;
      let miliToSecond = miliToSeconds / 1000;
      let progressBarWidth;
      if (miliToSecond.toFixed(0) < 7200) {
        setBarColor("red");
        let fixedWidth = (miliToSecond.toFixed(0) * 30) / miliToSecond;
         progressBarWidth = refValue - fixedWidth;
        setBarWidth(progressBarWidth);
      } else if (
        7200 < miliToSecond.toFixed(0) &&
        miliToSecond.toFixed(0) < 86400
      ) {
        setBarColor("#F9B917");
        let fixedWidth = (miliToSecond.toFixed(0) * 125) / miliToSecond;
        progressBarWidth = refValue - fixedWidth;
        setBarWidth(progressBarWidth);
      } else if (
        86400 < miliToSecond.toFixed(0) &&
        miliToSecond.toFixed(0) < 172800
      ) {
        setBarColor("#009e19");
        let fixedWidth = (miliToSecond.toFixed(0) * 250) / miliToSecond;
         progressBarWidth = refValue - fixedWidth;
        //  console.log('progressBarWidth',progressBarWidth);
        setBarWidth(progressBarWidth);
      }else {
        setBarColor("#009e19");
        let fixedWidth =
          (miliToSecond.toFixed(0) * containerRef?.current?.clientWidth) /
          miliToSecond;
         progressBarWidth = refValue - fixedWidth;
        setBarWidth(progressBarWidth);
      }
    }
  }
  const startTimer = () => {
    timerFunction();
    interval = setInterval(timerFunction, 1000);
  };

  useEffect(() => {
  
    startTimer();
  }, []);

  const clockRef = useRef(null);
  const containerStyles = {
    width: `347px`,
    height: `16px`,
    backgroundColor: barColor,
    borderBottomRightRradius: "10px",
    borderBottomLeftRadius: "20px",
    borderTopLeftRadius: "20px",
  };

  const fillerStyles = {
    height: "100%",
    width: `${barWidth}px`,
    backgroundColor: "#e0e0de",
    transition: "width 1s ease-in-out",
    // borderRadius: "20px",
    textAlign: "right",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
  };

  const labelStyles = {
    padding: `0 15px 0px 17px`,
    color: barWidth > 90 ? barColor : "#ffffff",
    fontWeight: "bold",
    fontSize: "12px",
    marginRight: "16px",
    display: "table",
    width: `${barWidth}px`,
  };

  return (
    <div data-testid = "auctionTimer-testid">
      <div
        className={enableStatus ? "checkProgress" : "text-option"}
        style={containerStyles}
        ref={containerRef}
      >
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${
            timerHours + ":" + timerMinutes + ":" + timerSeconds
          }`}</span>

          <span className="auction-timer-availablecar-clock-icon">
            <ClockIcon
              clockRef={clockRef}
              barColor={barColor}
              labelStyles={labelStyles}
            />
          </span>
        </div>
      </div>
    </div>
  );
}
