import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./dashbord-mybids.css";
// import { Table } from "react-bootstrap";
import moment from "moment";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
 //import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import localization from "moment/locale/nl";
import NorunningbidsIcon from "../Icons/AuctionDashboard/norunningbidsIcon";
function DashboardMybids() {
  const getToken = localStorage.getItem("Token");
  const getUserid = localStorage.getItem("userid");
  const [runningbids, setRunningbids] = useState([]);
  const [myBidStatus, setMyBidStatus] = useState([]);
  
  useEffect(() => {
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    const hostURL = process.env.REACT_APP_SERVER_URL;
    fetch(
      hostURL +
        `/api/AuctionDashboard/AuctionStatistics/${JSON.parse(getUserid)}`,
      requestGetOptions
    )
      .then((response) => {
        let dataJson = response.json();
        setMyBidStatus(response.status);
        return dataJson;
      })
      .then((data) => {

        const filterArrayViewAll =data.responsePegaFilterByStatus;
    
        filterArrayViewAll.sort(function (a, b) {
          var dateA = new Date(a.bidInfoPage.auctionEndDtTime),
            dateB = new Date(b.bidInfoPage.auctionEndDtTime);
          return dateA - dateB;
        });
        let filterArray =
        filterArrayViewAll.length > 5
            ? filterArrayViewAll.slice(0, 5)
            : filterArrayViewAll;
            
        setRunningbids(filterArray);
     
      }).catch((error)=> console.error(error.message));
  }, [getToken, getUserid]);
 return (
    <div className="auction-container-mybids" data-testid="dashbord-mybids">
      {myBidStatus === 401 || myBidStatus === 500 || !runningbids.length ? (
     <div className="no-running-bids-parent-container">
          <div className="no-running-bids-container">
            <NorunningbidsIcon />
          </div>
          <span className="no-running-bids-container-span">
          U heeft geen lopende biedingen!
          </span>
        </div>
      ) : (
        <Table className="auction-card-dashboard-table">
          <Thead>
            {/* <tr style={{ fontSize: '13px', fontWeight:'500',lineHeight:'15px', fontFamily:''}}> */}
            <Tr className="auction-card-dashboard-table-title">
              <Th>
                <span className="auction-dashboard-header-values">
                  Kenteken
                </span>
              </Th>
              <Th>
                <span className="auction-dashboard-header-values">
                  Voertuig
                </span>
              </Th>
              <Th>
                <span className="auction-dashboard-header-values">Status</span>
              </Th>
              <Th>
                <span className="auction-dashboard-header-values">
                  Einddatum en -tijd
                </span>
              </Th>
              <Th>
                <span className="auction-dashboard-header-values">Bedrag</span>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {runningbids.map((rowApi, key) => {
               
              let oneLicencePlate =
                rowApi.pxPages.vehicle.licenceplate !== null
                  ? rowApi.pxPages.vehicle.licenceplate
                  : null;

              moment.locale("nl", localization);
              const enddateFormat = moment(
                rowApi.bidInfoPage.auctionEndDtTime
              ).format("ll");
              const endhoursFormat = moment(
                rowApi.bidInfoPage.auctionEndDtTime
              ).hours();
              const endminutesFormat = moment(
                rowApi.bidInfoPage.auctionEndDtTime
              ).minutes();

              function financial(x) {
                return Number.parseFloat(x).toFixed(3);
              }
              const bidAmount = financial(rowApi?.bidInfoPage?.bidAmount);
              const formatter = new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
              });
              
              return (
                <Tr className="auction-dashboard-table">
                  <Td>
                  
                    <Link to={`/MyBids`}>
                      <span className="auction-dashboard-header-values">
                        {oneLicencePlate?oneLicencePlate:"-"}
                      </span>
                    </Link>
                  </Td>
                  <Td>
                  
                    <Link to={`/MyBids`}>
                      <span className="auction-dashboard-header-values">
                        {(rowApi.pxPages.vehicle.brand && rowApi.pxPages.vehicle.model)?rowApi.pxPages.vehicle.brand+" "+rowApi.pxPages.vehicle.model:"-"}
                      </span>
                    </Link>
                  </Td>
                  <Td className="auction-dashboard-header-values">
                    
                    <Link to={`/MyBids`}>
                      <span className="dashboard-running-status-option">
                      Bieding geplaatst 
                      </span>
                    </Link>
                  </Td>
                  <Td className="auction-dashboard-header-values">
                  
                    <Link to={`/MyBids`}>
                      <span className="date-format">
                        <span className="auction-dashboard-header-values">
                          {enddateFormat}- {endhoursFormat}u{endminutesFormat}
                        </span>
                        <span></span>
                      </span>
                    </Link>
                  </Td>
                  <Td>
                   
                    <Link to={`/MyBids`}>
                      <span className="auction-dashboard-header-values">
                        
                        {formatter.format(bidAmount)}
                      </span>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </div>
  );
}

export default DashboardMybids;
