import React from "react"
import './mybids.css';

import Titleheader from "../atoms/TitleHeader/titleheader"
import DataTables from "../components/Mybidtable/mybids-table"
import MybidsArchival from "../components/Mybidtable/mybids-archival";
import MybidsTotalValue from "../components/Mybidtable/mybids-totalValue";

function MyBids() {
    return (
      <div className="wrapper" data-testid = "MyBids-container">
      <div className="mybids-container">
        <section>
          <Titleheader title="Mijn Biedingen" titleheader="mybids-container"/>
        </section>
       <section>
       <DataTables className="mybids-container-holder"/>

       </section>
      
         <section>
         <div className="mybid-archival-total-value">
         <MybidsArchival/>
         <div className="totalValue-container">
         <MybidsTotalValue />
       </div>
       </div>
         </section>
      </div>
      </div>
);
}

export default MyBids;
