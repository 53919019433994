import React from 'react';

function CarTransmissionIcon(props) {
    return (
        <div>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66667 4C5.19391 4 4 5.19391 4 6.66667L4 6.66662C4.00074 7.61857 4.50888 8.49793 5.33329 8.97393L5.33333 14.4427C5.30953 14.5867 5.30953 14.7336 5.33333 14.8776V20.362L5.33333 20.362C4.50972 20.8375 4.00168 21.7156 4 22.6667H4C4 24.1394 5.19391 25.3333 6.66667 25.3333H6.66667C8.13943 25.3333 9.33333 24.1394 9.33333 22.6667V22.6667C9.3326 21.7148 8.82445 20.8354 8.00004 20.3594L8 16H14.6667V20.3594C13.8422 20.8353 13.3341 21.7147 13.3333 22.6667C13.3333 24.1394 14.5272 25.3333 16 25.3333H16C17.4728 25.3333 18.6667 24.1394 18.6667 22.6667V22.6667C18.665 21.7157 18.157 20.8375 17.3334 20.362L17.3333 16H24V23.0286C23.1764 23.5042 22.6683 24.3823 22.6667 25.3333C22.6667 26.8061 23.8606 28 25.3333 28C26.8061 28 28 26.8061 28 25.3333V25.3334C27.9993 24.3814 27.4911 23.5021 26.6667 23.0261L26.6667 14.8906V14.8906C26.6905 14.7466 26.6905 14.5997 26.6667 14.4557V8.97136V8.97136C27.4903 8.49585 27.9983 7.61769 28 6.66667V6.66667C28 5.19391 26.8061 4 25.3333 4C23.8606 4 22.6667 5.19391 22.6667 6.66667V6.66667V6.66662C22.6674 7.61857 23.1755 8.49794 24 8.97394L24 13.3333H17.3333V8.97136L17.3333 8.97136C18.1569 8.49585 18.665 7.6177 18.6667 6.66667V6.66667C18.6667 5.19391 17.4728 4.00001 16 4.00001C14.5272 4.00001 13.3333 5.19391 13.3333 6.66667V6.66667V6.66662C13.3341 7.61858 13.8422 8.49794 14.6666 8.97394L14.6667 13.3333H8V8.97137C8.82361 8.49586 9.33165 7.6177 9.33333 6.66667V6.66667C9.33333 5.19391 8.13943 4.00001 6.66667 4.00001L6.66667 4Z" fill="#C6CBD4"/>
</svg>

        </div>
    );
}

export default CarTransmissionIcon;