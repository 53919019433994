import React from "react";

function UpcomingAuctionsIcon3() {
  return (
    <div>
      <svg
        width="54"
        height="55"
        viewBox="0 0 54 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="26.8305" cy="27.5" rx="26.8305" ry="27" fill="#27AD7A" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.5257 18.75H33.0413V16.25H20.6198V18.75H18.1355C16.7691 18.75 15.6512 19.875 15.6512 21.25V22.5C15.6512 25.6875 18.0361 28.2875 21.1042 28.675C21.8868 30.55 23.5637 31.9625 25.5884 32.375V36.25H20.6198V38.75H33.0413V36.25H28.0727V32.375C30.0974 31.9625 31.7744 30.55 32.5569 28.675C35.625 28.2875 38.01 25.6875 38.01 22.5V21.25C38.01 19.875 36.892 18.75 35.5257 18.75ZM35.5257 22.5C35.5257 24.125 34.4823 25.5 33.0414 26.025V21.25H35.5257V22.5ZM18.1355 22.5V21.25H20.6198V26.025C19.1789 25.5 18.1355 24.125 18.1355 22.5ZM26.8306 30C24.781 30 23.1041 28.3125 23.1041 26.25V18.75H30.557V26.25C30.557 28.3125 28.8801 30 26.8306 30Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default UpcomingAuctionsIcon3;
