import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckedIcon from "../../Icons/checkedIcon";
import CheckboxIcon from "../../Icons/checkboxIcon";
import "./FilterCheckboxComponent.css";
import CheckallIcon from "../../Icons/checkallIcon";
import UncheckallIcon from "../../Icons/uncheckallIcon";
import { filterDutchWords } from "./filterDutchWords";

function FilterCheckboxComponent(props) {
  const {
    heading,
    getCheckedValues,
    nodes,
    selectedValues = [],
    showSelectAllIcon,
    isFilterApplied
  } = props;

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [translatedNode, setTranslatedNode] = useState(nodes);

  const getNodeIds = useCallback((translatedNode) => {
    let ids = [];
    translatedNode?.forEach(({ value, children }) => {
      ids = [...ids, value, ...getNodeIds(children)];
    });
    return ids;
  }, []);

  const translateLabel = (nodes) => {
    let translatedNode = nodes.map((node) => {
      let translatedLabel = filterDutchWords[node.label];
      if (translatedLabel) {
        node.label = translatedLabel;
      }
      return node;
    });
    setTranslatedNode(translatedNode);
    return translatedNode;
  };

  const getAllValuesFromArray = useCallback((array) => {
    var result = [];
    array.forEach(function iter(o) {
      if (o.children) {
        o.children.forEach(iter);
        return;
      }
      result.push(o.value);
    });
    return result;
  }, []);

  const checkAllValuesSelected = useCallback(
    (checkedValues) => {
      const allValueList = getAllValuesFromArray(translatedNode);
      return (_.isEqual(allValueList.sort(), checkedValues.sort())) || (allValueList.every(r=> checkedValues.includes(r)));
    },
    [getAllValuesFromArray, translatedNode]
  );

  useEffect(() => {
    // This is for expand default child checkboxes
    let translatedNodes = translateLabel(nodes);
    setExpanded(getNodeIds(translatedNodes));
    if (!_.isEmpty(selectedValues)) {
      setChecked(selectedValues);
      getCheckedValues(selectedValues);
    }
    const isAllChecked = checkAllValuesSelected(checked);
    setCheckedAll(isAllChecked);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  useEffect(() => {
     if (!isFilterApplied) {
      setChecked(selectedValues);
      getCheckedValues(selectedValues);      
    }
    const isAllChecked = checkAllValuesSelected(checked);
    setCheckedAll(isAllChecked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAllValuesSelected, getCheckedValues, isFilterApplied, selectedValues]);

  

  const onCheck = useCallback(
    (checked) => {
      // console.log(checked);
      setChecked(checked);
      const isAllChecked = checkAllValuesSelected(checked);
      setCheckedAll(isAllChecked);
      getCheckedValues(checked);
    },
    [checkAllValuesSelected, getCheckedValues]
  );

  const onExpand = useCallback((expanded) => {
    // console.log(expanded);

    setExpanded(expanded);
  }, []);

  const handleSelectAll = useCallback(() => {
    let selectedValue = [];
    if (!checkedAll) {
      selectedValue = getAllValuesFromArray(translatedNode);
    }
    setChecked(selectedValue);
    getCheckedValues(selectedValue);
    setCheckedAll(!checkedAll);
  }, [checkedAll, getAllValuesFromArray, getCheckedValues, translatedNode]);

  const icons = {
    check: <CheckedIcon />,
    uncheck: <CheckboxIcon />,
    halfCheck: <CheckboxIcon />,
  };

  return (
    <div className="checkbox-container" data-testid = "filter-checkbox-component-testid">
     
      <div className="checkbox-header">
        <span className="checkbox-header-selectall">
        {showSelectAllIcon ? (
          <div onClick={() => handleSelectAll()}>
            {checkedAll ? <CheckallIcon /> : <UncheckallIcon />}
          </div>
        ) : null}
        </span>
        <span className="checkbox-heading">{heading}</span>
     
      </div>
      <CheckboxTree
        nodes={translatedNode}
        checked={checked}
        expanded={expanded}
        onCheck={(checked) => onCheck(checked)}
        onExpand={(expanded) => onExpand(expanded)}
        showNodeIcon={false}
        icons={icons}
      />
    </div>
  );
}

export default FilterCheckboxComponent;
