import React from "react";
import { Collapse } from "antd";
import MybidArchiveTable from "./mybid-archive-table";
import "./mybids-table.css";

const { Panel } = Collapse;

function callback() {}

function MybidsArchival() {
  return (
    <div data-testid = "mybids-archival-parent-container">
    <Collapse defaultActiveKey={["1"]} onChange={callback} >
      <Panel header="Archief" key="1" >
        <MybidArchiveTable />
      </Panel>
    </Collapse>
    </div>
  );
}

export default MybidsArchival;
