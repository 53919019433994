import React from "react";
import { Link } from "react-router-dom";
import "./userorganizationComponent.css";
import Oval from ".././../images/Oval.png";
import DriveEtaRoundedIcon from "@material-ui/icons/DriveEtaRounded";
import MybidIcon from "../Icons/mybidIcon";
import Avatar from "@material-ui/core/Avatar";
import Mask from "../../images/Mask.png";


function UserorganizationComponent(userorganization) {
  
  return (
   <>
   
    <div className="user-organization-wrapper" data-testid = "user-org-component">
   
      <div className="user-organization-container">
        <div className="user-organization-information">
          <div className="user-organization-personal-detail">
            <div className="user-organization-photo">
              <img
                src={Oval}
                alt="oval"
                style={{
                  borderRadius: "16px",
                  width: "130px",
                  height: "130px",
                  margin: "18px 16px",
                }}
              />
            </div>
            <div className="user-organization-address">
              <span className="user-organization-title">Verkenner</span>
              <span className="user-organization-subtitle">KvK-{userorganization?.userorganization?.pxResults[0]?.coCNumber}</span>
              <span className="user-organization-website"><Link to={userorganization?.userorganization?.pxResults[0]?.organizationURL}>{userorganization?.userorganization?.pxResults[0]?.organizationURL.replace(/(^\w+:|^)\/\//, '')}</Link></span>
            </div>
          </div>
          <div className="user-organization-personal-account">
            <div className="user-organization-account-registration">
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">
                    Bovemij klant-ID
                  </span>
                  <span className="user-account-detail-subtitle">{userorganization?.userorganization?.pxResults[0].sysAdminCustomerID}</span>
                </div>
              </div>
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">RDW nr.</span>
                  <span className="user-account-detail-subtitle">{userorganization?.userorganization?.pxResults[0].rdwNumber}</span>
                </div>
              </div>
            </div>
            <div className="user-organization-account-registration">
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">Btw nr.</span>
                  <span className="user-account-detail-subtitle">
                  {userorganization?.userorganization?.pxResults[0].vatNumber}
                  </span>
                </div>
              </div>
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">IBAN-nr.</span>
                  <span className="user-account-detail-subtitle">
                  {userorganization?.userorganization?.pxResults[0].ibanNumber}
                  </span>
                </div>
              </div>
            </div>
            <div className="user-organization-account-registration">
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">
                    {/* E-mailadres (bestellingen) */}
                    E-mailadres
                  </span>
                  <span className="user-account-detail-subtitle">
                    {/* john@autoscout24.nl */}
                    {userorganization?.userorganization?.pxResults[0].companyEmailAddress}
                  </span>
                </div>
              </div>
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">
                    E-mailadres (facturen)
                  </span>
                  <span className="user-account-detail-subtitle">
                  {userorganization?.userorganization?.pxResults[0].pxPages.adm.email}
                  </span>
                </div>
              </div>
            </div>
            <div className="user-organization-account-registration">
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">Adres</span>
                  <span className="user-account-detail-subtitle">
                    {/* Gustav Mahlerplein 113-115 */}
                    {userorganization?.userorganization?.pxResults[0].pxPages.add.street}  {userorganization?.userorganization?.pxResults[0].pxPages.add.houseNumber}
                  </span>
                </div>
              </div>
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">Postcode</span>
                  <span className="user-account-detail-subtitle"> {userorganization?.userorganization?.pxResults[0].pxPages.add.zipCode}</span>
                </div>
              </div>
            </div>
            <div className="user-organization-account-registration">
              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">stad</span>
                  <span className="user-account-detail-subtitle">
                    {/* Amsterdam */}
                    {userorganization?.userorganization?.pxResults[0].pxPages.add.city}
                  </span>
                </div>
              </div>

              <div className="user-account-registration">
                
                <div className="user-account-detail">
                  <span className="user-account-detail-title">land</span>
                  <span className="user-account-detail-subtitle">
                    {/* Nederland */}
                    {userorganization?.userorganization?.pxResults[0].pxPages.add.country}
                  </span>
                </div>
              </div>
            </div>
            <div className="user-organization-account-registration"></div>
          </div>
        </div>
        {/* <div className="user-organization-subtitle-address">
          <div className="user-organization-address-heading">
            <span className="user-organization-title">Gebruikers</span>
            <span className="user-organization-address-heading-subtitle">
              Bekijk alle gebruikers
            </span>
          </div>
          <div className="user-organization-address-employee-detail-Gebruikers">
            <div className="user-organization-address-employee-detail-holder">
              <span className="user-organization-address-employee-detail-avatar">
              <Avatar alt="" src="" />
              </span>
              <span className="user-organization-address-employee-detail-description">
                <span className="user-employee-detail-title">
                  Johan Doe <span className="sub-title">(You)</span>
                </span>
                <span className="user-employee-detail-subtitle">
                  Overamstel, Amsterdam
                </span>
              </span>
            </div>

            <div className="user-organization-address-employee-detail-holder">
              <span className="user-organization-address-employee-detail-avatar">
                <Avatar alt="Remy Sharp" src={Mask} />
              </span>
              <span className="user-organization-address-employee-detail-description">
                <span className="user-employee-detail-title">
                  Johan Doe <span className="sub-title">(You)</span>
                </span>
                <span className="user-employee-detail-subtitle">
                  Overamstel, Amsterdam
                </span>
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
</>
  );
}

export default UserorganizationComponent;
