import React from "react";

export default function minutes({ hours, minutes }) {
  const minutesSplit = minutes.split("");

  if (minutesSplit[1] == null) {
    minutesSplit[1] = minutesSplit[0];
    minutesSplit[0] = 0;
  }

  const minutesTensProps = minutesSplit && minutesSplit[0];
  const minutesUnitsProps = minutesSplit && minutesSplit[1];

  return (
    <>
      <div className="minutes-section" data-testid = "minutes-section">
        <div className="minutes-container">
          <div
            className={
              hours >= 2 && hours >= 0 ? "hours-card-48" : "hours-card-2"
            }
          >
            <span className="minutes-tens-block">
              {minutesTensProps >= 0 ? minutesTensProps : "0"}
            </span>
          </div>

          <div
            className={
              hours >= 2 && hours >= 0 ? "hours-card-48" : "hours-card-2"
            }
          >
            <span className="minutes-units-block">
              {minutesUnitsProps >= 0 ? minutesUnitsProps : "0"}
            </span>
          </div>
        </div>
        <span className="minutes-title">Min</span>
      </div>
    </>
  );
}
