import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

function PrivateRoute({ component: Component, ...props }) {
  const url = window.location.href;
  if(window.location.pathname === "/landingpage" && url.indexOf('?') > -1 && url.indexOf('?') > -1) {
  let qParams = {};
 
  const getQueryParams = (url) => {
    let anchor = document.createElement("a");

    anchor.href = url;

    let qStrings = anchor.search.substring(1);
    let params = qStrings.split("?");

    let arrayparam = params[0].split("query=");

    let arrayParamUrl = arrayparam[1];
    localStorage.setItem('url', arrayParamUrl);
    let decodedUrl = Buffer.from(arrayParamUrl, "base64").toString("ascii");
    let decodedUrlArray = decodedUrl.split("&");

    for (let i = 0; i < decodedUrlArray.length; i++) {
      let decodeUrlObject = decodedUrlArray[i].split("=");
      qParams[decodeUrlObject[0]] = decodeUrlObject[1];
    }
    return qParams;
  };
  const getQueryParamsUrlData = getQueryParams(url);
  
  const authLocalstorage = localStorage.setItem(
    "Token",
    JSON.stringify(qParams.accesstoken)
  );
  const useridLocalstorage = localStorage.setItem(
    "userid",
    JSON.stringify(qParams.username)
  );
  const givenNameLocalstorage = localStorage.setItem(
    "given_name",
    JSON.stringify(qParams.given_name)
  );
  const familyNameLocalstorage = localStorage.setItem(
    "family_name",
    JSON.stringify(qParams.family_name)
  );
  const phonenumberLocalstorage = localStorage.setItem(
    "phone_number",
    JSON.stringify(qParams.phone_number)
  );
  const emailLocalstorage = localStorage.setItem(
    "email",
    JSON.stringify(qParams.email)
  );

  const companyIdLocalstorage = localStorage.setItem(
    "companyId",
    JSON.stringify(qParams.companyId)
  );
  // const existUserLocalstorage = localStorage.setItem(
  //   "existUser",
  //   JSON.stringify(qParams.PegaUserState)
  // );
  const getAuthLocalstorage = localStorage.getItem("Token");
  localStorage.setItem("UserRole", JSON.stringify(qParams.UserRole));
  }

  return (
    <Route
      {...props}
      render={(innerProps) =>
        localStorage.getItem("Token") ? (
          // isUrlAuthentication !== '401' ?  (
          <Component {...innerProps} />
        ) : (
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/landingpage" />;
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;
