import React from "react";

function CheckboxIcon({ checkboxRef }) {
  return (
    <div className="checkbox-icon">
      <svg
        width="14"
        height="14"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="14" height="14" fill="#2A69B2" />
      </svg>
    </div>
  );
}

export default CheckboxIcon;
