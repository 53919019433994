import React from "react";

function NorunningbidsIcon() {
  return (
    <div>
      <svg
        width="158"
        height="156"
        viewBox="0 0 158 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M104.218 41.3302H78.2845C77.7168 41.332 77.1561 41.2052 76.6452 40.9595C76.1343 40.7139 75.6866 40.3558 75.3362 39.9127C74.9794 39.4653 74.7302 38.9431 74.6075 38.3858C74.4849 37.8285 74.492 37.2508 74.6284 36.6966C77.0185 26.7645 77.0184 16.4137 74.6282 6.48165C74.492 5.92749 74.4849 5.3498 74.6076 4.79252C74.7302 4.23525 74.9794 3.71307 75.3361 3.26573C75.6865 2.82255 76.1343 2.46451 76.6452 2.21886C77.1561 1.97321 77.7167 1.84643 78.2845 1.84816H104.218C105.113 1.85071 105.978 2.1685 106.66 2.74491C107.341 3.32133 107.794 4.11895 107.938 4.99571C109.644 15.9932 109.644 27.1852 107.938 38.1827C107.794 39.0595 107.341 39.8571 106.66 40.4335C105.978 41.0099 105.113 41.3277 104.218 41.3302Z"
          fill="#E6E6E6"
        />
        <path
          d="M78.2844 3.78419C78.0118 3.78328 77.7425 3.84408 77.4971 3.96196C77.2517 4.07984 77.0367 4.2517 76.8683 4.46446C76.6949 4.68245 76.5739 4.9368 76.5146 5.20816C76.4552 5.47952 76.459 5.76074 76.5256 6.03043C78.9872 16.2593 78.9873 26.9194 76.5258 37.1483C76.4591 37.4179 76.4552 37.699 76.5146 37.9704C76.5739 38.2417 76.6949 38.496 76.8682 38.7139L76.8684 38.7141C77.0367 38.9269 77.2517 39.0988 77.4971 39.2167C77.7425 39.3346 78.0118 39.3954 78.2844 39.3945H104.217C104.647 39.3946 105.064 39.2434 105.392 38.9676C105.72 38.6919 105.939 38.3095 106.009 37.8887C107.685 27.0861 107.685 16.0926 106.009 5.28998C105.939 4.86915 105.72 4.48683 105.392 4.21107C105.064 3.93532 104.647 3.78404 104.217 3.78418L78.2844 3.78419Z"
          fill="white"
        />
        <path
          d="M98.5588 13.0925H84.9502C84.4329 13.0919 83.9369 12.8878 83.5711 12.5249C83.2053 12.1619 82.9996 11.6699 82.999 11.1567V10.4002C82.9996 9.88693 83.2053 9.39488 83.5711 9.03196C83.9369 8.66905 84.4329 8.46492 84.9502 8.46436H98.5588C99.0761 8.46492 99.5721 8.66905 99.9379 9.03196C100.304 9.39488 100.509 9.88693 100.51 10.4002V11.1567C100.509 11.6699 100.304 12.162 99.9379 12.5249C99.5721 12.8878 99.0761 13.0919 98.5588 13.0925Z"
          fill="#E6E6E6"
        />
        <path
          d="M98.5588 22.651H84.9502C84.4329 22.6505 83.9369 22.4464 83.5711 22.0834C83.2053 21.7205 82.9996 21.2285 82.999 20.7152V19.9588C82.9996 19.4455 83.2053 18.9535 83.5711 18.5905C83.9369 18.2276 84.4329 18.0235 84.9502 18.0229H98.5588C99.0761 18.0235 99.5721 18.2276 99.9379 18.5905C100.304 18.9535 100.509 19.4455 100.51 19.9588V20.7152C100.509 21.2285 100.304 21.7205 99.9379 22.0834C99.5721 22.4464 99.0761 22.6505 98.5588 22.651Z"
          fill="#E6E6E6"
        />
        <path
          d="M98.5588 32.2101H84.9502C84.4329 32.2096 83.9369 32.0054 83.5711 31.6425C83.2053 31.2796 82.9996 30.7876 82.999 30.2743V29.5178C82.9996 29.0046 83.2053 28.5125 83.5711 28.1496C83.9369 27.7867 84.4329 27.5826 84.9502 27.582H98.5588C99.0761 27.5826 99.5721 27.7867 99.9379 28.1496C100.304 28.5125 100.509 29.0046 100.51 29.5178V30.2743C100.509 30.7876 100.304 31.2796 99.9379 31.6425C99.5721 32.0054 99.0761 32.2096 98.5588 32.2101Z"
          fill="#E6E6E6"
        />
        <path
          d="M153 65.8517H127.067C126.499 65.8535 125.938 65.7267 125.427 65.481C124.917 65.2354 124.469 64.8773 124.118 64.4342C123.762 63.9868 123.512 63.4646 123.39 62.9073C123.267 62.35 123.274 61.7723 123.411 61.2181C125.801 51.286 125.801 40.9352 123.41 31.0031C123.274 30.449 123.267 29.8713 123.39 29.314C123.512 28.7567 123.762 28.2346 124.118 27.7872C124.469 27.344 124.916 26.986 125.427 26.7403C125.938 26.4947 126.499 26.3679 127.067 26.3696H153C153.895 26.3722 154.761 26.69 155.442 27.2664C156.123 27.8428 156.576 28.6404 156.72 29.5172C158.427 40.5147 158.427 51.7067 156.72 62.7042C156.576 63.5809 156.123 64.3786 155.442 64.955C154.761 65.5314 153.895 65.8492 153 65.8517Z"
          fill="#E6E6E6"
        />
        <path
          d="M127.067 28.3057C126.794 28.3048 126.525 28.3656 126.279 28.4834C126.034 28.6013 125.819 28.7732 125.651 28.9859C125.477 29.2039 125.356 29.4583 125.297 29.7296C125.237 30.001 125.241 30.2822 125.308 30.5519C127.769 40.7808 127.77 51.4409 125.308 61.6697C125.241 61.9394 125.237 62.2205 125.297 62.4918C125.356 62.7632 125.477 63.0175 125.65 63.2354L125.651 63.2355C125.819 63.4484 126.034 63.6203 126.279 63.7382C126.525 63.8561 126.794 63.9169 127.067 63.916H153C153.43 63.9161 153.846 63.7649 154.174 63.4891C154.502 63.2133 154.721 62.831 154.791 62.4102C156.468 51.6076 156.468 40.6141 154.791 29.8115C154.721 29.3906 154.502 29.0083 154.174 28.7326C153.846 28.4568 153.43 28.3055 153 28.3057L127.067 28.3057Z"
          fill="white"
        />
        <path
          d="M101.29 93.7608H75.3573C74.765 93.7626 74.1801 93.6303 73.647 93.374C73.114 93.1177 72.647 92.7441 72.2814 92.2818C71.9093 91.8153 71.6494 91.2708 71.5215 90.6897C71.3936 90.1085 71.401 89.5061 71.5431 88.9282C73.9272 79.0208 73.9272 68.6959 71.543 58.7885C71.4009 58.2107 71.3935 57.6083 71.5215 57.0272C71.6494 56.446 71.9093 55.9015 72.2813 55.4351C72.6469 54.9727 73.114 54.5992 73.647 54.3429C74.18 54.0866 74.765 53.9543 75.3573 53.9561H101.29C102.224 53.9588 103.127 54.2905 103.838 54.8919C104.549 55.4934 105.021 56.3256 105.171 57.2404C106.88 68.2542 106.88 79.4627 105.171 90.4765C105.021 91.3913 104.549 92.2235 103.838 92.8249C103.127 93.4264 102.224 93.758 101.29 93.7608Z"
          fill="#F2F2F2"
        />
        <path
          d="M147.341 42.4538H133.732C133.215 42.4532 132.719 42.2491 132.353 41.8862C131.988 41.5233 131.782 41.0312 131.781 40.518V39.7615C131.782 39.2483 131.988 38.7562 132.353 38.3933C132.719 38.0304 133.215 37.8262 133.732 37.8257H147.341C147.858 37.8262 148.354 38.0304 148.72 38.3933C149.086 38.7562 149.292 39.2483 149.292 39.7615V40.518C149.292 41.0312 149.086 41.5233 148.72 41.8862C148.354 42.2491 147.858 42.4532 147.341 42.4538Z"
          fill="#E6E6E6"
        />
        <path
          d="M147.341 52.0124H133.732C133.215 52.0118 132.719 51.8077 132.353 51.4448C131.988 51.0819 131.782 50.5898 131.781 50.0766V49.3201C131.782 48.8068 131.988 48.3148 132.353 47.9519C132.719 47.589 133.215 47.3848 133.732 47.3843H147.341C147.858 47.3848 148.354 47.589 148.72 47.9519C149.086 48.3148 149.292 48.8068 149.292 49.3201V50.0766C149.292 50.5898 149.086 51.0819 148.72 51.4448C148.354 51.8077 147.858 52.0118 147.341 52.0124Z"
          fill="#E6E6E6"
        />
        <path
          d="M88.3969 81.146C92.528 81.146 95.8768 77.8235 95.8768 73.7251C95.8768 69.6266 92.528 66.3042 88.3969 66.3042C84.2659 66.3042 80.917 69.6266 80.917 73.7251C80.917 77.8235 84.2659 81.146 88.3969 81.146Z"
          fill="white"
        />
        <path
          d="M91.9741 72.7571H89.3724V70.1759C89.3724 69.9192 89.2696 69.673 89.0866 69.4915C88.9037 69.31 88.6555 69.208 88.3967 69.208C88.138 69.208 87.8898 69.31 87.7069 69.4915C87.5239 69.673 87.4211 69.9192 87.4211 70.1759V72.7571H84.8194C84.5606 72.7571 84.3125 72.8591 84.1295 73.0406C83.9465 73.2222 83.8438 73.4684 83.8438 73.7251C83.8438 73.9818 83.9465 74.228 84.1295 74.4095C84.3125 74.591 84.5606 74.693 84.8194 74.693H87.4211V77.2742C87.4211 77.5309 87.5239 77.7771 87.7069 77.9586C87.8898 78.1401 88.138 78.2421 88.3967 78.2421C88.6555 78.2421 88.9037 78.1401 89.0866 77.9586C89.2696 77.7771 89.3724 77.5309 89.3724 77.2742V74.693H91.9741C92.2329 74.693 92.481 74.591 92.664 74.4095C92.847 74.228 92.9497 73.9818 92.9497 73.7251C92.9497 73.4684 92.847 73.2222 92.664 73.0406C92.481 72.8591 92.2329 72.7571 91.9741 72.7571Z"
          fill="#E6E6E6"
        />
        <path
          d="M97.8285 5.485H84.1695C83.7815 5.48457 83.4095 5.33146 83.1352 5.05927C82.8608 4.78708 82.7065 4.41803 82.7061 4.03309V1.45191C82.7065 1.06697 82.8608 0.697927 83.1352 0.425734C83.4095 0.153542 83.7815 0.000433818 84.1695 0H97.8285C98.2165 0.000433818 98.5885 0.153542 98.8628 0.425734C99.1372 0.697927 99.2915 1.06697 99.292 1.45191V4.03309C99.2915 4.41803 99.1372 4.78708 98.8628 5.05927C98.5885 5.33146 98.2165 5.48457 97.8285 5.485Z"
          fill="#CCCCCC"
        />
        <path
          d="M147.261 30.3288H133.602C133.214 30.3283 132.842 30.1752 132.568 29.903C132.293 29.6308 132.139 29.2618 132.139 28.8768V26.2957C132.139 25.9107 132.293 25.5417 132.568 25.2695C132.842 24.9973 133.214 24.8442 133.602 24.8438H147.261C147.649 24.8442 148.021 24.9973 148.295 25.2695C148.57 25.5417 148.724 25.9107 148.725 26.2957V28.8768C148.724 29.2618 148.57 29.6308 148.295 29.903C148.021 30.1752 147.649 30.3283 147.261 30.3288Z"
          fill="#CCCCCC"
        />
        <path
          d="M28.8132 151.986H32.8003L34.0468 136.729H28.8125L28.8132 151.986Z"
          fill="#FFB8B8"
        />
        <path
          d="M27.7959 150.695L35.6478 150.695H35.6481C36.3052 150.695 36.9559 150.823 37.563 151.073C38.1701 151.322 38.7218 151.688 39.1864 152.149C39.6511 152.61 40.0197 153.157 40.2711 153.759C40.5226 154.362 40.652 155.007 40.652 155.659V155.82L27.7961 155.821L27.7959 150.695Z"
          fill="#2F2E41"
        />
        <path
          d="M7.34831 151.986H11.3354L13.2324 136.729H7.34766L7.34831 151.986Z"
          fill="#FFB8B8"
        />
        <path
          d="M6.33203 150.695L14.1839 150.695H14.1843C14.8414 150.695 15.4921 150.823 16.0992 151.073C16.7063 151.322 17.2579 151.688 17.7226 152.149C18.1872 152.61 18.5558 153.157 18.8073 153.759C19.0587 154.362 19.1882 155.007 19.1882 155.659V155.82L6.33228 155.821L6.33203 150.695Z"
          fill="#2F2E41"
        />
        <path
          d="M8.056 147.166C7.64929 147.166 7.25748 147.015 6.95841 146.741C6.79786 146.596 6.66856 146.42 6.57827 146.224C6.48797 146.028 6.43855 145.815 6.43295 145.6L4.83398 88.7451L27.5376 94.376L34.5425 115.225C35.4458 117.907 35.8369 120.733 35.6955 123.557L34.8257 143.766C34.8059 144.18 34.6261 144.571 34.3236 144.857C34.0211 145.143 33.6193 145.302 33.2015 145.302H29.3936C29.0005 145.3 28.6212 145.158 28.3253 144.902C28.0293 144.645 27.8365 144.291 27.7822 143.905L25.0605 121.597C24.8227 119.856 24.209 118.187 23.2615 116.704L17.9054 108.29C17.8696 108.234 17.8171 108.19 17.7552 108.165C17.6934 108.139 17.625 108.134 17.5596 108.148C17.4942 108.162 17.4349 108.197 17.3899 108.246C17.3448 108.295 17.3161 108.357 17.3078 108.423L13.1045 145.556C13.0594 145.932 12.8818 146.281 12.6029 146.541C12.3241 146.8 11.962 146.954 11.5802 146.974L8.14857 147.164C8.1177 147.165 8.08689 147.166 8.056 147.166Z"
          fill="#2F2E41"
        />
        <path
          d="M23.7591 51.936C28.1705 51.936 31.7467 48.3881 31.7467 44.0115C31.7467 39.6349 28.1705 36.0869 23.7591 36.0869C19.3477 36.0869 15.7715 39.6349 15.7715 44.0115C15.7715 48.3881 19.3477 51.936 23.7591 51.936Z"
          fill="#FFB8B8"
        />
        <path
          d="M27.4282 96.6887C27.2908 96.6885 27.1539 96.6712 27.0208 96.6373H27.0205L4.49479 90.9072C4.09205 90.8049 3.74413 90.5534 3.52257 90.2044C3.30101 89.8554 3.22263 89.4354 3.30357 89.0309L8.4768 61.3829C8.7991 59.7847 9.50755 58.2881 10.5413 57.0214C11.5751 55.7547 12.9033 54.7558 14.4119 54.1106C15.8818 53.4651 17.4893 53.1886 19.0924 53.3054C20.6956 53.4221 22.2451 53.9286 23.6043 54.7801C23.9196 54.9753 24.2373 55.1882 24.5482 55.4128C25.7999 56.3289 26.8207 57.5202 27.5305 58.8934C28.2402 60.2665 28.6197 61.7841 28.639 63.3271L29.0504 95.0526C29.0532 95.266 29.0133 95.4778 28.9331 95.6758C28.8529 95.8737 28.7339 96.054 28.5831 96.2061C28.4323 96.3583 28.2525 96.4792 28.0543 96.562C27.8561 96.6449 27.6433 96.6879 27.4282 96.6887Z"
          fill="#27AD7A"
        />
        <path
          d="M6.81248 97.0137C6.54377 96.6213 6.36503 96.1753 6.28885 95.7071C6.21267 95.2388 6.24091 94.7597 6.37157 94.3034C6.50223 93.8472 6.73214 93.4249 7.0451 93.0663C7.35805 92.7077 7.74646 92.4216 8.18294 92.228L7.18101 80.7412L12.4905 83.5961L12.6279 94.0665C12.907 94.8156 12.8995 95.6402 12.6068 96.3841C12.3141 97.128 11.7565 97.7395 11.0396 98.1027C10.3228 98.466 9.49656 98.5558 8.71747 98.3551C7.93839 98.1543 7.26058 97.6771 6.81249 97.0137H6.81248Z"
          fill="#FFB8B8"
        />
        <path
          d="M8.70988 90.7092C8.56007 90.709 8.41087 90.6903 8.26573 90.6534C7.95357 90.5744 7.66873 90.4132 7.44134 90.1868C7.21396 89.9604 7.05249 89.6772 6.97401 89.3672L4.36475 79.08C3.45289 75.4871 3.88758 71.6879 5.58796 68.3892L11.0544 57.7799C11.4 56.5097 12.2349 55.4251 13.3792 54.7601C14.5234 54.095 15.8853 53.9028 17.1711 54.2248C17.812 54.3854 18.4143 54.6706 18.9433 55.0638C19.4722 55.4571 19.9172 55.9506 20.2524 56.5158C20.5946 57.0891 20.8183 57.7242 20.9107 58.384C21.003 59.0439 20.9621 59.7155 20.7904 60.3595L13.0088 77.6231L13.1012 87.9593C13.1036 88.3239 12.9931 88.6805 12.7846 88.9808C12.5761 89.2812 12.2798 89.5107 11.9355 89.6385L9.33141 90.598C9.13256 90.6714 8.92208 90.7091 8.70988 90.7092Z"
          fill="#27AD7A"
        />
        <path
          d="M45.9587 92.7883C45.4837 92.7345 45.0264 92.578 44.6189 92.3298C44.2115 92.0816 43.8639 91.7478 43.6006 91.3519C43.3372 90.956 43.1646 90.5077 43.0948 90.0384C43.025 89.5692 43.0598 89.0905 43.1966 88.636L33.7676 81.8962L39.4122 79.7661L47.5008 86.493C48.2551 86.7717 48.8802 87.3152 49.2575 88.0206C49.6349 88.726 49.7383 89.5442 49.5482 90.3202C49.3581 91.0962 48.8877 91.7761 48.2262 92.2309C47.5646 92.6858 46.7578 92.8842 45.9587 92.7883H45.9587Z"
          fill="#FFB8B8"
        />
        <path
          d="M41.0222 87.8666C40.7442 87.8664 40.4701 87.8022 40.2213 87.679L30.6603 82.9305C27.3208 81.2722 24.702 78.4671 23.2908 75.0368L18.7517 64.0054C18.0068 62.9168 17.7229 61.5815 17.9612 60.2872C18.1996 58.9929 18.941 57.8434 20.0258 57.0864C20.5677 56.7083 21.1801 56.4411 21.8273 56.3002C22.4744 56.1593 23.1434 56.1476 23.7951 56.2658C24.4545 56.3834 25.0838 56.6297 25.6466 56.9904C26.2093 57.351 26.6944 57.8189 27.0735 58.3669L35.186 75.48L43.1426 82.1532C43.4228 82.3892 43.6232 82.7049 43.7166 83.0576C43.8101 83.4103 43.7921 83.7829 43.6652 84.1251L42.6994 86.709C42.5733 87.0479 42.3455 87.3402 42.0467 87.5467C41.7463 87.755 41.3887 87.8667 41.0222 87.8666Z"
          fill="#27AD7A"
        />
        <path
          d="M22.0819 52.0574C22.5063 52.2416 23.3753 48.8866 22.986 48.2156C22.4071 47.2176 22.4413 47.2248 22.057 46.5646C21.6726 45.9044 21.5866 45.0007 22.0843 44.4197C22.5821 43.8388 23.7338 43.9159 23.961 44.6442C23.8148 43.2608 25.1941 42.1487 26.5604 41.8362C27.9267 41.5237 29.3678 41.7177 30.7416 41.4396C32.3359 41.1169 33.9947 39.791 33.3586 38.0516C33.2495 37.762 33.086 37.4955 32.877 37.2662C32.142 36.4464 31.1139 36.0964 30.1088 35.7579C28.0176 35.0537 25.8693 34.3407 23.6623 34.4184C21.8439 34.5128 20.0927 35.1309 18.6229 36.1973C17.1531 37.2637 16.0285 38.7321 15.3868 40.4227C15.2347 40.8456 15.1169 41.2799 15.0345 41.7214C14.277 45.7623 16.6422 49.7482 20.4838 51.287L22.0819 52.0574Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.2"
          d="M12.6504 88.1934L12.8315 77.569L18.5042 66.2534L13.9512 77.8687L12.6504 88.1934Z"
          fill="black"
        />
        <path
          d="M64.0924 114.857L40.1442 104.985C39.6193 104.771 39.1506 104.44 38.7738 104.019C38.397 103.598 38.122 103.097 37.9698 102.554C37.8133 102.005 37.7851 101.428 37.8874 100.867C37.9897 100.305 38.2197 99.7745 38.5599 99.3147C44.6082 91.0526 48.6112 81.494 50.245 71.4122C50.3335 70.8486 50.5504 70.3124 50.8792 69.8445C51.208 69.3766 51.64 68.9892 52.1424 68.7119C52.6374 68.436 53.1893 68.2758 53.7562 68.2434C54.323 68.2111 54.8897 68.3074 55.4134 68.5251L79.3616 78.3967C80.1875 78.7399 80.8637 79.3627 81.2699 80.1544C81.6761 80.946 81.7859 81.855 81.5798 82.7194C78.9027 93.5249 74.5744 103.86 68.7451 113.366C68.2732 114.121 67.5464 114.685 66.6944 114.958C65.8423 115.231 64.9203 115.195 64.0924 114.857Z"
          fill="#E6E6E6"
        />
        <path
          d="M72.4515 79.8817C72.2634 79.8816 72.0771 79.8453 71.9028 79.7751L59.2396 74.6957C58.8801 74.551 58.5931 74.2708 58.4416 73.9164C58.29 73.5621 58.2864 73.1626 58.4313 72.8055L59.4066 70.4125C59.5521 70.0555 59.8346 69.7705 60.1919 69.6201C60.5492 69.4698 60.9521 69.4664 61.3119 69.6107L73.9751 74.6901C74.3346 74.8347 74.6216 75.115 74.7732 75.4693C74.9247 75.8237 74.9284 76.2232 74.7834 76.5802L73.8081 78.9733C73.6986 79.2413 73.511 79.4709 73.2694 79.6327C73.0278 79.7944 72.743 79.8811 72.4515 79.8817Z"
          fill="#CCCCCC"
        />
        <path
          d="M45.8552 156H0.325214C0.238962 156 0.156247 155.966 0.0952577 155.905C0.0342683 155.845 0 155.763 0 155.677C0 155.592 0.0342683 155.51 0.0952577 155.449C0.156247 155.388 0.238962 155.354 0.325214 155.354H45.8552C45.9414 155.354 46.0242 155.388 46.0852 155.449C46.1462 155.51 46.1804 155.592 46.1804 155.677C46.1804 155.763 46.1462 155.845 46.0852 155.905C46.0242 155.966 45.9414 156 45.8552 156Z"
          fill="#CCCCCC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M68.3164 98.9184H59.3284V100.415H68.3164V98.9184ZM65.1406 89.2405L63.021 87.1222L52.4301 97.7058L54.5498 99.824L65.1406 89.2405ZM66.1967 90.2959L61.9574 94.5323L64.0771 96.6505L68.3164 92.4141L66.1967 90.2959ZM59.8377 83.9487L55.5984 88.1852L57.718 90.3034L61.9574 86.0669L59.8377 83.9487Z"
          fill="#27AD7A"
        />
      </svg>
    </div>
  );
}

export default NorunningbidsIcon;
