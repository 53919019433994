import React from 'react';

function ElectricVechicleIcon(props) {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#B3B3B3" xmlns="http://www.w3.org/2000/svg">
<path d="M18 2C16.6954 2 15.5967 2.83717 15.1836 4H5C3.35503 4 2 5.35503 2 7V13V19V18.9999C2 19.5522 2.44764 19.9999 2.9999 20H4.1875C4.60486 21.1573 5.70684 22 7 22C8.29316 22 9.39514 21.1573 9.8125 20H13.1875C13.6049 21.1573 14.7068 22 16 22C17.2932 22 18.3951 21.1573 18.8125 20H21C21.5523 20 22 19.5523 22 19V15.6406C22 14.2168 20.9843 12.9785 19.5879 12.6992L16.4922 12.0781L13.5859 9.17187C12.8358 8.42173 11.8185 8 10.7578 8H7.82031C6.87371 8 6.04573 8.67962 5.85937 9.60742L5.86328 9.58203L5.31445 12H4V7C4 6.43497 4.43497 6 5 6H15.1836C15.5967 7.16284 16.6954 8 18 8H21V6H19V4H21V2H18H18ZM7.82031 10H10.7578C11.2892 10 11.796 10.2101 12.1719 10.5859L13.5859 12H7.36523L7.81445 10.0254C7.81651 10.017 7.81847 10.0085 7.82031 10H7.82031ZM3.99996 14H15.9023L19.1953 14.6602C19.6688 14.7549 20 15.1584 20 15.6406V18H18.8125C18.3951 16.8427 17.2931 16 16 16C14.7068 16 13.6048 16.8427 13.1875 18H9.81246C9.3951 16.8427 8.29312 16 6.99996 16C5.7068 16 4.60482 16.8427 4.18746 18H3.99996V14ZM7 18C7.56413 18 8 18.4359 8 19C8 19.5641 7.56413 20 7 20C6.43587 20 6 19.5641 6 19C6 18.4359 6.43587 18 7 18ZM16 18C16.5641 18 17 18.4359 17 19C17 19.5641 16.5641 20 16 20C15.4359 20 15 19.5641 15 19C15 18.4359 15.4359 18 16 18Z" fill="#B3B3B3"/>
</svg>
        </div>
    );
}

export default ElectricVechicleIcon;