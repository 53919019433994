import React from "react";
import "./wonContainer.css";

function ResistrationContainer(props) {
  return (
    <div>
      <div className="quoteBreakdownComponent-header">
      <h4>Stap 2. Betalen en opnemen in bedrijfsvoorraad  </h4>
      </div>
      <div className="resistrationContainer-report-detail">
      We verzoeken je binnen 24 uur de auto te betalen en op te nemen in de bedrijfsvoorraad. 
      </div>
    </div>
  );
}

export default ResistrationContainer;
