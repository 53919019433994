import React, { useCallback, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import dateFormat from "dateformat";
import moment from "moment";
import GasFuelIcon from "../Icons/gasFuelIcon";
import CalenderIcon from "../Icons/calenderIcon";
import MilageIcon from "../Icons/milageIcon";
import GearboxIcon from "../Icons/gearboxIcon";
import ElectricVechicleIcon from "../Icons/electricVechicleIcon";
import StopwatchIcon from "../Icons/stopwatchIcon";
import TimerCalenderIcon from "../Icons/timerCalenderIcon";
import _ from "lodash";
import Hours from "../../atoms/Timer/hours";
import Minutes from "../../atoms/Timer/minutes";
import Seconds from "../../atoms/Timer/seconds";
import "../Cards/singleLotCardBody.css";
import DirectionCarIcon from "../Icons/directionCarIcon";
import AvailableUpdateButton from "../../atoms/UpdateButton/available-update-button";

function SingleLotCardBody(cardbodyModel) {

  const [flag, setFlag] = useState(false);

  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  const kmFormatter = new Intl.NumberFormat("nl-NL", {
    unit: "kilometer",
  });

  let end = moment(cardbodyModel.enddayofbidding);

  let interval;

  let start = moment(new Date(cardbodyModel.openForBidding));
  
  const startTimer = () => {
    let countDownDate = new Date(cardbodyModel.enddayofbidding).getTime();

    interval = setInterval(() => {
      let now = moment(new Date().getTime());

      const distance = countDownDate - now;
      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        var remainingtimesecondss = Math.floor(distance / 1000);
        var remainingtimeminutes = Math.floor(remainingtimesecondss / 60);
        var remainingtimehours = Math.floor(remainingtimeminutes / 60);

       

        remainingtimeminutes %= 60;
        remainingtimesecondss %= 60;

        setTimerHours(remainingtimehours);
        setTimerMinutes(remainingtimeminutes);
        setTimerSeconds(remainingtimesecondss);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
  });

  useEffect(() => {
    if (
      dateFormat(cardbodyModel.enddayofbidding, "HH") &&
      dateFormat(cardbodyModel.enddayofbidding, "HH") >= 2
    ) {
      setFlag(!flag);
    }
  }, []);

  localStorage.setItem('stateKey', JSON.stringify({
    accesstoken: cardbodyModel.accesstoken,
    userid: cardbodyModel.userid,
    catalogID: cardbodyModel.id,
    lotID: cardbodyModel.lotID,
    vehicleId: cardbodyModel.vehiclesID,
    bidStatus: cardbodyModel.status,
    OpenForBidding: cardbodyModel.openForBidding,
    enddayofbidding: cardbodyModel.enddayofbidding,
    bidInfoPage: cardbodyModel.bidInfoPage,
    minBidExpirationDays: cardbodyModel.minBidExpirationDays,
    licencePlate: cardbodyModel.licencePlate,
    modelType: cardbodyModel.modelType,
    brand: cardbodyModel.brand,
    jaar: cardbodyModel.jaar,
    fuelType: cardbodyModel.fuelType,
    milage: cardbodyModel.milage,
    transmission: cardbodyModel.transmission,
    minBid: cardbodyModel.minBid,
  }))  

  return (
    <div data-testid="singleLotCardbodyContainer">
      <Card.Body style={{ padding: "0px" }} key={cardbodyModel.licencePlate}>
        {cardbodyModel.status === "OpenForBidding" &&
        (cardbodyModel.bidInfoPage === null ||
          cardbodyModel.bidInfoPage?.bidAmount === null) ? (
          <Link
            to={{
              pathname: `carpassportslider/vehicleById/${cardbodyModel.vehiclesID}`,
              state: {
                currentIndex:_.findIndex(cardbodyModel.vehiclesDetail, (vDetail) => {
    
                  return _.isEqual(_.get(vDetail, "itemInfo.id"), cardbodyModel.vehiclesID);
                }),
                accesstoken: cardbodyModel.accesstoken,
                userid: cardbodyModel.userid,
                catalogID: cardbodyModel.id,
                lotID: cardbodyModel.lotID,
                vehicleId: cardbodyModel.vehiclesID,
                bidStatus: cardbodyModel.status,
                OpenForBidding: cardbodyModel.openForBidding,
                enddayofbidding: cardbodyModel.enddayofbidding,
                bidInfoPage: cardbodyModel.bidInfoPage,
                minBidExpirationDays: cardbodyModel.minBidExpirationDays,
                licencePlate: cardbodyModel.licencePlate,
                modelType: cardbodyModel.modelType,
                brand: cardbodyModel.brand,
                jaar: cardbodyModel.jaar,
                fuelType: cardbodyModel.fuelType,
                milage: cardbodyModel.milage,
                transmission: cardbodyModel.transmission,
                minBid: cardbodyModel.minBid,
                vehiclesDetail: cardbodyModel.vehiclesDetail
              },
            }}
            className="running"
          >
            <div className="cardbody-container">
              <div className="cardbody-container-title">
                <h4 className="container-title">{cardbodyModel.model} {cardbodyModel.modelType}</h4>
                <p className="container-sub-title">{cardbodyModel.brand}</p>
              </div>

              <div className="cardbody-container-body">
                <div className="cardbody-container-body-first">
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <DirectionCarIcon />
                      <span className="cardbody-body-property-title">
                      Carrosserie
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.coachType}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <GasFuelIcon />
                      <span className="cardbody-body-property-title">
                        Brandstof
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                    {cardbodyModel.fuelType === "Diesel"
                        ? "Diesel"
                        : cardbodyModel.fuelType === "CNG"
                        ? "Aardgas"
                        : cardbodyModel.fuelType === "Electric"
                        ? "Elektrisch"
                        : cardbodyModel.fuelType==="Electricgasoline"
                        ? "Elektr/benzine" 
                        : cardbodyModel.fuelType==="Electricdiesel"
                        ? "Elektr/diesel" 
                        : cardbodyModel.fuelType==="Gasoline"
                        ? "Benzine"
                        : cardbodyModel.fuelType==="LPG"
                        ? "LPG"
                        : cardbodyModel.fuelType==="Other"
                        ?  "Onbekend" 
                        : cardbodyModel.fuelType==="Unknown"
                        ? "Onbekend"
                        : cardbodyModel.fuelType==="Ethanol"
                        ? "Ethanol" 
                        : "Hydrogen"}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <CalenderIcon />
                      <span className="cardbody-body-property-title">
                        Bouwjaar
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.jaar !== null
                        ? dateFormat(cardbodyModel.jaar, "yyyy")
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="cardbody-container-body-first">
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <MilageIcon />
                      <span className="cardbody-body-property-title">
                        Km-stand
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {kmFormatter.format(cardbodyModel.milage)}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <GearboxIcon />
                      <span className="cardbody-body-property-title">
                        Transmissie
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.transmission === "Automatic"
                        ? "Automaat"
                        : cardbodyModel.transmission === "Manual"
                        ? "Handgeschakeld"
                        : cardbodyModel.transmission === "Semiautomatic"
                        ? "Semiautomaat"
                        : "-"}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-iconholder">
                      <span className="cardbody-body-property-title">
                        {cardbodyModel.fuelType === "Electric" ? (
                          <ElectricVechicleIcon />
                        ) : null}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {cardbodyModel.status !== "OpenForBidding" ? (
                <div className="cardbody-container-body-timer-upcoming">
                  <span className="cardbody-body-property-datetime-upcoming">
                    <span className="cardbody-body-property-iconbody-title">
                      Startdatum/tijd
                    </span>
                    <span className="cardbody-body-property-iconbody-content-parent">
                      <span className="cardbody-body-property-iconbody-content-date">
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(cardbodyModel.openForBidding, "dd-mm-yy")
                          : null}
                      </span>
                      <span
                        className="cardbody-body-property-iconbody-content-time"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(cardbodyModel.openForBidding, "HH")
                          : null}
                        :
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(start,"MM")
                          : null}
                      </span>
                    </span>
                  </span>
                  <span className="cardbody-body-property-icontitle-upcoming">
                    <TimerCalenderIcon />
                  </span>
                  <span className="cardbody-body-property-datetime-upcoming">
                    <span className="cardbody-body-property-iconbody-title">
                      Einddatum/tijd
                    </span>
                    <span className="cardbody-body-property-iconbody-content-parent">
                      <span className="cardbody-body-property-iconbody-content-date">
                        {cardbodyModel.enddayofbidding ||
                        cardbodyModel.enddayofbidding === "undefined"
                          ? dateFormat(
                              cardbodyModel.enddayofbidding,
                              "dd-mm-yy"
                            )
                          : null}
                      </span>
                      <span
                        className="cardbody-body-property-iconbody-content-time"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {dateFormat(cardbodyModel.enddayofbidding, "HH")}:
                        {dateFormat(end,"MM")}
                      </span>
                    </span>
                  </span>
                </div>
              ) : (
                <div className="cardbody-container-body-timer">
                  <div className="cardbody-container-body-enddatetimer">
                    <span className="cardbody-body-property-icontitle">
                      <StopwatchIcon
                        width="37px"
                        height="34px"
                        color="#B3B3B3"
                      />
                    </span>
                    <span className="cardbody-body-property-iconbody">
                      <span className="cardbody-body-property-iconbody-title">
                        Einddatum/tijd
                      </span>
                      <div className="cardbody-hours-card-parent">
                        <span
                          className={
                            flag === true
                              ? "cardbody-date-card-48"
                              : "cardbody-date-card-24"
                          }
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {cardbodyModel.enddayofbidding ||
                          cardbodyModel.enddayofbidding === "undefined"
                            ? dateFormat(
                                cardbodyModel.enddayofbidding,
                                "dd-mm-yy"
                              )
                            : null}
                        </span>
                        <span
                          className={
                            flag === true
                              ? "cardbody-date-card-48"
                              : "cardbody-date-card-24"
                          }
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "14px",
                          }}
                        >
                          {dateFormat(cardbodyModel.enddayofbidding, "HH")}:
                          {dateFormat(end,"MM")}
                        </span>
                      </div>
                    </span>
                  </div>

                  <div
                    className="cardbody-container-body-runningtimer"
                    id="timers"
                  >
                    <Hours
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                    />
                    :
                    <Minutes
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                      minutes={
                        timerMinutes !== undefined
                          ? timerMinutes.toString()
                          : ""
                      }
                    />
                    :
                    <Seconds
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                      seconds={
                        timerSeconds !== undefined
                          ? timerSeconds.toString()
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </Link>
        ) : cardbodyModel.status === "Published" &&
          (cardbodyModel?.bidInfoPage === null ||
            cardbodyModel?.bidInfoPage !== null ||
            cardbodyModel?.bidInfoPage == null) ? (
          <Link
            to={{
              pathname: `/carpassportslider/vehicleById/${cardbodyModel.vehiclesID}`,

              // pathname: `upcoming/vehicleById/${cardbodyModel.vehiclesID}`,
              state: {
                accesstoken: cardbodyModel.accesstoken,
                userid: cardbodyModel.userid,
                catalogID: cardbodyModel.id,
                lotID: cardbodyModel.lotID,
                vehicleId: cardbodyModel.vehiclesID,
                bidStatus: cardbodyModel.status,
                OpenForBidding: cardbodyModel.openForBidding,
                bidInfoPage: cardbodyModel.bidInfoPage,
                enddayofbidding: cardbodyModel.enddayofbidding,
                minBidExpirationDays: cardbodyModel.minBidExpirationDays,
                licencePlate: cardbodyModel.licencePlate,
                modelType: cardbodyModel.modelType,
                brand: cardbodyModel.brand,
                jaar: cardbodyModel.jaar,
                fuelType: cardbodyModel.fuelType,
                milage: cardbodyModel.milage,
                transmission: cardbodyModel.transmission,
                minBid: cardbodyModel.minBid,
                vehiclesDetail: cardbodyModel.vehiclesDetail
              },
            }}
            className="upcoming"
          >
            <div className="cardbody-container">
              <div className="cardbody-container-title">
                <h4 className="container-title">
                {cardbodyModel.model} {cardbodyModel.modelType} 
                </h4>
                <p className="container-sub-title">{cardbodyModel.brand}</p>
              </div>

              <div className="cardbody-container-body">
                <div className="cardbody-container-body-first">
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <DirectionCarIcon />
                      <span className="cardbody-body-property-title">
                      Carrosserie
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.coachType}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <GasFuelIcon />
                      <span className="cardbody-body-property-title">
                        Brandstof
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                    {cardbodyModel.fuelType === "Diesel"
                        ? "Diesel"
                        : cardbodyModel.fuelType === "CNG"
                        ? "Aardgas"
                        : cardbodyModel.fuelType === "Electric"
                        ? "Elektrisch"
                        : cardbodyModel.fuelType==="Electricgasoline"
                        ? "Elektr/benzine" 
                        : cardbodyModel.fuelType==="Electricdiesel"
                        ? "Elektr/diesel" 
                        : cardbodyModel.fuelType==="Gasoline"
                        ? "Benzine"
                        : cardbodyModel.fuelType==="LPG"
                        ? "LPG"
                        : cardbodyModel.fuelType==="Other"
                        ?  "Onbekend" 
                        : cardbodyModel.fuelType==="Unknown"
                        ? "Onbekend"
                        : cardbodyModel.fuelType==="Ethanol"
                        ? "Ethanol" 
                        : "Hydrogen"}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <CalenderIcon />
                      <span className="cardbody-body-property-title">
                        Bouwjaar
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.jaar !== null
                        ? dateFormat(cardbodyModel.jaar, "yyyy")
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="cardbody-container-body-first">
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <MilageIcon />
                      <span className="cardbody-body-property-title">
                        Km-stand
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {kmFormatter.format(cardbodyModel.milage)}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <GearboxIcon />
                      <span className="cardbody-body-property-title">
                        Transmissie
                      </span>
                    </span>
                    
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.transmission === "Automatic"
                        ? "Automaat"
                        : cardbodyModel.transmission === "Manual"
                        ? "Handgeschakeld"
                        : cardbodyModel.transmission === "Semiautomatic"
                        ? "Semiautomaat"
                        : "-"}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-iconholder">                 

                      <span className="cardbody-body-property-title">
                        {cardbodyModel.fuelType === "Electric" ? (
                          <ElectricVechicleIcon />
                        ) : null}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {cardbodyModel.status !== "OpenForBidding" ? (
                <div className="cardbody-container-body-timer-upcoming">
                  <span className="cardbody-body-property-datetime-upcoming">
                    <span className="cardbody-body-property-iconbody-title">
                      Startdatum/tijd
                    </span>
                    <span className="cardbody-body-property-iconbody-content-parent">
                      <span className="cardbody-body-property-iconbody-content-date">
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(cardbodyModel.openForBidding, "dd-mm-yy")
                          : null}
                      </span>
                      <span
                        className="cardbody-body-property-iconbody-content-time"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(cardbodyModel.openForBidding, "HH")
                          : null}
                        :
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(start,"MM")
                          : null}
                      </span>
                    </span>
                  </span>
                  <span className="cardbody-body-property-icontitle-upcoming">
                    <TimerCalenderIcon />
                  </span>
                  <span className="cardbody-body-property-datetime-upcoming">
                    <span className="cardbody-body-property-iconbody-title">
                      Einddatum/tijd
                    </span>
                    <span className="cardbody-body-property-iconbody-content-parent">
                      <span className="cardbody-body-property-iconbody-content-date">
                        {cardbodyModel.enddayofbidding ||
                        cardbodyModel.enddayofbidding === "undefined"
                          ? dateFormat(
                              cardbodyModel.enddayofbidding,
                              "dd-mm-yy"
                            )
                          : null}
                      </span>
                      <span
                        className="cardbody-body-property-iconbody-content-time"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {dateFormat(cardbodyModel.enddayofbidding, "HH")}:
                        {dateFormat(end,"MM")}
                      </span>
                    </span>
                  </span>
                </div>
              ) : (
                <div className="cardbody-container-body-timer">
                  <div className="cardbody-container-body-enddatetimer">
                    <span className="cardbody-body-property-icontitle">
                      <StopwatchIcon
                        width="37px"
                        height="34px"
                        color="#B3B3B3"
                      />
                    </span>
                    <span className="cardbody-body-property-iconbody">
                      <span className="cardbody-body-property-iconbody-title">
                        Einddatum/tijd
                      </span>
                      <div className="cardbody-hours-card-parent">
                        <span
                          className={
                            flag === true
                              ? "cardbody-date-card-48"
                              : "cardbody-date-card-24"
                          }
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {cardbodyModel.enddayofbidding ||
                          cardbodyModel.enddayofbidding === "undefined"
                            ? dateFormat(
                                cardbodyModel.enddayofbidding,
                                "dd-mm-yy"
                              )
                            : null}
                        </span>
                        <span
                          className={
                            flag === true
                              ? "cardbody-date-card-48"
                              : "cardbody-date-card-24"
                          }
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "24px",
                          }}
                        >
                          {dateFormat(cardbodyModel.enddayofbidding, "HH")}:
                          {dateFormat(end,"MM")}
                        </span>
                      </div>
                    </span>
                  </div>
                  <div
                    className="cardbody-container-body-runningtimer"
                    id="timers"
                  >
                    <Hours
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                    />
                    :
                    <Minutes
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                      minutes={
                        timerMinutes !== undefined
                          ? timerMinutes.toString()
                          : ""
                      }
                    />
                    :
                    <Seconds
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                      seconds={
                        timerSeconds !== undefined
                          ? timerSeconds.toString()
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </Link>
        ) : (
          <Link
            to={{
              pathname: `/carpassportslider/vehicleById/${cardbodyModel.vehiclesID}`,

              // pathname: `update/vehicleById/${cardbodyModel.vehiclesID}`,
              state: {
                bidId: cardbodyModel?.bidInfoPage?.bidId,
                BidAmount:
                  cardbodyModel.bidInfoPage.bidAmount > cardbodyModel.minBid
                    ? cardbodyModel.bidInfoPage.bidAmount
                    : cardbodyModel.minBid,
                accesstoken: cardbodyModel.accesstoken,
                userid: cardbodyModel.userid,
                catalogID: cardbodyModel.id,
                lotID: cardbodyModel.lotID,
                vehicleId: cardbodyModel.vehiclesID,
                bidStatus: cardbodyModel.status,
                OpenForBidding: cardbodyModel.openForBidding,
                bidInfoPage: cardbodyModel.bidInfoPage,
                enddayofbidding: cardbodyModel.enddayofbidding,
                minBidExpirationDays: cardbodyModel.minBidExpirationDays,
                licencePlate: cardbodyModel.bidInfoPage.licencePlate,
                modelType: cardbodyModel.modelType,
                brand: cardbodyModel.brand,
                jaar: cardbodyModel.jaar !== null ? cardbodyModel.jaar : "",
                fuelType: cardbodyModel.fuelType,
                milage: cardbodyModel.milage,
                transmission: cardbodyModel.transmission,
                minBid: cardbodyModel.minBid,
                vehiclesDetail: cardbodyModel.vehiclesDetail

              },
            }}
            className="update"
          >
            <div className="cardbody-container">
              <div className="cardbody-container-title">
                <h4 className="container-title">
                {cardbodyModel.model} {cardbodyModel.modelType}
                </h4>
                <p className="container-sub-title">{cardbodyModel.brand}</p>
              </div>

              <div className="cardbody-container-body">
                <div className="cardbody-container-body-first">
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <DirectionCarIcon />
                      <span className="cardbody-body-property-title">
                      Carrosserie
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.coachType}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <GasFuelIcon />
                      <span className="cardbody-body-property-title">
                        Brandstof
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                    {cardbodyModel.fuelType === "Diesel"
                        ? "Diesel"
                        : cardbodyModel.fuelType === "CNG"
                        ? "Aardgas"
                        : cardbodyModel.fuelType === "Electric"
                        ? "Elektrisch"
                        : cardbodyModel.fuelType==="Electricgasoline"
                        ? "Elektr/benzine" 
                        : cardbodyModel.fuelType==="Electricdiesel"
                        ? "Elektr/diesel" 
                        : cardbodyModel.fuelType==="Gasoline"
                        ? "Benzine"
                        : cardbodyModel.fuelType==="LPG"
                        ? "LPG"
                        : cardbodyModel.fuelType==="Other"
                        ?  "Onbekend" 
                        : cardbodyModel.fuelType==="Unknown"
                        ? "Onbekend"
                        : cardbodyModel.fuelType==="Ethanol"
                        ? "Ethanol" 
                        : "Hydrogen"}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <CalenderIcon />
                      <span className="cardbody-body-property-title">
                        Bouwjaar
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.jaar !== null
                        ? dateFormat(cardbodyModel.jaar, "yyyy")
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="cardbody-container-body-first">
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <MilageIcon />
                      <span className="cardbody-body-property-title">
                        Km-stand
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {kmFormatter.format(cardbodyModel.milage)}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-icon">
                      <GearboxIcon />
                      <span className="cardbody-body-property-title">
                        Transmissie
                      </span>
                    </span>
                    <span className="cardbody-body-property-body">
                      {cardbodyModel.transmission === "Automatic"
                        ? "Automaat"
                        : cardbodyModel.transmission === "Manual"
                        ? "Handgeschakeld"
                        : cardbodyModel.transmission === "Semiautomatic"
                        ? "Semiautomaat"
                        : "-"}
                    </span>
                  </div>
                  <div className="cardbody-container-body-property">
                    <span className="cardbody-body-property-iconholder">
                      <span className="cardbody-body-property-title">
                        {cardbodyModel.fuelType === "Electric" ? (
                          <ElectricVechicleIcon />
                        ) : null}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {cardbodyModel.status !== "OpenForBidding" ? (
                <div className="cardbody-container-body-timer-upcoming">
                  <span className="cardbody-body-property-datetime-upcoming">
                    <span className="cardbody-body-property-iconbody-title">
                      Startdatum/tijd
                    </span>
                    <span className="cardbody-body-property-iconbody-content-parent">
                      <span className="cardbody-body-property-iconbody-content-date">
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(cardbodyModel.openForBidding, "dd-mm-yy")
                          : null}
                      </span>
                      <span
                        className="cardbody-body-property-iconbody-content-time"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(cardbodyModel.openForBidding, "HH")
                          : null}
                        :
                        {cardbodyModel.openForBidding ||
                        cardbodyModel.openForBidding === "undefined"
                          ? dateFormat(start,"MM")
                          : null}
                      </span>
                    </span>
                  </span>
                  <span className="cardbody-body-property-icontitle-upcoming">
                    <TimerCalenderIcon />
                  </span>
                  <span className="cardbody-body-property-datetime-upcoming">
                    <span className="cardbody-body-property-iconbody-title">
                      Einddatum/tijd
                    </span>
                    <span className="cardbody-body-property-iconbody-content-parent">
                      <span className="cardbody-body-property-iconbody-content-date">
                        {cardbodyModel.enddayofbidding ||
                        cardbodyModel.enddayofbidding === "undefined"
                          ? dateFormat(
                              cardbodyModel.enddayofbidding,
                              "dd-mm-yy"
                            )
                          : null}
                      </span>
                      <span
                        className="cardbody-body-property-iconbody-content-time"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "24px",
                        }}
                      >
                        {dateFormat(cardbodyModel.enddayofbidding, "HH")}:
                        {dateFormat(end,"MM")}
                      </span>
                    </span>
                  </span>
                </div>
              ) : (
                <div className="cardbody-container-body-timer">
                  <div className="cardbody-container-body-enddatetimer">
                    <span className="cardbody-body-property-icontitle">
                      <StopwatchIcon
                        width="37px"
                        height="34px"
                        color="#B3B3B3"
                      />
                    </span>
                    <span className="cardbody-body-property-iconbody">
                      <span className="cardbody-body-property-iconbody-title">
                        Einddatum/tijd
                      </span>
                      <div className="cardbody-hours-card-parent">
                        <span
                          className={
                            flag === true
                              ? "cardbody-date-card-48"
                              : "cardbody-date-card-24"
                          }
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {cardbodyModel.enddayofbidding ||
                          cardbodyModel.enddayofbidding === "undefined"
                            ? dateFormat(
                                cardbodyModel.enddayofbidding,
                                "dd-mm-yy"
                              )
                            : null}
                        </span>
                        <span
                          className={
                            flag === true
                              ? "cardbody-date-card-48"
                              : "cardbody-date-card-24"
                          }
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "24px",
                          }}
                        >
                          {dateFormat(cardbodyModel.enddayofbidding, "HH")}:{dateFormat(end,"MM")}
                        </span>
                      </div>
                    </span>
                  </div>
                  <div
                    className="cardbody-container-body-runningtimer"
                    id="timers"
                  >
                    <Hours
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                    />
                    :
                    <Minutes
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                      minutes={
                        timerMinutes !== undefined
                          ? timerMinutes.toString()
                          : ""
                      }
                    />
                    :
                    <Seconds
                      hours={
                        timerHours !== undefined ? timerHours.toString() : ""
                      }
                      seconds={
                        timerSeconds !== undefined
                          ? timerSeconds.toString()
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </Link>
        )}

        {cardbodyModel.status === "OpenForBidding" &&
        (cardbodyModel.bidInfoPage === null ||
          cardbodyModel.bidInfoPage?.bidAmount === null) ? (
          <Link
            to={{
              pathname: `/carpassportslider/vehicleById/${cardbodyModel.vehiclesID}`,
              state: {
                accesstoken: cardbodyModel.accesstoken,
                userid: cardbodyModel.userid,
                catalogID: cardbodyModel.id,
                lotID: cardbodyModel.lotID,
                vehicleId: cardbodyModel.vehiclesID,
                bidStatus: cardbodyModel.status,
                OpenForBidding: cardbodyModel.openForBidding,
                bidInfoPage: cardbodyModel.bidInfoPage,
                minBidExpirationDays: cardbodyModel.minBidExpirationDays,
                licencePlate: cardbodyModel.licencePlate,
                modelType: cardbodyModel.modelType,
                brand: cardbodyModel.brand,
                bidAmount: cardbodyModel?.bidInfoPage?.bidAmount,
                enddayofbidding: cardbodyModel.enddayofbidding,
                minBid:cardbodyModel.minBid,
                vehiclesDetail: cardbodyModel.vehiclesDetail
              },
            }}
          >
            <div className="cardbody-container-body-button">
              <span className="cardbody-container-body-button-title">+</span>
              <span className="cardbody-container-body-button-body">
                PLAATS JOUW BOD
              </span>
            </div>
          </Link>
        ) : cardbodyModel.status === "Published" &&
          (cardbodyModel?.bidInfoPage === null ||
            cardbodyModel?.bidInfoPage !== null) ? (
          <Link
            to={{
              pathname: `/carpassportslider/vehicleById/${cardbodyModel.vehiclesID}`,
              // pathname: `upcoming/vehicleById/${cardbodyModel.vehiclesID}`,
              state: {
                accesstoken: cardbodyModel.accesstoken,
                userid: cardbodyModel.userid,
                catalogID: cardbodyModel.id,
                lotID: cardbodyModel.lotID,
                vehicleId: cardbodyModel.vehiclesID,
                bidStatus: cardbodyModel.status,
                OpenForBidding: cardbodyModel.openForBidding,
                bidInfoPage: cardbodyModel.bidInfoPage,
                minBidExpirationDays: cardbodyModel.minBidExpirationDays,
                licencePlate: cardbodyModel.licencePlate,
                modelType: cardbodyModel.modelType,
                brand: cardbodyModel.brand,
                minBid:cardbodyModel.minBid,
                vehiclesDetail: cardbodyModel.vehiclesDetail

              },
            }}
          >
            <div className="cardbody-container-body-button-upcoming">
              <span className="cardbody-container-body-button-body-upcoming">
                START BINNENKORT
              </span>
            </div>
          </Link>
        ) : (
          <div className="cardbody-container-body-button-update">
            <div className="button-heading">
              <span className="text-container">jouw bod</span>
              <span class="material-icons-outlined info">info</span>
            </div>
            <div className="button-container">
              <AvailableUpdateButton
                bidInfoPage={cardbodyModel?.bidInfoPage}
                bidId={cardbodyModel?.bidInfoPage?.bidId}
                accesstoken={cardbodyModel?.accesstoken}
                userid={cardbodyModel?.userid}
                catalogID={cardbodyModel?.id}
                vehicleId={cardbodyModel?.vehiclesID}
                lotID={cardbodyModel?.lotID}
                bidStatus={cardbodyModel?.status}
                OpenForBidding={cardbodyModel?.OpenForBidding}
                licencePlate={cardbodyModel?.licencePlate}
                modelType={cardbodyModel?.modelType}
                brand={cardbodyModel?.brand}
                enddayofbidding={cardbodyModel?.enddayofbidding}
                minBid={cardbodyModel.minBid}
              />
            </div>
          </div>
        )}
      </Card.Body>
    </div>
  );
}

export default SingleLotCardBody;
