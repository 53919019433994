import React, { useState } from "react";
import _ from "lodash";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./DamageAccordion.css";

const DamageAccordion = (props) => {
  const [expanded, setExpanded] = useState(true);
  let panelId = `panel${props.index}`;
  let damageContent = props.damageContent ? props.damageContent : [];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="DamageAccordionWrapper">
      <Accordion expanded={expanded} onChange={handleChange(panelId)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${panelId}bh-content`}
          id={`${panelId}bh-header`}
        >
          {props.title ? (
            <span
              className="AccordionTitle"
            >
              {_.capitalize(props.title)}
            </span>
          ) : null}
        </AccordionSummary>
        <AccordionDetails>
          <div className={`${props.damageType}Direction`}>
            {damageContent.map((damage, index) => {
              return (
                <div
                  className={`accordionContent ${damageContent.length === 1 ? "singleItemWidth" : "halfWidth"}`}
                  key={index + Math.random()}
                >
                  <div
                    className="imageSection"
                    onClick={() => {
                      return props.sliderHandler(damage);
                    } }
                  >
                    <img
                      src={props.getImageFunc(damage)}
                      alt={damage && damage.media && damage.media.description
                        ? damage.media.description
                        : "Car"} />
                  </div>
                  <div className="contentSection">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Live make-up van welke aard dan ook, niet de auteur die  */}
                    {damage.description ? damage.description : ""}
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DamageAccordion;
