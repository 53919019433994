import React from 'react';

function TimerCalenderIcon(props) {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1 4C1 2.895 1.95416 2 3.1322 2L3.1322 2H18.0576C19.2356 2 20.1898 2.895 20.1898 4V9.58789C19.5171 9.33189 18.8028 9.15536 18.0576 9.06836V8H3.1322V18H8.53557C8.62832 18.699 8.81652 19.369 9.08944 20H3.1322C1.95416 20 1 19.105 1 18V4ZM18.0577 4H3.1323V6H18.0577V4ZM5.26454 10V12H7.39674V10H5.26454ZM9.52884 12V10H12.8666C11.8901 10.507 11.0329 11.189 10.3388 12H9.52884ZM16.9916 11C13.4735 11 10.5951 13.7 10.5951 17C10.5951 20.3 13.4735 23 16.9916 23C20.5098 23 23.3882 20.3 23.3882 17C23.3882 13.7 20.5098 11 16.9916 11ZM21.2561 17C21.2561 14.8 19.3371 13 16.9917 13C14.6463 13 12.7273 14.8 12.7273 17C12.7273 19.2 14.6463 21 16.9917 21C19.3371 21 21.2561 19.2 21.2561 17ZM5.26454 14V16H7.39674V14H5.26454ZM15.7423 17.1387L17.4477 14.1172L18.8615 14.8184L17.7079 16.8613L19.1093 18.1758L17.9786 19.2363L15.7423 17.1387Z" fill="#90A0B7"/>
</svg>
        </div>
    );
}

export default TimerCalenderIcon;