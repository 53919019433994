export const filterDutchWords = {
  Manual: "Handgeschakeld",
  Automatic: "Automaat",
  Diesel: "Diesel",
  Gasoline: "Benzine",
  Electric: "Elektrisch",
  Electricgasoline: "Elektrisch/benzine",
  Electricdiesel: "Elektrisch/diesel",
  CNG: "Aardgas",
  LPG: "LPG",
  Semiautomatic: "Semiautomatic",
};
