import React,{useState} from "react";
import "./userprofileComponent.css";
import { Button } from "@material-ui/core";
import Oval from '../../images/Oval.png';
// import ProfileImage from '../../images/profile_pic.jpg'
import ProfileImage from "../../images/profile_image.jpg";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Avatar from '@material-ui/core/Avatar';
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneAndroidSharpIcon from '@material-ui/icons/PhoneAndroidSharp';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: '1px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "41ch",
  },
}));

function UserprofileComponent(userProfile) {
  const classes = useStyles();
  const username = localStorage.getItem("userid");
  const firstname = localStorage.getItem("given_name");
  const lastname = localStorage.getItem("family_name");
  const phonenumber = localStorage.getItem("phone_number");
  const email = localStorage.getItem("email");
  const capitalize = (str) => {
    if(typeof str === 'string') {
        return str.replace(/^\w/, c => c.toUpperCase());
    } else {
        return '';
    }
};

  const [file, setFile] = React.useState(null)
  const [values, setValues] = React.useState({
    password: "",
    password1: "",
    password2: "",
    showPasswords: false,
    showPasswords1: false,
    showPasswords2: false,
  });
  const [disable, setDisable] =useState(true);


  const fileHandler = (e) => {
    setFile(e.target.files[0])
}


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
   if (event.target.value==null || event.target.value==""){
     setDisable(true)
   }
   else{
     setDisable(false);
   }
  };

  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPasswords: !values.showPasswords });
    
  };

  const handleClickShowPassword1=() => {
    setValues({ ...values, showPasswords1: !values.showPasswords1 }); 
  };
  
  const handleClickShowPassword2 =() => {
    setValues({ ...values, showPasswords2: !values.showPasswords2 });  
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="user-profile-wrapper" data-testid="user-profile-component">
      <div className="user-profile-container">
        <div className="user-profile-information">
          <div className="user-profile-personal-imformation">
            <div className="user-profile-heading">Persoonlijke informatie</div>
            <div className="user-profile-photo">
              <div className="user">
                <img 
                  src={file? URL.createObjectURL(file) : ProfileImage} alt={file? file.name : null}
                  class="main-profile-img"
                />
                <div class="image-upload">
                 <label for="file-input">
                <i class="fa fa-edit" ></i>
                </label>
            <input type="file" id="file-input" name="myImage" onChange={fileHandler} />
            </div>
                {/* <input type="file" id="wizard-picture" class=""></input> */}
              </div>
            </div>
            <div>
              <form noValidate autoComplete="on" className="user-profile-form">
                <TextField
                  id="filled-basic"
                  label="Voornaam *"
                  variant="filled"
                  className="user-profile-form-input-user"
                  value={JSON.parse(firstname)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="Achternaam *"
                  variant="filled"
                  className="user-profile-form-input-user"
                  value={JSON.parse(lastname)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="Gebruikersnaam *"
                  variant="filled"
                  className="user-profile-form-input-user"
                  value={capitalize(JSON.parse(username))}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="E-mail *"
                  variant="filled"
                  className="user-profile-form-input-user"
                  value= {JSON.parse(email)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="Telefoon"
                  variant="filled"
                  className="user-profile-form-input-user"
                  value= {JSON.parse(phonenumber)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="Positie"
                  variant="filled"
                  className="user-profile-form-input-user"
                  value= "N/A"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <div></div>
                {/* <Button variant="contained" color="primary" disabled>
                  Wijzigingen opslaan
                </Button> */}
              </form>
            </div>
          </div>
          {/* <div className="user-profile-change-password">
            <h1>Wachtwoord wijzigen</h1>
            <form noValidate autoComplete="off" className="user-profile-form">
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Huidig ​​wachtwoord
                </InputLabel>
                <FilledInput
                  className="user-profile-form-input-password"
                  id="filled-adornment-password"
                  type={values.showPasswords ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPasswords ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                     

                    </InputAdornment>
                  }
                />
              </FormControl>
              <div></div>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Nieuw paswoord
                </InputLabel>
                <FilledInput
                  className="user-profile-form-input-password"
                  id="filled-adornment-password"
                  type={values.showPasswords1 ? "text" : "password"}
                  value={values.password1}
                  onChange={handleChange("password1")}
                  endAdornment={
                    <InputAdornment position="end">
                     
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPasswords1 ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                     
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  typ wachtwoord nogmaals
                </InputLabel>
                <FilledInput
                  className="user-profile-form-input-password"
                  id="filled-adornment-password"
                  type={values.showPasswords2 ? "text" : "password"}
                  value={values.password2}
                  onChange={handleChange("password2")}
                  endAdornment={
                    <InputAdornment position="end">
                    
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPasswords2 ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                      
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div></div>
              <Button variant="contained" color="primary"  disabled={disable}>
                <span style={{fontSize: '14px'}}>Wijzigingen opslaan</span>
              </Button>
            </form>
          </div>
         */}
        </div>
        {/* <div className="user-profile-address">
            <div className="user-profile-address-top">
                <div className="user-profile-address-image-holder">
                    <img src={Oval} alt="image holder" />
                </div>
                <div className="user-profile-address-complete-holder">
                    <div className="user-profile-address-title">{userProfile?.userProfile?.pxResults[0].addressID}</div>
                    <div className="user-profile-address-subtitle">{userProfile?.userProfile?.pxResults[0].pxPages.add.houseNumber} {userProfile?.userProfile?.pxResults[0].pxPages.add.zipCode} {userProfile?.userProfile?.pxResults[0].pxPages.add.city} {userProfile?.userProfile?.pxResults[0].pxPages.add.country}.  </div>
                    <div className="user-profile-address-endtitle">KvK: {userProfile?.userProfile?.pxResults[0]?.coCNumber}</div>
                </div>
            </div>
            <div className="user-profile-address-bottom">
            <div className="user-profile-heading">Systeem administrator</div>
            <div className="user-profile-information-profile">
                <span className="user-profile-information-avatar"><Avatar alt="" src="" /></span>
                <span className="user-profile-information-name">{userProfile?.userProfile?.pxResults[0].addressID}</span>
            </div>
            <div className="user-profile-information-phone">
                <span className="user-profile-information-icon"><PhoneAndroidSharpIcon /></span>
                <span className="user-profile-information-name">{userProfile?.userProfile?.pxResults[0].pxPages.adm.phone}</span>
            </div>
            <div>
                <span className="user-profile-information-icon"><MailOutlineRoundedIcon /></span>
                <span className="user-profile-information-name">{userProfile?.userProfile?.pxResults[0].pxPages.adm.email}</span>
            </div>
            </div>
        </div>*/}
      </div> 
    </div>
  );
}

export default UserprofileComponent;
