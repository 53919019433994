import React from 'react'
import '../Carpassports/TopHeader/wonbidHeader.css'
function RegistratieIcon() {
    return (
        <div className="registratie-icon">
        
<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.66628 5.6666H10.9746L6.90794 1.59993C6.58294 1.27493 6.58294 0.741602 6.90794 0.416602C7.23294 0.0916016 7.75794 0.0916016 8.08294 0.416602L13.5746 5.90827C13.8996 6.23327 13.8996 6.75827 13.5746 7.08327L8.08294 12.5749C7.75794 12.8999 7.23294 12.8999 6.90794 12.5749C6.58294 12.2499 6.58294 11.7249 6.90794 11.3999L10.9746 7.33327H1.66628C1.20794 7.33327 0.832943 6.95827 0.832943 6.49994C0.832943 6.0416 1.20794 5.6666 1.66628 5.6666Z" fill="white"/>
</svg>

        </div>
    )
}

export default RegistratieIcon
