import React from "react";
import "./hours.css";

export default function Hours({ hours, totalhoursleft }) {
  setInterval(hours, 1000);

  const hoursSplit = hours.split("");
  if (hoursSplit[1] == null) {
    hoursSplit[1] = hoursSplit[0];
    hoursSplit[0] = 0;
  }

  return (
    <>
      <div className="hours-section" data-testid = "hours-section"> 
        <div className="hours-container">
          {hours >= 2 && hours >= 0 && hoursSplit[0] >= 0 ? (
            <div
              className={
                hours >= 2 && hours >= 0 && hoursSplit[0] >= 0
                  ? "hours-card-48"
                  : "hours-card-2"
              }
            >
              <span className="hours-tens-block">
                {hoursSplit[0] >= 0 ? hoursSplit[0] : null}
              </span>
            </div>
          ) : (
            <div className="hours-card">
              <div
                className={
                  hours < 2 && hours >= 0 && hoursSplit[0] >= 0
                    ? "hours-card-2"
                    : "hours-card-48"
                }
              >
                <span className="hours-tens-block">
                  {hoursSplit[0] >= 0 ? hoursSplit[0] : null}
                </span>
              </div>
            </div>
          )}
          {hours >= 2 && hours >= 0 && hoursSplit[1] >= 0 ? (
            <div
              className={
                hours >= 2 && hours >= 0 && hoursSplit[0] >= 0
                  ? "hours-card-48"
                  : "hours-card-2"
              }
            >
              <span className="hours-tens-block">
                {hoursSplit[1] >= 0 ? hoursSplit[1] : null}
              </span>
            </div>
          ) : (
            <div className="hours-card">
              <div
                className={
                  hours < 2 && hours >= 0 && hoursSplit[0] >= 0
                    ? "hours-card-2"
                    : "hours-card-48"
                }
              >
                <span className="hours-tens-block">
                  {hoursSplit[1] >= 0 ? hoursSplit[1] : null}
                </span>
              </div>
            </div>
          )}

          {hours >= 2 && hours >= 0 && hoursSplit[2] >= 0 ? (
            <div
              className={
                hours >= 2 && hours >= 0 && hoursSplit[2] >= 0
                  ? "hours-card-48"
                  : "hours-card-2"
              }
            >
              <span className="hours-units-block">
                {hoursSplit[2] >= 0 ? hoursSplit[2] : null}
              </span>
            </div>
          ) : null}
        </div>
        <span className="hours-title">Uren</span>
      </div>
    </>
  );
}
