import React, { useState, useEffect } from "react";
import "./auction-dashboard.css";
import DashboardMybids from "../components/Auction-Dashboard/dashboard-mybids";
import { Link } from "react-router-dom";

import AuctionCard from "../components/Cards/auction-dashboard-cards/AuctionCard";
import UpcomingAuctionsIcon1 from "../components/Icons/AuctionDashboard/upcomingAuctionsIcon1";
import UpcomingAuctionIcon2 from "../components/Icons/AuctionDashboard/upcomingAuctionIcon2";
import UpcomingAuctionsIcon3 from "../components/Icons/AuctionDashboard/upcomingAuctionsIcon3";
import NorunningupcomingauctionIcon from "../components/Icons/AuctionDashboard/norunningupcomingauctionIcon";
const hostURL = process.env.REACT_APP_SERVER_URL;
function AuctionDashboard(props) {
  const getToken = localStorage.getItem("Token");
  const getUserid = localStorage.getItem("userid");
  const [auctions, setAuctions] = useState([]);
  const [totalRunningauction, setTotalRunningAuction] = useState(0);
  const [totalReadyForAuction, setTotalReadyForAuction] = useState(0);
  const [myAuctionStatus, setMyAuctionStatus] = useState([]);

  const [data, setData] = useState([]);
  const [wonbid, setWonbid] = useState(0);
  useEffect(() => {
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    fetch(
      hostURL +
        `/api/AuctionDashboard/AuctionStatistics/${JSON.parse(getUserid)}`,
      requestGetOptions
    )
      .then((response) => response.json())
      .then((dataobject) => setData(dataobject));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vehiclesAPI = (params, auctionaAllData) => {
    const requestOptionsvalue = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: JSON.stringify(params),
    };

    fetch(
      hostURL +
        `/api/auction/FilterVehicleLotsByMultipleCatalogIds?userId=${JSON.parse(
          getUserid
        )}`,
      requestOptionsvalue
    )
      .then((response) => response.json())
      .then((data) => {
        let auctionMerge = [];
        for (let i = 0; i < auctionaAllData.length; i++) {
          const filteredVehicles = data.filter((vechicle) => {
            return vechicle.catalogID === auctionaAllData[i].id;
          });
          auctionMerge.push({
            ...auctionaAllData[i],
            filteredVehicles,
          });
        }
        auctionMerge.sort((a, b) => {
          let aStatus = a.status,
            bStatus = b.status,
            aEndTime = new Date(a.auctionParameters.closedForBidding),
            bEndTime = new Date(b.auctionParameters.closedForBidding);
          return aStatus === bStatus
            ? aEndTime - bEndTime
            : aStatus === "OpenForBidding"
            ? -1
            : 1;
        });
        let noUpcomingAuction = auctionMerge.filter(
          (auction) => auction.status !== "Published"
        );
        let upcomingAuction = auctionMerge
          .filter((auction) => auction.status === "Published")
          .sort((a, b) => {
            let aEndTime = new Date(a.openForBidding),
              bEndTime = new Date(b.openForBidding);
            return aEndTime - bEndTime;
          });
        auctionMerge = [...noUpcomingAuction, ...upcomingAuction];
        setAuctions(auctionMerge);
      });
  };

  useEffect(() => {
    const params = {
      filters: [
        {
          field: "Status",
          value: "OpenForBidding",
          operator: "Equals",
        },
        {
          field: "Status",
          value: "Published",
          operator: "Equals",
        },
        {
          field: "Status",
          value: "InConsideration",
          operator: "Equals",
        }
      ],
      operator: "Or",
    };

    const requestGetOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: JSON.stringify(params),
    };

    fetch(hostURL + "/api/auction/GetAuctionByStatus", requestGetOptions)
      .then((response) => {
        let dataJson = response.json();
        setMyAuctionStatus(response.status);
        return dataJson;
      })
      .then((data) => {
        localStorage.setItem("GetAuctionByStatus",data?.length)
        const runningFilterArrayAuctionViewAll = [];
        const readyForAuctionFilterArrayAuctionViewAll = [];

        const auctionfiltersList = [];
        // eslint-disable-next-line array-callback-return
        let dataList = data ? data :[]; 
        dataList?.map((auctionAPI, key) => {
          const filter = {
            field: "CatalogID",
            value: auctionAPI.id,
            operator: "Equals",
          };
          auctionfiltersList.push(filter);
        });
        const params = {
          filters: auctionfiltersList,
          operator: "Or",
        };
        vehiclesAPI(params, data);

        // eslint-disable-next-line array-callback-return
        data.map((runningMap) => {
          let enddate = new Date(runningMap.auctionParameters.closedForBidding);
          let nowdate = new Date();
          if (runningMap.status === "OpenForBidding" && nowdate < enddate)
            runningFilterArrayAuctionViewAll.push(runningMap);
        });
        // eslint-disable-next-line array-callback-return
        data.map((upcomingMap) => {
          if (upcomingMap.status === "Published")
            readyForAuctionFilterArrayAuctionViewAll.push(upcomingMap);
        });

        setTotalRunningAuction(runningFilterArrayAuctionViewAll?.length);
        setTotalReadyForAuction(
          readyForAuctionFilterArrayAuctionViewAll?.length
        );
        return true;
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });


  const handleChange = (e) => {
    setWonbid({ wonbid: e.target.value ? e.target.value : "0" });
  };


  return (
    <div className="auction-dashboard-wrapper" data-testid="auction-dashboard">
        
      <div className="auction-dashboard-header"></div>
      <div className="auction-dashboard-container">
        <div className="auction-dashboard-hot-metrics">
          <div className="auction-current-auction">
            <div className="auction-icons">
              <UpcomingAuctionsIcon1 />
            </div>
            <div className="auction-description">
              <div className="auction-total-number">{totalRunningauction}</div>
              <div className="auction-number-description">
                Lopende veilingen
              </div>
            </div>
          </div>
          <div className="auction-current-auction upcoming">
            <div className="auction-icons">
              <UpcomingAuctionIcon2 />
            </div>
            <div className="auction-description">
              <div className="auction-total-number">{totalReadyForAuction}</div>
              <div className="auction-number-description">
                Aankomende veilingen
              </div>
            </div>
          </div>
          <div className="auction-current-auction">
            <div className="auction-icons">
              <UpcomingAuctionsIcon3 />
            </div>
            <div className="auction-description">
              <div className="auction-total-addresswons">
                <select className="auction-dropdown" onChange={handleChange}>
                  <option
                    value={data.wonBidCount30}
                    className="optionvalueclass"
                  >
                    Laatste 30 dagen
                  </option>
                  <option
                    value={data.wonBidCount60}
                    className="optionvalueclass"
                  >
                    Laatste 60 dagen
                  </option>
                  <option
                    value={data.wonBidCount90}
                    className="optionvalueclass"
                  >
                    Laatste 90 dagen
                  </option>
                </select>
              </div>
              <div className="auction-total-number">
                {wonbid === true ? wonbid : data.wonBidCount30}
              </div>
              <div className="auction-number-description">
                Gewonnen biedingen
              </div>
            </div>
          </div>
          <div className="auction-current-auction">
            <div className="auction-icons">
              <UpcomingAuctionIcon2 />
            </div>
            <div className="auction-description">
              <div className="auction-total-number">
                {formatter.format(data.totalAmount)}
              </div>
              <div className="auction-number-description">
                Totaal uitstaande biedingen
              </div>
            </div>
          </div>
        </div>
        <div className="auction-dashboard-mybids">
          <div className="auction-header">
            <span className="auction-header-title">
              Biedingen die al snel sluiten
            </span>
            <span className="auction-header-subtitle">
              <Link to={`/MyBids`}>Toon alles</Link>
            </span>
          </div>
          <DashboardMybids />
        </div>
        <div className="auction-dashboard-metrics">
          <section className="auction-dashboard-metrics-header">
            {/* <span className="auction-heading">
              Hot Auctions
              </span> */}
            <span className="auction-heading">
              Lopende en aankomende veilingen
            </span>
          </section>
          {myAuctionStatus === 401 ||
          myAuctionStatus === 404 ||
          myAuctionStatus === 500 || !auctions.length ? (
            <div className="no-running-upcoming-auctions-parent-container">
              <div className="no-running-upcoming-auctions-container">
                <NorunningupcomingauctionIcon />
              </div>
              <span className="no-running-upcoming-auctions-container-span">
                Geen lopende of aanstaande veiling beschikbaar
              </span>
            </div>
          ) : (
            <section className="auction-dashboard-metrics-container">
              <AuctionCard auctions={auctions} />
            </section>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuctionDashboard;
