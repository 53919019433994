import { forwardRef, Fragment, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import "./update-a-bid.css";
import "../../../../atoms/UpdateButton/updatebutton.css";

/**
 * for minbid code
 */
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
/**
 * for minbid code ends
 */

const hostURL = process.env.REACT_APP_SERVER_URL;
/**
 * need to declare outside as a module scoped global variable,
 * else will get reset during rerender causing issue, else clearTimeout wont work
 *
 */

let setPopupWarningMessageToNullCancelToken = null;
let setErrorMessageToNullCancelToken = null;

export default function UpdateABid({
  bidId,
  bidAmount,
  minBid,
  bidStatus,
  bidInfoPage,
}) {
  const history = useHistory();
  const updateBidAmountRef = useRef();
  const getToken = localStorage.getItem("Token");
  let [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
    success: "success",
  });

  const [snackMessage, setSnackMessage] = useState("");
  const [bidcancelinfo, setCancelBidInfo] = useState([]);
  let [errorMessage, setErrorMessage] = useState(null);
  let [disableInputOptions, setDisableInputOptions] = useState(false);

  minBid = (!_.isUndefined(minBid) && !_.isNull(minBid) && minBid >0)  ? minBid :0;
  const minBidValue = !_.isEmpty(bidInfoPage?.minBid) ? bidInfoPage?.minBid :minBid;
  const minBidAmount = minBidValue !== undefined ? minBidValue : 0;
  let updateBidAmt = bidAmount ? parseInt(bidAmount, 10) : minBid;
  const [bidAmounts, setBidAmount] = useState(updateBidAmt);
  const [disableSaveButton, setDisableSaveButton] = useState(
    bidAmounts < minBidAmount
  );
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
  const minBidTitle = "Minimum bod :";
  const ErrorMessage = `Plaats een bod van minimaal ${formatter.format(
    minBidAmount
  )}`;

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /** To disable input based on bidStatus   */
  useEffect(() => {
    if(!_.isEmpty(bidInfoPage)){
      let disableStatus = isDisabledMode(bidInfoPage.bidStatus);
      setDisableInputOptions(disableStatus);
    }else{
      setDisableInputOptions(true);
    }
    
  }, []);
  const isDisabledMode = (bidStatus) => {
    switch (bidStatus) {
      /**Added same conditions existing in mybid scenario */
      case "Bid Placed":
        return false;
      case "AWAITING RESULTS":
        return true;
      case "AWAITING RESULTS-P":
        return true;
      case "Won":
        return true;
      case "Lost":
        return true;
      case "To be Confirmed":
        return true;
      case "Fail":
        return true;
      case "Failed":
        return true;
      default:
        return false;
    }
  };

  /** To disable input based on bidStatus ends   */

  const convertToNum = (textValue) => {
    if (!textValue || typeof textValue == "number") {
      return textValue;
    } else {
      let num = textValue.replace(/[^0-9]/g, "");
      if (num.length > 0) {
        return parseInt(num, 10);
      } else {
        return null;
      }
    }
  };

  const clearExistingTimeout = () => {
    if (setPopupWarningMessageToNullCancelToken) {
      clearTimeout(setPopupWarningMessageToNullCancelToken);
      setPopupWarningMessageToNullCancelToken = null;
    }
    if (setErrorMessageToNullCancelToken) {
      clearTimeout(setErrorMessageToNullCancelToken);
      setErrorMessageToNullCancelToken = null;
    }
  };

  /**
   * onchangeInput
   */
  const onHandleErrorChange = (e) => {
    /**
     * we have to use e.target.value
     * everywhere instead of saving in variable to resolve
     * wrong message issue due to setTimeout storing value
     */

    if (
      convertToNum(_.isEmpty(e.target) ? e : e.target.value) >=
      minBidAmount
    ) {
      clearExistingTimeout();
      setErrorMessage(null);
    }else if (
      convertToNum(_.isEmpty(e.target) ? e : e.target.value) < minBidAmount
    ) {
      clearExistingTimeout();
      setErrorMessage(ErrorMessage);
      setErrorMessageToNullCancelToken = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  };

  const onHandleChange = (updatedBidValue) => {
    let convertedBidValue = convertToNum(updatedBidValue);
    if (!convertedBidValue || convertedBidValue < minBidAmount) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }

    setBidAmount(convertedBidValue);
  };

  let incrementBidAmount = () => {
    let updatedValue = bidAmounts >= minBid ? Number(bidAmounts) + 50 : minBid;
    onHandleErrorChange(updatedValue);
    onHandleChange(updatedValue);
  };

  let decrementBidAmount = () => {
    let updatedValue = bidAmounts > minBid ? Number(bidAmounts) - 50 : minBid;
    onHandleErrorChange(updatedValue);
    onHandleChange(updatedValue);
  };
  const debouncedChangeHandler = _.debounce(onHandleErrorChange, 1000);

  /**
   * onchange input ends her
   */

  const handleSubmit = () => {
    setState({ open: true, success: "success" });
    setSnackMessage("Bid update is in progress...");
    const params = {
      BidId: `${bidId}`,
      caseTypeID: "BVE-DMP-BidMgmt-Work-BidMgmt",
      processID: "pyStartCase",
      content: {
        CaseData: {
          BidAmount: bidAmounts > minBid ? bidAmounts : minBid,
          minBid: parseInt(minBid),
          LastBidAmount: updateBidAmt,
          KeyIndicator: "Modify",
        },
      },
    };
    const requestOptionsvalue = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: JSON.stringify(params),
    };

    fetch(hostURL + `/api/bidmanager/Bids/Updatebid`, requestOptionsvalue)
      .then((response) => response.json())
      .then((data) => data);
    const timer = setTimeout(() => {
      setState({ open: false});
      setDisableSaveButton(true);
    }, 5000);
  };

  const handleClose = () => {
    setState({ open: false });
  };

  return (
    <Fragment>
      <div className="updateBidForm">
        {!disableInputOptions ? (
          <div
            className={
              disableInputOptions
                ? "full-container-update-bid disable"
                : "full-container-update-bid"
            }
          >
            <div className="update-save-button">
              <button
                className="dec-amt"
                onClick={decrementBidAmount}
                type="button"
                disabled={bidAmounts <= minBidAmount}
              >
                {" "}
                <div class="material-icons">remove</div>
              </button>
              <div
                id="input-block-border"
                className={`inputWraper ${
                  errorMessage == null
                    ? "warningInfoSection"
                    : "errorInfoSection"
                }`}
              >
                {errorMessage ? (
                  <Tooltip
                    open={errorMessage ? true : false}
                    arrow
                    classes={{
                      tooltip: "customUpdateTooltip",
                      arrow: "customUpdateToolTipArrow",
                    }}
                    placement="top"
                    title={errorMessage ? errorMessage : null}
                  >
                    <ReportProblemIcon />
                  </Tooltip>
                ) : null}
                <input
                  id="amount-input-validation"
                  ref={updateBidAmountRef}
                  className="bid-amount-container"
                  required
                  onChange={(e) => onHandleChange(e.target.value)}
                  onKeyUp={(e) => debouncedChangeHandler(e)}
                  value={formatter.format(bidAmounts)}
                  type="text"
                  disabled={disableInputOptions}
                ></input>
              </div>
              <button className="inc-amt" onClick={incrementBidAmount}>
                {" "}
                <div class="material-icons">add</div>
              </button>
              <Button
                className="update-btn-default-submit btn-bidday"
                disabled={disableSaveButton || disableInputOptions}
                onClick={() => handleSubmit()}
              >
              WIJZIGEN
              </Button>
            </div>           
          </div>
        ) : (
          <div className="full-container-update-bid disable" disabled>
            <button
              className="dec-amt"
              onClick={decrementBidAmount}
              type="button"
              disabled
            >
              {" "}
              <div class="material-icons">remove</div>
            </button>
            <div id="input-block-border">
              <input
                id="amount-input-validation"
                ref={updateBidAmountRef}
                className="bid-amount-container"
                required
                onChange={(e) => onHandleChange(e.target.value)}
                onKeyUp={(e) => debouncedChangeHandler(e)}
                value={formatter.format(bidAmounts)}
                type="text"
                disabled
              ></input>
            </div>
            <button className="inc-amt" onClick={incrementBidAmount} disabled>
              {" "}
              <div class="material-icons">add</div>
            </button>
            <Button
             className={
              disableInputOptions
                ? "update-btn-default-submit disable"
                : "update-btn-default-submit btn-bidday"
            }
              // className="update-btn-default-submit btn-bidday"
              onClick={() => handleSubmit()}
              disabled
            >
              WIJZIGEN
            </Button>            
          </div>
        )}

        <Snackbar
          open={state.open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          key={state.vertical + state.horizontal}
        >
          <Alert severity={state.success} sx={{ width: "100%" }}>
            {snackMessage}
          </Alert>
        </Snackbar>
      </div>
    </Fragment>
  );
}
