import React from 'react';

const testURL = process.env.REACT_APP_TESTAPI_URL;
function AuctionImage(props) {
  return (
    
    <div className="auction-car-body">
    {props.carLogos.map((logoURL, index) => {
      if(index<props.length-1){
        return (
          <img
            className="auction-car-body-logos"
            src={testURL + logoURL}
            alt="logo1"
            key={index}
          ></img>
        );
      } else if(index===props.length-1 && props.carLogos.length===props.length){
        return (
        
          <img
            className="auction-car-body-logos"
            src={testURL + logoURL}
            alt="logo1"
            key={index}
          ></img>
        );
      } else if(index===props.length-1 ){
        return (
          <div class="counter">
          <div
            className="auction-car-body-logos-black"
            key={index}
          ></div>
          <div class="centered">+{props.carLogos.length-props.length+1}</div>
          </div>
        );
      }return null;
      
    })}
  </div>
   
    );
   
}

export default AuctionImage;