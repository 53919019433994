import React from "react";

const SliderNextArrow = (props) => {
  const { height, width, color, content } = props;
  return (
    <React.Fragment>
    <div className="slick-next-icon">
      <svg
        width={width ? width : "24"}
        height={height ? height : "24"}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="next-arrow-image"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d={content? content : "M5.25709 0.263672L0.5 4.96367L15.9521 20.2637L0.5 35.5637L5.25709 40.2637L25.5 20.2637L5.25709 0.263672Z"}
          fill= {color ? color : "white"}
          fill-opacity="0.6"
        />
      </svg>
    </div>
    </React.Fragment>

  );
}
export default SliderNextArrow;
