import React from 'react';
import "./licencePlate.css";

function licencePlate(props) {
    return (
        <div className="licencePlate">
             <div className="licencePlate-contanier">
             <div className="licencePlate-blue"></div>
                <div className="licencePlate-yellow">
                    <span className="licencePlate-GY-W9">
                    {props.licencePlate}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default licencePlate;