import React from "react";
import "./waitingbidheader.css";
import "../style.css";
import WaitingIcon from "../../Icons/headerIcon/waitingIcon";
import BackButtonCarpassportHeaderIcon from '../../Icons/backButtonCarpassportHeaderIcon';
import waitingimage from "../../../../src/images/waitingimage.gif";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export default function WaitingBidHeader({
  bidAmount,vehiclesList,  isNavigationNeeded,isFromMyBid
  }) {
    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    });
    const history = useHistory();
  
    const backButtonOnclick = () => {
      if(isFromMyBid){
        let sortData;
        if(localStorage.getItem("sortData")){
          sortData = JSON.parse(localStorage.getItem("sortData"));
          localStorage.removeItem("sortData");
        }
        history.push({
          pathname:  "/mybids",
          state:{
            sortData,
            vehiclesList
          }
         
       });
      }else{
        const selectedUniqueVehicle = _.uniqBy(
          vehiclesList,
          "catalogID"
        );
        const catalogIDs = selectedUniqueVehicle.map((vehicle)=>vehicle.catalogID);
        history.push({
          pathname:  "/AvailableCars",
          state: {
            catalogIDs: catalogIDs,
            vehiclesList:vehiclesList
          } 
        });
      }
    }

  return (
    <React.Fragment>
       {isNavigationNeeded ? (
        <button
          className="carpassport-back-btn-header"
          onClick={() => backButtonOnclick()}
        >
          <BackButtonCarpassportHeaderIcon />
          <span className="carpassport-back-btn-title">Terug</span>
        </button>
      ) : null}
      <WaitingIcon />
      <div class="waiting-bid-header-col-second span_1_of_3">
        <div className="waitingFlex">
        <div className="carpassport-waiting-bid-header-form-label">
          <span className="carpassport-waiting-bid-header-field-label">
            Wacht op de uitslag
          </span>
        </div>
        {/* dd */}
        <div className="carpassport-waiting-bid-header-minimum-amount-container">
          <div className="carpassport-waiting-bid-header-minimum-amount-container-title-block">
            <span className="carpassport-waiting-bid-value">Jouw bod</span>
            <span className="carpassport-waiting-bid-rate">{formatter.format(bidAmount)}</span>
          </div>
          <div className="carpassport-waiting-bid-header-form-description">
            <span className="carpassport-waiting-bid-header-form-description-title">
              Koperskosten vanaf € 280,- + transportkosten vanaf € 99,-
            </span>
            <span className="carpassport-form-description-subtitle-infoicon"></span>
          </div>
        </div>
        </div>
      </div>
      <div className="waiting-bid-header-col-second-middle span_1_of_3">
        <div className="carpassport-waiting-bid-header-image-container">
          <img
            alt="waitingImage"
            src={waitingimage}
            className="header-waiting-image"
          ></img>
        </div>
      </div>
      <div className="waiting-bid-header-col-second-end span_1_of_3">
        <div className="carpassport-waiting-bid-header-title">
          <span className="carpassport-waiting-bid-second-value">
            Even geduld alsjeblieft{" "}
          </span>
        </div>
        <div className="waiting-bid-header-second-title-title">
          <div className="carpassport-waiting-bid-second-rate">
            De uitslag van de veiling wordt bijna
            <br />{" "}
            <span className="awaiting-result-bekendge"> bekendgemaakt</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
