import React from "react";
import "./filterIcon.css";

function FilterIcon(props) {
  return (
    <div className="filterIcons">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 2L8 5L11 2H5ZM15 2L12 5H18L15 2ZM3 7V9H4V9.0332L9 16.0332V22H15V16.0332L20 9.0332V9H21V7H3ZM6.43359 9H17.5664L13 15.3926V20H11V15.3926L6.43357 9H6.43359Z"
          fill="#90A0B7"
        />
      </svg>
    </div>
  );
}

export default FilterIcon;
