import React from "react";

function CarkmIcon(props) {
  return (
    <div style={{marginTop:"2px"}}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.83333 5C3.82225 5 3 5.82225 3 6.83333V17.8333C3 18.8444 3.82225 19.6667 4.83333 19.6667H19.5C20.5111 19.6667 21.3333 18.8444 21.3333 17.8333V6.83333C21.3333 5.82225 20.5111 5 19.5 5H4.83333ZM4.83335 6.83331H19.5L19.5018 17.8333H4.83331V6.83331H4.83335ZM5.75 16V8.66669H7.125V16H5.75ZM9.41667 8.66669C8.91067 8.66669 8.5 9.07736 8.5 9.58336V15.0834C8.5 15.5894 8.91067 16 9.41667 16H12.1667C12.6727 16 13.0833 15.5894 13.0833 15.0834V9.58336C13.0833 9.07736 12.6727 8.66669 12.1667 8.66669H9.41667ZM14 9.58336C14 9.07736 14.4107 8.66669 14.9167 8.66669H17.6667C18.1727 8.66669 18.5833 9.07736 18.5833 9.58336V15.0834C18.5833 15.5894 18.1727 16 17.6667 16H14.9167C14.4107 16 14 15.5894 14 15.0834V9.58336ZM9.875 10.0417H11.7083V14.625H9.875V10.0417ZM17.2083 10.0417H15.375V14.625H17.2083V10.0417Z"
          fill="#C6CBD4"
        />
      </svg>
    </div>
  );
}

export default CarkmIcon;
