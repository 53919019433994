import React from "react";

export default function Seconds({ hours, seconds, flag }) {
  const flagProps = flag;

  if (hours > 2 && hours > 0) {
    flag = !flag;
  }

  const secondsSplit = seconds.split("");

  if (secondsSplit[1] == null) {
    secondsSplit[1] = secondsSplit[0];
    secondsSplit[0] = 0;
  }

  return (
    <>
      <div className="seconds-section" data-testid = "seconds-section">
        <div className="seconds-container">
          <div
            className={
              hours >= 2 && hours >= 0 ? "hours-card-48" : "hours-card-2"
            }
          >
            <span className="minutes-tens-block">
              {secondsSplit[0] >= 0 ? secondsSplit[0] : null}
            </span>
          </div>

          <div
            className={
              hours >= 2 && hours >= 0 ? "hours-card-48" : "hours-card-2"
            }
          >
            <span className="minutes-tens-block">
              {secondsSplit[0] >= 0 ? secondsSplit[1] : null}
            </span>
          </div>
        </div>
        <span className="seconds-title">Sec</span>
      </div>
    </>
  );
}
