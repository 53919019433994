import React from 'react';

function TransportationContainer(props) {
    return (
        <div>
        <div className="quoteBreakdownComponent-header">
            <h4>Stap 3. Transport </h4>
        </div>
        <div className="resistrationContainer-report-detail">
        Na ontvangst van de betaling en de bevestiging vanuit de RDW dat je de auto in je bedrijfsvoorraad hebt opgenomen, wordt transport gepland. De auto wordt binnen 5 werkdagen afgeleverd op je locatie. Van onze transporteur ontvang je een transportbevestiging. 
        </div>
        </div>
    );
}

export default TransportationContainer;