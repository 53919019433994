import React, { useEffect, useState } from "react";
import moment from "moment";
import dateFormat from "dateformat";
import _ from "lodash";

import UpdateABid from "./BidForm/update-a-bid";
import InfoIcon from "../../Icons/infoIcon";
import StopWatchIcon from "../../Icons/stopwatchIcon";
import RunningIcon from "../../Icons/headerIcon/runningIcon";
import BackButtonCarpassportHeaderIcon from "../../Icons/backButtonCarpassportHeaderIcon";

import "./updateHeader.css";
import { Popover } from "antd";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import PopoverPopupState from "./popoverPopupState";
import { Typography } from "@mui/material";

function UpdateHeader({
  accesstoken,
  userid,
  catalogID,
  lotID,
  bidAmount,
  bidId,
  bidStatus,
  openForBidding,
  startBiddingDate,
  startBiddinghours,
  openForBiddingminute,
  enddayofbidding,
  updateSelectedVehicleId,
  minBid,
  bidInfoPage,
  vehiclesList,
  isNavigationNeeded,
  isFromMyBid
}) {

  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  let [visible, setVisible] = useState(false);
  let [flag, setFlag] = useState(false);
  minBid =
    !_.isUndefined(minBid) && !_.isNull(minBid) && minBid > 0 ? minBid : 0;

  const splittedTimer =
    openForBidding || openForBidding === "undefined"
      ? openForBidding.split("/(?=[A-Z])/")
      : null;
  const startDate = splittedTimer && dateFormat(splittedTimer[0], "dd-mm-yyyy");

  let now = moment(new Date());

  let end = moment(new Date(enddayofbidding));

  let remainingdays = moment.duration(end.diff(now)).days();
  let totalhoursleft = remainingdays * 24;

  let diff = moment.duration(end.diff(now));

  let remainingtimehours = diff.asHours();
  let remainingtimeminutes = diff.minutes();
  let remainingtimesecondss = diff.seconds();

  let hours = remainingtimehours.toFixed(0);
  let minutes = remainingtimeminutes;
  let seconds = remainingtimesecondss;

  if (totalhoursleft < 0 && hours < 0) {
    hours = "000";
    minutes = "00";
    seconds = "00";
  }

  const content = (
    <div>
      <div
        style={{
          fontSize: "13px",
          textAlign: "center",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <p className="popover-place-bid-message-text">
          {" "}
          Bod is altijd incl btw wanneer van toepassing.
        </p>

        <div style={{ marginTop: "6px", fontSize: "8px" }}>
          <p className="popover-content-text-info">
            {" "}
            Biedingsbedrag(incl btw){" "}
            <span className="popover-text-value1">Kostenkoper (excl btw) </span>
          </p>
          <p className="popover-content-text-info-second">
            € 0,00 en € 5.000,-
            <span className="popover-text-value-second1"> € 280,- </span>
          </p>
          <p className="popover-content-text-info-third">
            € 5.001,- en € 10.000,-
            <span className="popover-text-value-second2">€ 330,- </span>
          </p>
          <p className="popover-content-text-info-fourth">
            € 10.001,- en € 15.000,-
            <span className="popover-text-value-second3">€ 405,- </span>
          </p>
          <p className="popover-content-text-info-fourth">
            € 15.001,- en € 20.000,-
            <span className="popover-text-value-second4">€ 455,- </span>
          </p>
          <p className="popover-content-text-info-fourth">
            Meer dan € 20.001,-
            <span className="popover-text-value-second5">€ 530,- </span>
          </p>
          <hr />
          <p className="popover-content-text-info">
            Transportkosten (excl btw)
          </p>
          <p className="pocket-popover">
            <span>Personenauto</span>
            <span> € 99,-</span>
          </p>
          <p className="pocket-popover">
            <span>Kleine bedrijfswagen</span>
            <span> € 109,-</span>
          </p>
          <p className="pocket-popover">
            <span>Grote bedrijfswagen</span>
            <span> € 159,-</span>
          </p>

          <p className="pocket-popover" style={{ paddingBottom: "24px" }}>
            Overige maten op basis van calculatie
          </p>
        </div>
      </div>
    </div>
  );
  const handleVisibleChangeUpdate = () => {
    setVisible(!visible);
  };

  let interval;
  let start = moment(new Date(openForBidding));
  let startAuctiondateminutes = start.minutes();
  let closeAuctiondateminutes = end.minutes();

  const startTimer = () => {
    let countDownDate = new Date(enddayofbidding).getTime();

    interval = setInterval(() => {
      let now = moment(new Date().getTime());

      const distance = countDownDate - now;
      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        var remainingtimesecondss = Math.floor(distance / 1000);
        var remainingtimeminutes = Math.floor(remainingtimesecondss / 60);
        var remainingtimehours = Math.floor(remainingtimeminutes / 60);

        var days = Math.floor(remainingtimehours / 24);

        remainingtimeminutes %= 60;
        remainingtimesecondss %= 60;

        setTimerHours(remainingtimehours);
        setTimerMinutes(remainingtimeminutes);
        setTimerSeconds(remainingtimesecondss);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
  });

  useEffect(() => {
    if (totalhoursleft && totalhoursleft >= 2) {
      setFlag(!flag);
    }
  }, []);

  let hourss = timerHours !== undefined ? timerHours.toString() : "";
  const hoursSplit = hourss.split("");
  if (hoursSplit[1] == null) {
    hoursSplit[1] = hoursSplit[0];
    hoursSplit[0] = 0;
  }

  let minutess = timerMinutes !== undefined ? timerMinutes.toString() : "";
  let minutesSplit = minutess.split("");
  if (minutesSplit[1] == null) {
    minutesSplit[1] = minutesSplit[0];
    minutesSplit[0] = 0;
  }

  let minutesTensProps = minutesSplit && minutesSplit[0];
  let minutesUnitsProps = minutesSplit && minutesSplit[1];
  let secondss = timerSeconds !== undefined ? timerSeconds.toString() : "";

  const secondsSplit = secondss.split("");
  if (secondsSplit[1] == null) {
    secondsSplit[1] = secondsSplit[0];
    secondsSplit[0] = 0;
  }
  const history = useHistory();

  const backButtonOnclick = () => {
    if(isFromMyBid){
      let sortData;
      if(localStorage.getItem("sortData")){
        sortData = JSON.parse(localStorage.getItem("sortData"));
        localStorage.removeItem("sortData");
      }
      history.push({
        pathname:  "/mybids",
        state:{
          sortData,
          vehiclesList
        }
        
      });
    }else{
      const selectedUniqueVehicle = _.uniqBy(
        vehiclesList,
        "catalogID"
      );
      const catalogIDs = selectedUniqueVehicle.map((vehicle)=>vehicle.catalogID);
      
      history.push({
        pathname:  "/AvailableCars",
        state: {
          catalogIDs: catalogIDs,
          vehiclesList:vehiclesList
        } 
     });

    }
  
  }
  return (
    <React.Fragment>
      {isNavigationNeeded ? (
        <button
          className="carpassport-back-btn-header"
          onClick={() => backButtonOnclick()}
        >
          <BackButtonCarpassportHeaderIcon />
          <span className="carpassport-back-btn-title">Terug</span>
        </button>
      ) : null}
      <RunningIcon />
      <div class="col-second span_1_of_3-update">
        {/* <div className="carpassport-form-label-update">
          <span className="field-label-update">UPDATE JOUW BOD</span>
        </div> */}
        <div className="carpassport-input-form-update">
          {/* <div className="carpassport-form-input-update">
          <span className="place-a-bid-text">UPDATE JOUW BOD</span>
          <span className="carpassport-form-popover-description-subtitle"> <PopoverPopupState/></span>
            <UpdateABid
              updateSelectedVehicleId={updateSelectedVehicleId}
              bidAmount={bidAmount}
              catalogID={catalogID}
              accesstoken={accesstoken}
              userid={userid}
              lotID={lotID}
              bidStatus={bidStatus}
              OpenForBidding={openForBidding}
              bidId={bidId}
              minBid={minBid}
              bidInfoPage={bidInfoPage}
            />
          </div> */}
        <div className="title_info_update_button">
        <span className="place-a-bid-title"> WIJZIG JE BOD </span>
         <span className="carpassport-form-description-subtitle-update"> <PopoverPopupState/></span>

        </div>
      
      <div className="minBidForm">
      <UpdateABid
              updateSelectedVehicleId={updateSelectedVehicleId}
              bidAmount={bidAmount}
              catalogID={catalogID}
              accesstoken={accesstoken}
              userid={userid}
              lotID={lotID}
              bidStatus={bidStatus}
              OpenForBidding={openForBidding}
              bidId={ _.isUndefined(bidId) ? bidInfoPage.bidId : bidId}
              minBid={minBid}
              bidInfoPage={bidInfoPage}
            />
      </div>
          <div className="carpassport-form-description-update">
            <span className="carpassport-form-description-title-update">
              Koperskosten vanaf € 280,- + transportkosten vanaf € 99,-
            </span>
         
          </div>
        </div>
      </div>

      <div class="col-second-middle-update span_1_of_3">
        <div className="carpassport-timer-label-update">
          <StopWatchIcon width="55px" height="51px" color="#fff" />
        </div>
        <div className="carpassport-timer-start-update">
          <span className="carpassport-start-date-title-update">
            Startdatum en tijd
          </span>
          <div className="carpassport-start-date-update">
            {dateFormat(openForBidding ? openForBidding : bidInfoPage.auctionStartDtTime, "dd-mm-yy")}{" "}
            {dateFormat(openForBidding ? openForBidding : bidInfoPage.auctionStartDtTime, "HH")}:{openForBidding ? dateFormat(openForBidding, 'MM'):dateFormat(bidInfoPage.auctionStartDtTime, "MM")}
          </div>
        </div>
      </div>
      <div class="col-second-end span_1_of_3-update">
        <div className="carpassport-timer-label-update">
          <div className="carpassport-body-hours-container-update">
            {hours >= 2 && hours >= 0 && hoursSplit[0] >= 0 ? (
              <div
                className={
                  hours >= 2 && hours >= 0 && hoursSplit[0] >= 0
                    ? "carpassport-body-hours-card-48-update"
                    : "carpassport-body-hours-card-2-update"
                }
              >
                <span className="carpassport-body-block-update">
                  {hoursSplit[0] >= 0 ? hoursSplit[0] : null}
                </span>
              </div>
            ) : (
              <div className="hours-card">
                <div
                  className={
                    hours < 2 && hours >= 0 && hoursSplit[0] >= 0
                      ? "carpassport-body-hours-card-2-update"
                      : "carpassport-body-hours-card-48-update"
                  }
                >
                  <span className="carpassport-body-block-update">
                    {hoursSplit[0] >= 0 ? hoursSplit[0] : null}
                  </span>
                </div>
              </div>
            )}
            {hours >= 2 && hours >= 0 && hoursSplit[1] >= 0 ? (
              <div
                className={
                  hours >= 2 && hours >= 0 && hoursSplit[0] >= 0
                    ? "carpassport-body-hours-card-48-update"
                    : "carpassport-body-hours-card-2-update"
                }
              >
                <span className="carpassport-body-block-update">
                  {hoursSplit[1] >= 0 ? hoursSplit[1] : null}
                </span>
              </div>
            ) : (
              <div className="hours-card">
                <div
                  className={
                    hours < 2 && hours >= 0 && hoursSplit[0] >= 0
                      ? "carpassport-body-hours-card-2-update"
                      : "carpassport-body-hours-card-48-update"
                  }
                >
                  <span className="carpassport-body-block-update">
                    {hoursSplit[1] >= 0 ? hoursSplit[1] : "0"}
                  </span>
                </div>
              </div>
            )}

            {hours >= 2 && hours >= 0 && hoursSplit[2] >= 0 ? (
              <div
                className={
                  hours >= 2 && hours >= 0 && hoursSplit[2] >= 0
                    ? "carpassport-body-hours-card-48-update"
                    : "carpassport-body-hours-card-2-update"
                }
              >
                <span className="carpassport-body-block-update">
                  {hoursSplit[2] >= 0 ? hoursSplit[2] : null}
                </span>
              </div>
            ) : null}
          </div>
          <span className="carpassport-body-hours-title-update">Uren</span>
        </div>

        <span className="carpassport-body-timer-colon-update">:</span>
        <div className="carpassport-timer-label">
          <div className="carpassport-body-hours-container">
            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48-update"
                  : "carpassport-body-hours-card-2-update"
              }
            >
              <span className="carpassport-body-block-update">
                {minutesTensProps >= 0 ? minutesTensProps : "0"}
              </span>
            </div>

            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48-update"
                  : "carpassport-body-hours-card-2-update"
              }
            >
              <span className="carpassport-body-block-update">
                {minutesUnitsProps > 0 ? minutesUnitsProps : "0"}
              </span>
            </div>
          </div>
          <span className="carpassport-body-hours-title-update">Minuten</span>
        </div>
        <span className="carpassport-body-timer-colon-update">:</span>
        <div className="carpassport-timer-label-update">
          <div className="carpassport-body-hours-container-update">
            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48-update"
                  : "carpassport-body-hours-card-2-update"
              }
            >
              <span className="carpassport-body-block-update">
                {secondsSplit[0] > 0 ? secondsSplit[0] : "0"}
              </span>
            </div>

            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48-update"
                  : "carpassport-body-hours-card-2-update"
              }
            >
              <span className="carpassport-body-block-update">
              {secondsSplit[0] > 0 ? secondsSplit[1] : "0"}
              </span>
            </div>
          </div>

          <span className="carpassport-body-hours-title-update">Seconden</span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UpdateHeader;
