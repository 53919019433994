import React from "react";

function CheckedIcon() {
  return (
    <div className="checkedbox-icon">
      <svg
        width="12"
        height="12"
        viewBox="0 0 10 10"
        fill="#2A69B2"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="12" height="12" fill="#2A69B2" />
      </svg>
    </div>
  );
}

export default CheckedIcon;
