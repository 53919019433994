import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import "./AuctionCard.css";
import RoundBlankCheckbox from "../../Icons/roundBlankCheckbox";
import dateFormat from "dateformat";
import _ from "lodash";
import InconsiderationAuctionComponent from "./inconsiderationAuction";
import AuctionImage from "../../../shared/AuctionImage/auctionImage";
import DashboardAuctionTimer from "../../../atoms/ProgressBar/dashboard-auctionTimer";

function AuctionCard({ auctions }) {
  const history = useHistory();

  const checkboxAuctionRunningRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const navigationToCars = (dataAuction, e) => {
    history.push({ pathname: `/AvailableCars`, dataAuction });
  };

  const filterRunningCarByCatalog = (auction) => {
    const carLogo = [];

    if (auction.filteredVehicles && !_.isEmpty(auction.filteredVehicles)) {
      auction.filteredVehicles.forEach((vechicle) => {
        if (vechicle.lotStatus === "OpenForBidding") {
          carLogo.push(vechicle?.itemInfo?.medias[0]?.url);
        }
        if (vechicle.lotStatus === "Closed") {
          carLogo.push(vechicle?.itemInfo?.medias[0]?.url);
        }
      });
    }

    return carLogo;
  };
  const filterUpcommingByCatalog = (auction) => {
    const carLogo = [];
    if (auction.filteredVehicles && !_.isEmpty(auction.filteredVehicles)) {
      auction.filteredVehicles.forEach((vechicle) => {
        if (vechicle.lotStatus === "Published") {
          carLogo.push(vechicle?.itemInfo?.medias[0]?.url);
        }
      });
    }

    return carLogo;
  };

  return (
    <React.Fragment>
      {auctions.map((auctionapi) => {
        const dayLeftOfBidding = new Date(
          auctionapi?.auctionParameters?.closedForBidding
        );

        let now = moment(new Date());
        let end = moment(auctionapi?.auctionParameters?.closedForBidding);

        let start = moment(auctionapi?.openForBidding);
        let diff = moment.duration(end.diff(now));
        let bidStartDate = moment.duration(start.diff(now)).asHours();

        let remainingdays = diff.days();
        let totalhoursleft = remainingdays * 24;
        let remaininghour = diff.asHours();
        let remainingminutes = diff.minutes();
        let remainingtimesecondss = diff.seconds();

        const handleMouseMove = () => {
          setIsHovered(!isHovered);
        };
// console.log(auctionapi);
        return (
          <React.Fragment>
            {remaininghour > 0 && auctionapi.status !== "InConsideration"
              ? runningupcomingAuction()
              : null}

            {auctionapi.status === "InConsideration" && (
              <InconsiderationAuctionComponent
                end={end}
                start={start}
                checkboxAuctionRunningRef={checkboxAuctionRunningRef}
                isHovered={isHovered}
                filterRunningCarByCatalog={filterRunningCarByCatalog}
                auctionapi={auctionapi}
              ></InconsiderationAuctionComponent>
            )}
          </React.Fragment>
        );

        function runningupcomingAuction() {
          return (
            <div
              className={
                bidStartDate > 0
                  ? "auction-dashboard-metrics-card upcoming"
                  : "auction-dashboard-metrics-card running"
              }
              onClick={navigationToCars.bind(null, auctionapi.id)}
              onMouseEnter={handleMouseMove}
              onMouseLeave={handleMouseMove}
            >
              <div className="auction-dashboard-hot-metrics-header">
                {isHovered && (
                  <RoundBlankCheckbox
                    id="auctionControl"
                    checkboxRef={checkboxAuctionRunningRef}
                    className="non-checked"
                  />
                )}
                <div className="auction-dashboard-header-vehicle-name">
                  <span className="auction-vehicle-name-heading">Veiling</span>
                  <span className="auction-vehicle-name-subheading">
                    {auctionapi?.profile?.name}
                  </span>
                </div>
                <div className="auction-dashboard-header-start-date">
                  <span className="auction-vehicle-name-heading">
                    Startdatum/tijd
                  </span>
                  <span className="auction-vehicle-name-subheading">
                    <span>
                      {dateFormat(auctionapi.openForBidding, "dd-mm-yy")}
                    </span>
                    <span>
                      {auctionapi.openForBidding ||
                      auctionapi.openForBidding === "undefined"
                        ? dateFormat(auctionapi.openForBidding, "HH")
                        : null}
                      :
                      {auctionapi.openForBidding ||
                      auctionapi.openForBidding === "undefined"
                        ? 
                          `${dateFormat(start, "MM")}`
                        : null}
                    </span>
                  </span>
                </div>
                <div className="auction-dashboard-header-end-date">
                  <span className="auction-vehicle-name-heading">
                    Einddatum/tijd
                  </span>
                  <span className="auction-vehicle-name-subheading">
                    <span>
                      {dateFormat(
                        auctionapi?.auctionParameters?.closedForBidding,
                        "dd-mm-yy"
                      )}
                    </span>
                    <span>
                      {dateFormat(
                        auctionapi?.auctionParameters?.closedForBidding,
                        "HH"
                      )}
                      :{dateFormat(end, "MM")}
                    </span>
                  </span>
                </div>
              </div>
              <div className="auction-dashboard-hot-metrics-description">
                <div className="auction-dashboard-description-title">
                  Omschrijving
                </div>
                <div className="auction-dashboard-description-subtitle">
                  {auctionapi?.description !== "string" ? (
                    auctionapi.description
                  ) : (
                    <span>
                      Lorem Ipsum is gewoon een proeftekst van de drukkerij- en
                      zetindustrie.
                    </span>
                  )}
                </div>
              </div>
              <div className="auction-dashboard-hot-metrics-lots-image">
                <AuctionImage
                  length={5}
                  carLogos={
                    auctionapi.status === "OpenForBidding"
                      ? filterRunningCarByCatalog(auctionapi)
                      : auctionapi.status === "Published" &&
                        filterUpcommingByCatalog(auctionapi)
                  }
                />

                {bidStartDate > 0 ? (
                  <div>
                    <span className="auction-status-date">Aankomende</span>
                  </div>
                ) : null}
              </div>
              <div className="auction-dashboard-hot-metrics-timer">
                {bidStartDate < 0 ? (
                  <DashboardAuctionTimer
                    openForBidding={auctions.openForBidding}
                    enddayofbidding={dayLeftOfBidding}
                    remainingtimehours={remaininghour}
                    remainingtimeminutes={remainingminutes}
                    remainingtimesecondss={remainingtimesecondss}
                    totalhoursleft={totalhoursleft}
                  />
                ) : null}
              </div>
            </div>
          );
        }
      })}
    </React.Fragment>
  );
}

export default AuctionCard;
