import React from "react";
import "./lostheader.css";
import "../style.css";
import LostBidIcon from "../../Icons/headerIcon/lostBidIcon";
import BackButtonCarpassportHeaderIcon from "../../Icons/backButtonCarpassportHeaderIcon";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

export default function LostHeader({ bidAmount ,  vehiclesList,isNavigationNeeded,isFromMyBid}) {
  const history = useHistory();
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });

  const backButtonOnclick = () => {
    if(isFromMyBid){
      let sortData;
      if(localStorage.getItem("sortData")){
        sortData = JSON.parse(localStorage.getItem("sortData"));
        localStorage.removeItem("sortData");
      }
      history.push({
        pathname:  "/mybids",
        state:{
          sortData,
          vehiclesList
        }
       
     });
    }else{
      const selectedUniqueVehicle = _.uniqBy(
        vehiclesList,
        "catalogID"
      );
      const catalogIDs = selectedUniqueVehicle.map((vehicle)=>vehicle.catalogID);
      
      history.push({
        pathname:  "/AvailableCars",
        state: {
          catalogIDs: catalogIDs,
          vehiclesList:vehiclesList
        } 
      });
    }
  }

  return (
    <React.Fragment>
      {isNavigationNeeded ?
        <button
          className="carpassport-back-btn-header"
          onClick={()=>backButtonOnclick()}
        >
          <BackButtonCarpassportHeaderIcon />
          <span className="carpassport-back-btn-title">Terug</span>
        </button>:null}
      <LostBidIcon />
      {/* <div className = "carpassport-upcoming-bid-header-container"> */}
      <div class="lost-bid-header-col-second span_1_of_3">
        <div className="carpassport-lost-bid-header-form-label">
          <span className="carpassport-lost-bid-header-field-label">
            Je hebt de bieding niet gewonnen
          </span>
        </div>
        
      </div>
      <div className="lost-bid-header-col-second-middle span_1_of_3">
      <div className="carpassport-lost-bid-header-minimum-amount-container">
          <div className="carpassport-lost-bid-header-minimum-amount-container-title-block">
            <span className="carpassport-lost-bid-value">
              Jouw bod: {formatter.format(bidAmount)}
            </span>
            <span className="carpassport-lost-bid-rate"></span>
          </div>
          {/* <div className="carpassport-lost-bid-header-form-description">
              <span className="carpassport-lost-bid-header-form-description-title">
             Koperskosten vanaf € 280,- + transportkosten vanaf € 99,-
              </span>
            
            </div> */}
        </div>
        {/* <div className="carpassport-lost-bid-second-header-minimum-amount-container-title-block">
              <span className="carpassport-lost-bid-second-value">Einduitslag</span>
              <span className="carpassport-lost-bid-second-rate">Je bent geëindigd op plek #2</span>
            </div> */}
      </div>
      <div className="carpassport-lost-bid-btn-block">
        <Link to={`/AvailableCars`}>
          {" "}
          <div className="carpassport-lost-bid-btn-btn-block">
            GA NAAR BESCHIKBARE VOERTUIGEN
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
}
