import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

import "./SpecificatiesContainer.css";
import _ from "lodash";

function SpecificatiesContainer(props) {
  const locationHistory = window.location.search;
  let brandstof =
    props?.brandstof === "Unknown"
      ? "Onbekend"
      : props?.brandstof === "Diesel"
      ? "Diesel"
      : props?.brandstof === "Electric"
      ? "Elektrisch"
      : props?.brandstof === "Electricgasoline"
      ? "Elektrisch/benzine"
      : props?.brandstof === "Electricdiesel"
      ? "Elektrisch/diesel"
      : props?.brandstof === "CNG"
      ? "Aardgas"
      : props?.brandstof === "Gasoline"
      ? "Benzine"
      : props?.brandstof === "LPG"
      ? "LPG"
      : props?.brandstof === "Other"
      ? "Onbekend"
      : props?.brandstof === "Ethanol"
      ? "Ethanol"
      : "Hydrogen";

      let exteriorcolor =
    props?.exteriorColor === "Unknown"
      ? "Onbekend"
      : props?.exteriorColor === "Blue"
      ? "Blauw"
      : props?.exteriorColor === "Red"
      ? "Rood"
      : props?.exteriorColor === "White"
      ? "Wit"
      : props?.exteriorColor === "Black"
      ? "Zwart"
      : props?.exteriorColor === "Green"
      ? "Groen"
      : props?.exteriorColor === "Yellow"
      ? "Geel"
      : props?.exteriorColor === "Gray"
      ? "Grijs"
      : props?.exteriorColor === "Orange"
      ? "Oranje"
      : props?.exteriorColor === "Purple"
      ? "Paars"
      : props?.exteriorColor === "Pink"
      ? "Rose"
      :props?.exteriorColor === "Brown"
      ? "Bruin"
      :"-";

      let transmission = props?.transmission === "Other"
      ? "Onbekend"
      : props?.transmission === "Manual"
      ? "Handgeschakeld"
      : props?.transmission === "Automatic"
      ? "Automaat"
      : props?.transmission === "Semiautomatic"
      ? "Semiautomatic" : "-"

      let coachType =
    props?.coachType === "Unknown"
      ? "Onbekend"
      : props?.coachType === "Other"
      ? "Onbekend"
      : props?.coachType === "Hatchback"
      ? "Hatchback"
      : props?.coachType === "Cabrio"
      ? "Cabrio"
      : props?.coachType === "Coupe"
      ? "Coupe"
      : props?.coachType === "SUV"
      ? "SUV"
      : props?.coachType === "Sedan"
      ? "Sedan"
      : props?.coachType === "Stationwagon"
      ? "Stationwagon"
      : props?.coachType === "Van"
      ? "Van"
      : props?.coachType === "MPV"
      ? "MPV"
      :"-";

      let driveType = props?.driveType;

      let vehicletype = props?.vehicleType === "Other"
      ? "Onbekend"
      : props?.vehicleType === "PersonsVehicle"
      ? "Personenwagen"
      : props?.vehicleType === "Frontwheeldrive"
      ? "Frontwheeldrive"
      : props?.vehicleType === "Rearwheeldrive"
      ? "Rearwheeldrive"
      
      : props?.vehicleType === "Allwheeldrive"
      ? "Allwheeldrive"
     : "-"

    //  Frontwheeldrive, Rearwheeldrive, Allwheeldrive, Other
      const mileageFormatter = new Intl.NumberFormat('nl-NL',  {
        // style: 'unit',
        unit: 'kilometer'
    })
      
  return (
    <React.Fragment>
      <div className="specificaties-container-title">
        <h4>Specificaties</h4>
        {/* <p className="specifiaties-container-title-right">
          <Link to={`/MyBids${locationHistory}`}>Toon alles</Link>
        </p> */}
      </div>

      <div className="specificaties-container-body-block">
        <div className="specificaties-container-body-columns">
          {/* 1 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Kenteken
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(props?.kenteken)
                ? props?.kenteken
                : "-"}
            </span>
          </div>
          {/* 2 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Merk
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(props?.merk) ? props?.merk : "-"}
            </span>
          </div>
          {/* 3 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Model
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(props?.model) ? props?.model : "-"}
            </span>
          </div>
          {/* 4 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Uitvoering
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(props?.modelType)
                ? props?.modelType
                : "-"}
            </span>
          </div>
          {/* 5 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
            Carrosserie
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(coachType)
                ? coachType
                : "-"}
            </span>
          </div>
          {/* 6 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Bouwjaar
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.bouwjaar)
                ? "-"
                : dateFormat(props.bouwjaar, "yyyy")}
            </span>
          </div>
          {/* 7 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Kilometerstand
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {props?.mileage
                ? mileageFormatter.format(props.mileage)
                : "-"}
                {/* {props?.mileageUnits !==  undefined || null
                ? props?.mileageUnits 
                : " "} */}
            </span>
          </div>
          {/* 8 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Brandstof
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(brandstof)
                ? brandstof
                : "-"}
            </span>
          </div>
          {/* 9 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Transmissie
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(transmission)
                ? transmission
                : "-"}
            </span>
          </div>
          {/* 10 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Chassisnummer
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(props?.vinNumber)
                ? props.vinNumber
                : "-"}
            </span>
          </div>
          {/* 11 */}
          {/* Last block  */}
          <div className="specificaties-container-body-columns-info-last-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Aantal sleutels
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props?.numberOfKeys)
                ? props?.numberOfKeys
                : "-"}
            </span>
          </div>
        </div>

        <div className="specificaties-container-body-columns">
          {/* 12 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Type voertuig
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(vehicletype)
                ? vehicletype
                : "-"}
            </span>
          </div>
          {/* 13 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Exterieur kleur
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
             
             {!_.isEmpty(exteriorcolor)
                ? exteriorcolor
                : "-"} 
            </span>
          </div>
          {/* 14 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Aantal deuren
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.doors) ? props.doors : "-"}
            </span>
          </div>
          {/* 15 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Energielabel
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(props.energyLabel)
                ? props.energyLabel
                : "-"}
            </span>
          </div>
          {/* 16 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              CO2-uitstoot
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.cvoCo2) ? props?.cvoCo2 : "-"}
            </span>
          </div>
          {/* 17 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">Aandrijving</span>
            <span className="specificaties-container-body-columns-info-block-value">
              {!_.isEmpty(driveType)
                ? driveType
                : "-"}
            </span>
          </div>
          {/* 18 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Aantal versnellingen
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.numberOfGears)
                ? props.numberOfGears
                : "-"}
            </span>
          </div>
          {/* 19*/}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Motor vermogen (kW)
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.horsePower)
                ? props.horsePower
                : "-"}
            </span>
          </div>

          {/* 20 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Aantal cilinders
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.cylinders)
                ? props.cylinders
                : "-"}
            </span>
          </div>

          {/* 21 */}
          <div className="specificaties-container-body-columns-info-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Cilinderinhoud (cm3)
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.displacement)
                ? props.displacement
                : "-"}
            </span>
          </div>

          {/* 22 */}
          <div className="specificaties-container-body-columns-info-last-block">
            <span className="specificaties-container-body-columns-info-block-title">
              Koppel (Nm)
            </span>
            <span className="specificaties-container-body-columns-info-block-value">
              {_.isEmpty(props.torque) ? props?.torque : "-"}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SpecificatiesContainer;
