import React from "react";

function BackButtonCarpassportHeaderIcon() {
  return (
    <div className="carpassport-back-button-icon-div">
      <svg
        width="18"
        height="18"
        style={{ margin: "7px 4px 7px 8px" }}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.1324 2.90172L11.7974 1.57422L4.37988 8.99922L11.8049 16.4242L13.1324 15.0967L7.03488 8.99922L13.1324 2.90172Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default BackButtonCarpassportHeaderIcon;

// styleProp = {{width:"18px",height:"18px",fill:"#ffffff",top:"0px",margin:"7px 4px 7px 8px",position:"unset"}}
