import React, { useState } from "react";
import { Modal } from "antd";
import "./specificationBlock.css";
import "../CarPassportBody/CarpassportCarousal/carpassportcarousal.css";

import CarpassportCarousal from "../CarPassportBody/CarpassportCarousal/CarpassportCarousal";

function SpecificationBlock(props) {
  let noDataForField = "N/A";
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <React.Fragment>
      <div>
        <CarpassportCarousal
          mediaProp={props?.medias}
          className="specification"
          showModal={showModal}
          modelType={props?.modelType}
          isModalVisible = {isModalVisible}
        />
      </div>
      <div className="modal-container">
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer = {false}
          width={"55%"}
          data-keyboard="false"
        >
          <div className="carpassport-modal-container">
          <CarpassportCarousal
            mediaProp={props?.medias}
            modelType={props?.modelType}
            isModalVisible={isModalVisible}
            showModal={showModal}
          />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default SpecificationBlock;
