import React from "react";

function CarFuelIcon(props) {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0354 2.66699C8.04976 2.66699 6.41414 4.30261 6.41414 6.2882V26.8084H4V29.2225H23.3131V26.8084H20.899V23.1872H23.3131C25.2987 23.1872 26.9343 21.5516 26.9343 19.566V10.6167L22.9595 6.64184L21.2526 8.34871L23.3131 10.4092V12.3236C23.3131 12.9899 23.8539 13.5306 24.5202 13.5306V19.566C24.5202 20.248 23.9952 20.7731 23.3131 20.7731H20.899V6.2882C20.899 4.30261 19.2634 2.66699 17.2778 2.66699H10.0354ZM10.0354 5.08109H17.2778C17.9598 5.08109 18.4848 5.60613 18.4848 6.28816V8.7023H8.82828V6.28816C8.82828 5.60613 9.35332 5.08109 10.0354 5.08109ZM18.4848 11.1164H8.82828V26.8084H18.4848V11.1164Z"
          fill="#C6CBD4"
        />
      </svg>
    </div>
  );
}

export default CarFuelIcon;
