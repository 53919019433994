import React,{ useEffect,useState} from "react";
import { Tabs } from "antd";
import "./user-profile.css";
import Titleheader from "../atoms/TitleHeader/titleheader";
import UserprofileComponent from "../components/User-Profile/userprofileComponent";
const { TabPane } = Tabs;

function callback(key) {
  
}

function UserProfile(props) {
  const hostURL = process.env.REACT_APP_SERVER_URL;
  const [userProfile,setUseProfile] =useState();
const [getToken, setGetToken] = useState(localStorage.getItem("Token"));
const [getUserid, setGetUserid] = useState(localStorage.getItem("userid"));

useEffect(() => {
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };
    
    fetch(`https://viabovemij-api.azurewebsites.net/api/CompanyProfile/Profie/${JSON.parse(getUserid)}`,
      requestGetOptions
    )
     .then((response) => response.json())
      .then((data) => {
        setUseProfile(data);
       
      })
}, []);

 
  return (
    <div className="wrapper" data-testid = "user-profile-container">
      <div className="mybids-user-container">
        <section>
          <div
            className="container-top-header"
            style={{ background: "#fcfcfc" }}
          >
            <div className="title-heading-right-block">Mijn profiel</div>
            <div className="title-heading-left-block"></div>
          </div>
        </section>
        <Tabs defaultActiveKey="1" onChange={callback}>
          {/* <TabPane tab="Bewerk profiel" key="1"> */}
          <TabPane tab="Gebruikersprofiel" key="1">
            <UserprofileComponent userProfile={userProfile}/>
          </TabPane>
            {/* <TabPane tab="Apps" key="2">
              Content of Tab Pane 2
            </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
}

export default UserProfile;
