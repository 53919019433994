import { forwardRef, useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./updatebutton.css";
/**
 * for minbid code
 */
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
/**
 * for minbidcode ends
 */

/**
 * need to declare outside as a module scoped global variable,
 * else will get reset during rerender causing issue, else clearTimeout wont work
 *
 */

let setPopupWarningMessageToNullCancelToken = null;
let setErrorMessageToNullCancelToken = null;

export default function UpdateButton({
  bidInfoPage,
  bidId,
  accesstoken,
  disableOption,
  minBid,
  handleBitChange,
}) {

  let [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });

  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });

  const [setBidInfo] = useState("");
  let [errorMessage, setErrorMessage] = useState(null);

  let updateBidAmt = bidInfoPage?.bidAmount
    ? parseInt(bidInfoPage?.bidAmount, 10)
    : 0;

  minBid = bidInfoPage.minBid !== undefined || null ? bidInfoPage.minBid : 0;
  const minBidAmount = minBid !== undefined ? minBid : 0;
  // const minBidAmount = 400;
  const [bidAmounts, setBidAmount] = useState(0);

  const [disableSaveButton, setDisableSaveButton] = useState(
    bidAmounts < minBidAmount
  );
  const minBidTitle = "Minimum bod :";
  const ErrorMessage = `Plaats een bod van minimaal ${formatter.format(
    minBidAmount
  )}`;

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    setBidAmount(updateBidAmt);
  }, [updateBidAmt]);

  const handleSubmit = (e) => {
    if (!disableSaveButton) {
      setState({ open: true });
      // setBidAmount(bidAmounts);
      const params = {
        BidId: `${bidId}`,
        caseTypeID: "BVE-DMP-BidMgmt-Work-BidMgmt",
        processID: "pyStartCase",
        content: {
          CaseData: {
            LastBidAmount: updateBidAmt,
            BidAmount: bidAmounts > minBid ? bidAmounts : minBid,
            minBid: parseInt(minBid),
            KeyIndicator: "Modify",
          },
        },
      };

      const requestOptionsvalue = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: accesstoken,
        },
        body: JSON.stringify(params),
      };
      const hostURL = process.env.REACT_APP_SERVER_URL;
      fetch(hostURL + `/api/bidmanager/Bids/Updatebid`, requestOptionsvalue)
        .then((response) => response.json())
        .then((data) => setBidInfo(data));
      const payIdArr = params.BidId.split(" ");
      let payId = "";
      if (payIdArr.length > 0) {
        payId = payIdArr[1];
      }
      handleBitChange(payId);
    }
  };

  const handleClose = () => {
    setState({ open: false });
  };

  const convertToNum = (textValue) => {
    if (!textValue || typeof textValue == "number") {
      return textValue;
    } else {
      let num = textValue.replace(/[^0-9]/g, "");
      if (num.length > 0) {
        return parseInt(num, 10);
      } else {
        return null;
      }
    }
  };

  const clearExistingTimeout = () => {
    if (setPopupWarningMessageToNullCancelToken) {
      clearTimeout(setPopupWarningMessageToNullCancelToken);
      setPopupWarningMessageToNullCancelToken = null;
    }
    if (setErrorMessageToNullCancelToken) {
      clearTimeout(setErrorMessageToNullCancelToken);
      setErrorMessageToNullCancelToken = null;
    }
  };

  /**
   * onchangeInput
   */
  const onHandleErrorChange = (e) => {
    /**
     * we have to use e.target.value
     * everywhere instead of saving in variable to resolve
     * wrong message issue due to setTimeout storing value
     */

    if (
      convertToNum(_.isEmpty(e.target) ? e : e.target.value) >= minBidAmount
    ) {
      clearExistingTimeout();
      setErrorMessage(null);
    } else if (
      convertToNum(_.isEmpty(e.target) ? e : e.target.value) < minBidAmount
    ) {
      clearExistingTimeout();
      setErrorMessage(ErrorMessage);
      setErrorMessageToNullCancelToken = setTimeout(() => {
        setErrorMessage(null);
      }, 6000);
    }
  };

  const onHandleChange = (updatedBidValue) => {
    let convertedBidValue = convertToNum(updatedBidValue);
    if (!convertedBidValue || convertedBidValue < minBidAmount) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }

    setBidAmount(convertedBidValue);
  };

  let incrementBidAmount = () => {
    let updatedValue = bidAmounts >= minBid ? Number(bidAmounts) + 50 : minBid;
    onHandleErrorChange(updatedValue);
    onHandleChange(updatedValue);
  };

  let decrementBidAmount = () => {
    let updatedValue = bidAmounts > minBid ? Number(bidAmounts) - 50 : minBid;
    onHandleErrorChange(updatedValue);
    onHandleChange(updatedValue);
  };

  const debouncedChangeHandler = _.debounce(onHandleErrorChange, 1000);

  return (
    <div className="available-update-button mybids updateButtonForm" 
    data-testid = "update-button-parent"
    >
      <div
        className={
          disableOption
            ? "full-container-update disable"
            : "full-container-update"
        }
      >
        <button
          className="dec-amt updateButtonDecAmt"
          onClick={decrementBidAmount}
          type="button"
          disabled={bidAmounts <= minBidAmount}
        >
          {" "}
          <div class="material-icons">remove</div>
        </button>

        <div
          id="input-block-border"
          className={`inputWraper ${
            errorMessage == null ? "warningInfoSection" : "errorInfoSection"
          }`}
        >
          {errorMessage ? (
            <Tooltip
              open={errorMessage ? true : false}
              arrow
              classes={{
                tooltip: "customUpdateButtonTooltip",
                arrow: "customUpdateButtonToolTipArrow",
              }}
              placement="top"
              title={errorMessage ? errorMessage : null}
            >
              <ReportProblemIcon />
            </Tooltip>
          ) : null}

          <input
            id="amount-input-validation"
            className="bid-amount-container"
            required
            onKeyUp={(e) => debouncedChangeHandler(e)}
            onChange={(e) => onHandleChange(e.target.value)}
            value={formatter.format(bidAmounts)}
            type="text"
          ></input>
        </div>
        <button className="inc-amt" onClick={incrementBidAmount}>
          {" "}
          <div class="material-icons">add</div>
        </button>
        <span
          class={`material-icons-outlined ${
            disableSaveButton ? "disabledSaveButton" : null
          }`}
          onClick={(e) => handleSubmit(e)}
        >
          save
        </span>
      </div>

      <Snackbar
        open={state.open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={state.vertical + state.horizontal}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Bid update is in progress...
        </Alert>
      </Snackbar>
    </div>
  );
}
