/* eslint-disable import/no-anonymous-default-export */
let responeInfo;
const hostURL = process.env.REACT_APP_SERVER_URL;
const logoutURL = process.env.REACT_APP_LOGOUTAPI_URL;
const hostLogoutURL = process.env.REACT_APP_LOGOUT_HOST_URL;

const handleLogout = (auth) => {
  // localStorage.removeItem("StockReload");
  const url = hostURL + `/api/logout/logout`;
  send(url, auth)
    .then((response) => {
      logoutRedirection();
    })
    .catch(() => {
      logoutRedirection();
    });
};

const logoutRedirection = () => {
  window.localStorage.clear();
  window.location.href =
    logoutURL +
    `/login/api/terminate_session?post_logout_redirect_uri=` +
    hostLogoutURL;
};

const send = (url, auth, reqType = "GET", body = "") => {
  let requestContent = {
    method: reqType,
    headers: {
      "Content-Type": "application/json",
      Authorization: auth,
    },
  };
  if (reqType === "POST") {
    requestContent.body = body;
  }
  return fetch(url, requestContent)
    .then((response) => {
      const responseContentType = response.headers.get("Content-Type");
      if (!response.ok) {
        if (response.status === 401) {
          if (!url.includes("/logout")) {
            handleLogout(auth);
          }
          return Promise.reject({
            status: 401,
          });
        }
        if (
          responseContentType &&
          responseContentType.includes("application/json")
        ) {
          return response.json().then((data) => {
            return Promise.reject(data);
          });
        }
      }
      responeInfo = response;
      return response.json();
    })
    .then((data) => {
      return {
        info: responeInfo,
        data: data,
      };
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
export default { send, handleLogout };
