import { useRef, useEffect } from "react";
import _ from "lodash";
import Slider from "react-slick";
import "./Damageslide.css";
import SliderNextArrow from "./SliderNextArrow";
import SliderPrevArrow from "./SliderPrevArrow";
import defaultImage from '../../../images/Damage-part-photo-not-available-big.svg'
const imageDominURL = process.env.REACT_APP_TESTAPI_URL;

export default function DamageSlide(props) {
  const { damages, setActiveSlideForParent, selectedIndex, setTitle } = props;

  const currentSlide = useRef();
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    nextArrow: (
      <div className="sliderArrow">
        <SliderNextArrow width={"25"} height={"40"} color={"#333333"} />
      </div>
    ),
    prevArrow: (
      <div className="sliderArrow">
        <SliderPrevArrow width={"25"} height={"40"} color={"#333333"} />
      </div>
    ),
    afterChange: (current) => {
      setActiveSlideForParent(current + 1);
      const partName = damages[current].part;
      setTitle(partName);
    },
  };

  useEffect(() => {
    // for directly jump to the slide
    if (currentSlide.current) {
      currentSlide.current.slickGoTo(selectedIndex);
      setActiveSlideForParent(selectedIndex + 1);
      const partName = _.get(damages[selectedIndex], "part" ,"");
      setTitle(partName)
    }
  }, [damages, selectedIndex, setActiveSlideForParent, setTitle]);
  return (
    <div className="content" data-testid="damage-slider">
      <Slider
        {...sliderSettings}
        ref={(slider) => (currentSlide.current = slider)}
      >
        {damages.map((card, index) => (
          <div key={index}>
            <img
              className="sliderImage"
              data-testid="damage-slider-img"
              alt={card.part}
              src={_.get(card, "media.urlFullSize") ? imageDominURL + `${_.get(card, "media.urlFullSize", "")}` : defaultImage}
              
            />
            <p className="sliderDescription">{_.get(card, "description")}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}
