import React, { useState, useEffect } from "react";
import "./mybids-table.css";
import localization from "moment/locale/nl";
import moment from "moment";
import dateFormat from "dateformat";


import { Table } from "antd";
import ArchivalUpdateButton from "../../atoms/UpdateButton/archivalupdatebutton";

function MybidArchiveTable(props) {
  const [rowFilterdata, setRowFilterdata] = useState([]);
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const getToken = localStorage.getItem("Token");
  const getUserid = localStorage.getItem("userid");

  const testURL = process.env.REACT_APP_TESTAPI_URL;
  const locationHistory = window.location.search;

  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      title: "",
      dataIndex: "border",
    },
    {
      title: <span className="mybid-table-theader">Foto</span>,
      dataIndex: "photo",
    },
    {
      title: <span className="mybid-table-theader">Kenteken</span>,
      dataIndex: "plate",
    },

    {
      title: <span className="mybid-table-theader">Merk</span>,
      dataIndex: "brand",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        a?.brand?.props?.children !== null
          ? (a?.brand?.props?.children).localeCompare(
              b?.brand?.props?.children
            )
          : null,
    },
    {
      title: <span className="mybid-table-theader model-style">Model</span>,
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        a?.model?.props?.children !== null
          ? (a?.model?.props?.children).localeCompare(
              b?.model?.props?.children
            )
          : null,
    },
    ,
    {
      title: <span className="mybid-table-theader">Status</span>,
      dataIndex: "status",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a.status?.props?.children).localeCompare(
          b.status?.props?.children
        ),
    },
    {
      title: (
        <span className="mybid-table-theader">
          Startdatum <br />en -tijd
        </span>
      ),
      dataIndex: "start_date_time",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => {
        let t1 =
          a.start_date_time?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];

        let t2 =
          b.start_date_time?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];
        return new Date(t1)-new Date(t2)
      },
    },
    {
      title: (
        <span className="mybid-table-theader">
          Einddatum <br />en -tijd
        </span>
      ),
      dataIndex: "end_date_time",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => {
        let t1 =
          a.end_date_time?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];

        let t2 =
          b.end_date_time?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];
        
            return new Date(t1)-new Date(t2)
      },
    },
    {
      title: <span className="mybid-table-theader">Jouw bod</span>,
      dataIndex: "bidamount",
      sortDirections: ["ascend", "descend", "ascend"],

      sorter: (a, b) => {
        let a_value =
          a.bidamount?.props?.children?.props?.bidInfoPage
            .bidAmount;

        let b_value =
          b.bidamount?.props?.children?.props?.bidInfoPage
            .bidAmount;

        return a_value-b_value
      },
    },
    {
      dataIndex:"timer",
    },

    {
      title: <span className="mybid-table-theader">Laatst bijgewerkt</span>,
      dataIndex: "lastupdate",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        let t1 = a.lastupdate?.props?.children.map((x) => x)[0]
          ?.props?.children;

        let t2 = b.lastupdate?.props?.children?.map((x) => x)[0]
          ?.props?.children;

        return new Date(t1) - new Date(t2);
      }
      },
    {
      title: "",
      dataIndex: "action",
    },
  ];

  useEffect(() => {
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    const hostURL = process.env.REACT_APP_SERVER_URL;
    fetch(
      hostURL + `/api/bidmanager/Bids/All/${JSON.parse(getUserid)}`,
      requestGetOptions
    )
      .then((response) => response.json())
      .then((data) => {
        const dataviewall = data;
        var columnsDetails = [];

        dataviewall?.pxResults.map((rowApi, key) => {
          const nowDate = moment(new Date());
          const lastupdateDate = moment(
            new Date(rowApi.bidInfoPage.lastUpdated)
          );
          let enddate = moment(new Date(rowApi.bidInfoPage.auctionEndDtTime));
          let startDate = moment(new Date(rowApi.bidInfoPage.auctionStartDtTime));
          const lastdatediffrence = moment.duration(
            nowDate.diff(lastupdateDate)
          );
          const totalhours = 24;
          const hourslastdatediffrence = lastdatediffrence.days();
          const remaininglastdatediffrence =
            hourslastdatediffrence * totalhours;

          moment.locale("nl", localization);
          const enddateFormat = moment(
            rowApi.bidInfoPage.auctionEndDtTime
          ).format("ll");
          const endhoursFormat = moment(
            rowApi.bidInfoPage.auctionEndDtTime
          ).hours();
          const endminutesFormat = moment(
            rowApi.bidInfoPage.auctionEndDtTime
          ).minutes();

          const startdateFormat = moment(
            rowApi.bidInfoPage.auctionStartDtTime
          ).format("ll");
          const starthoursFormat = moment(
            rowApi.bidInfoPage.auctionStartDtTime
          ).hours();
          const startminutesFormat = moment(
            rowApi.bidInfoPage.auctionStartDtTime
          ).minutes();
          const coloumData = {
            key: key,
            border: (
              <div
                style={{
                  borderLeft: "3px solid #A6A6A6",
                  height: "59px",
                
                
                }}
                //  className={(rowApi.bidInfoPage.bidStatus === 'lost') && 'lost-border-style'}
              ></div>
            ),
            photo: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
                <div  className="mybid-image-holder">
                  <img
                    src={testURL + `${rowApi.bidInfoPage.url}`}
                    
                    alt="data-not-found"
                  />
                </div>
              // </Link>
            ),
            plate: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
              <div style={{ marginLeft: "12px" }} >{rowApi.pxPages.vehicle.licenceplate}</div>
              // </Link>
            ),
            brand: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
                <div style={{ marginLeft: "10px" }}>{rowApi.pxPages.vehicle.brand}</div>
              // </Link>
            ),
            model: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
                <div style={{ marginRight:"21px"}} >{rowApi.pxPages.vehicle.model}</div>
              // </Link>
            ),
            status: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
                <div style={{marginRight:"24px"}}>Geannuleerd</div>
              // </Link>
            ),
            start_date_time: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
              <div style={{ marginLeft: "8px" }}>
                <span className="date-format">
                <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
                </div>
              // </Link>
            ),
            end_date_time: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
              <div style={{ marginLeft: "8px" }}>
                <span className="date-format">
                <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>
                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
                </div>
              // </Link>
            ),
            bidamount: (
              <div style={{ marginLeft: "16px" }} >
              <ArchivalUpdateButton
                bidInfoPage={rowApi?.bidInfoPage}
                disableOption={true}
              />
              </div>
            ),
            timer: (
              <div style={{ marginRight: "50px",width:"93px" }} >
              
              </div>
            ),
            lastupdate: (
              // <Link
              //   to={{
              //     pathname: `/lost/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
              //     state: {
              //       licencePlate: rowApi.pxPages.vehicle.licenceplate,
              //       vehicleId: rowApi.bidInfoPage.vehicleId,
              //       BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
              //       bidId: rowApi.pyID,
              //       enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
              //     },
              //   }}
              // >
                <div style={{ marginLeft: "14px", fontSize: " 10.55px" }}>
                  <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                  {dateFormat(lastupdateDate, "dd-mm-yy") +

                " " +

                dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}</div>
              // </Link>
            ),
            action: "",
          };
          if (
            rowApi.bidInfoPage.bidStatus === "Cancelled" ||
            rowApi.bidInfoPage.IsArchived === "true"
          )
            columnsDetails.push(coloumData);
        });
        setRowFilterdata(columnsDetails);
      });
  }, [getToken, getUserid, testURL]);

  const data = rowFilterdata;
  function onChange(pagination, filters, sorter, extra) {}
  return (
    <div className="archiveTable" data-testid = "mybid-archive-table-container">
      <Table
       scroll={{x:100}}
        columns={columns}
        dataSource={data}
        onChange={onChange}
        class="disableRow"
        pagination={false}
      />
    </div>
  );
}

export default MybidArchiveTable;
