import React from "react";
import "./damageIcon.css";

function DamageCarIcons({ isDamage }) {
  return (
    <div className="damageIconWrapper">
      {isDamage ? (
        <svg
          width="27"
          height="27"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.36095 3.00005C4.93033 3.00301 4.54291 3.24335 4.37552 3.61138L2.99 6.65238L1.52448 5.29302L0 6.70707L2.08457 8.64065L0.846458 11.3613C0.790703 11.4844 0.762066 11.6165 0.762233 11.75V18V20C0.762233 20.552 1.24522 21 1.84032 21H3.99649L3.99017 19H19.096L19.0897 21H21.2458C21.8388 21 22.3239 20.55 22.3239 20V18V11.5625C22.3238 11.357 22.2554 11.1565 22.1281 10.9883L24 9.83204L22.804 8.16799L20.7363 9.44729L19.2939 7.8594L20.054 6.4473C20.3203 5.95328 20.1045 5.35255 19.5719 5.10553L19.5718 5.10551L17.4157 4.10552C17.2965 4.0501 17.1673 4.01567 17.0346 4.00396L5.46832 3.00396C5.43261 3.00101 5.39677 2.9997 5.36093 3.00006L5.36095 3.00005ZM5.92245 5L16.4609 5.926L17.4679 6.40087L16.8742 7.52077C16.6821 7.88247 16.7365 8.31728 17.0126 8.62683L18.24 10H13.2488L4.8 7.50495L5.92245 5ZM12.9016 11.6975L4.0507 9L2.88 11.6954V17H20.16V11.7447H13.2244C13.115 11.7449 13.0061 11.729 12.9016 11.6975ZM6.24 12C5.44471 12 4.8 12.6716 4.8 13.5C4.8 14.3284 5.44471 15 6.24 15C7.03529 15 7.68 14.3284 7.68 13.5C7.68 12.6716 7.03529 12 6.24 12ZM15.84 15C15.0451 15 14.4 14.552 14.4 14H17.28C17.28 14.552 16.6349 15 15.84 15Z"
            fill="#D15744"
          />
        </svg>
      ) : (
        <svg
          width="25"
          height="25"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.92 1.01C15.72 0.42 15.16 0 14.5 0H3.5C2.84 0 2.29 0.42 2.08 1.01L0 7V15C0 15.55 0.45 16 1 16H2C2.55 16 3 15.55 3 15V14H15V15C15 15.55 15.45 16 16 16H17C17.55 16 18 15.55 18 15V7L15.92 1.01ZM16 12H2V7H16V12ZM14.14 2H3.85L2.77 5.11H15.22L14.14 2ZM15 9.5C15 10.3284 14.3284 11 13.5 11C12.6716 11 12 10.3284 12 9.5C12 8.67157 12.6716 8 13.5 8C14.3284 8 15 8.67157 15 9.5ZM4.5 11C5.32843 11 6 10.3284 6 9.5C6 8.67157 5.32843 8 4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11Z"
            fill="#27AD7A"
          />
        </svg>
      )}
    </div>
  );
}

export default DamageCarIcons;
