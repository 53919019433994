import React from "react";

function ArrowPrevCarpassportCarousal(props) {
  return (
    <div
      style={props.styleProp}
      className="custom-slick-prev"
      onClick={props.onClick}
    >
      <svg
        width={props?.modal === true ? "25" : "24"}
        height={props?.modal === true ? "40" : "25"}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="previous-icon-image"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.5098 4.36961L15.7298 2.59961L5.83984 12.4996L15.7398 22.3996L17.5098 20.6296L9.37984 12.4996L17.5098 4.36961Z"
          fill={props?.modal === true ? "#333333" : "#90a0b7"}
        />
      </svg>
    </div>
  );
}

export default ArrowPrevCarpassportCarousal;
