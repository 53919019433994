import React from 'react';
import './singleLotsCard.css'

function SingleLotCardFooter(props) {
    return (
        <div data-testid="singleLotCardFooter">
            <section className="singleLotCardBody-container">
            </section>
        </div>
    );
}

export default SingleLotCardFooter;