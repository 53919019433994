import React from "react";

function CheckallIcon({ checkboxAllRef }) {
  return (
    <div
      style={{ cursor: "pointer"}}
      className="checkallicon-filter"
    >
      <svg
        width="22"
        height="21"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 2V11H5V2H14ZM14 0.5H5C4.175 0.5 3.5 1.175 3.5 2V11C3.5 11.825 4.175 12.5 5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5ZM8.3525 9.5L5.75 6.875L6.8 5.8175L8.3525 7.3775L12.2 3.5L13.25 4.5575L8.3525 9.5ZM2 3.5H0.5V14C0.5 14.825 1.175 15.5 2 15.5H12.5V14H2V3.5Z"
          fill="#90A0B7"
        />
      </svg>
    </div>
  );
}

export default CheckallIcon;
