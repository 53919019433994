import React from "react";

import "../../style.css";

export default function CarLicensePlate({ licencePlate }) {


  
  return (
    <div className="carpassport-licencePlate-contanier">
      <div className="carpassport-licencePlate-blue"></div>
      <div className="carpassport-licencePlate-yellow">
        <span className="carpassport-licencePlate-GY-W9">{licencePlate}</span>
      </div>
    </div>
  );
}
