import React from "react";
import { Typography } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Redirect } from "react-router";

const locationHistory = window.location.search;
const getToken = localStorage.getItem("Token");
const username = localStorage.getItem("userid");
const linkStateData = JSON.parse(localStorage.getItem("stateKey"));

export const steps = [
  {
    content: (
      <>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Start met de online tour!{" "}
        </Typography>
      </>
    ),

    locale: {
      back: (
        <strong aria-label="back" className="back_container-input">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    stepIndex: 0,
    target: "body",
    placement: `center`,
    options: {
      arrowColor: "#fcfcfc",
      backgroundColor: "#27AD7A",
    },
  },
  
  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 1 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Lopende veilingen: het aantal veilingen waarin nu op voertuigen kan
          worden geboden. Beoordeel de voertuigen en plaats een bod. Aankomende
          veilingen: het aantal veilingen, die binnenkort starten. Bekijk de
          aangeboden voertuigen. Gewonnen biedingen: Gefeliciteerd, het aantal
          gewonnen biedingen over de laatste 30, 60 of 90 dagen. Totaal
          uitstaande biedingen: de som van de bedragen van de uitstaande
          biedingen, waarvan het resultaat van de bieding nog niet bekend..{" "}
        </Typography>
      </>
    ),

    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    stepIndex: 1,
    target: ".auction-dashboard-hot-matrics",
    options: {
      arrowColor: "#fcfcfc",
      backgroundColor: "#27AD7A",
    },
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 2 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Overzicht van de voertuigen waar op je hebt geboden. Bieding kunnen
          voor het tijdstip van einde van de veiling nog worden aangepast of
          ingetrokken. Klik hiervoor op de bieding of op "Toon alles".{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: ".auction-dashboard-mybids",
    placement: "right",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 3 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Een overzicht van alle lopende en aankomende veilingen. Klik op het
          kaartje om alle auto's in die veiling te bekijken.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: ".auction-dashboard-matrics",
    placement: "right",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 4 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Selecteer het kaartje van de veiling waarin je geinteresseerd bent. Je
          kunt ook meerdere veilingen tegelijk selecteren.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: ".auction-dashboard-matrics-card.upcoming",
    placement: "top",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 4 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Is de veiling gestart, dan is ook de resterende looptijd zichtbaar.
          Aan de hand van resterend looptijd van de veiling zal de kleur van de
          balk van oranje naar rood verkleuren. Beoordeel de voertuigen en
          plaats een bod?{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {/* <Link to={`/AvailableCars`}> */}
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
          {/* </Link> */}
        </strong>
      ),
    },
    target: ".auction-dashboard-matrics-card.running",
    placement: "right",
    stepIndex: 4,
  },

  {
    content: (
      <>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Via beschikbare voertuigen vind je een overzicht van alle beschikbare
          voertuigen in de komende en lopende veilingen
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="close" className="back_container page-change">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          <Link to={"/AvailableCars"}>
            {" "}
            {"Klik boven en dan volgende" + " "}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "-1px" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
                fill="white"
              />
            </svg>
          </Link>
        </strong>
      ),
    },
    target: "a#auction-available-cars",
    disableBeacon: true,
    disableOverlayClose: false,
    stepIndex: 5,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 5 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Selecteer het kaartje van de veiling waarin je geinteresseerd bent. Je
          kunt ook meerdere veilingen tegelijk selecteren.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: "label.auctionControlvalue.auction-upcoming",
    placement: "right",
    spotlightPadding: 0,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 5 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Is de veiling gestart, dan is ook de resterende looptijd zichtbaar.
          Aan de hand van resterend looptijd van de veiling zal de kleur van de
          balk van oranje naar rood verkleuren. Beoordeel de voertuigen en
          plaats een bod?{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: "label.auctionControlvalue.auction-running",
    placement: "right",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 6 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Beoordeel het voertuig en plaats direct een bod of klik op het kaartje
          voor meer informatie zoals foto's of eventuele schadeinformatie.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      next: (
        <strong aria-label="next" className="back_container.page-chang">
          <Link
            to={{
              pathname: `/carpassport/vehicleById/${linkStateData?.vehicleId}`,
              state: linkStateData,
            }}
          >
            {"Carpassport" + " "}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "-1px" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
                fill="white"
              />
            </svg>
          </Link>
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      last: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: ".ant-card.ant-card-bordered.running",
    placement: "right",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
          Stap 7 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Door te klikken op het informatie icon zie je een overzicht van alle
          kosten die van toepassing zijn.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container-input">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {/* <Link to={`/AvailableCars`}> */}
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
          {/* </Link> */}
        </strong>
      ),
    },
    target: ".carpassport-form-input",
    placement: "bottom",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
          Stap 8 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Via beschikbare voertuigen vind je een overzicht van alle beschikbare
          voertuigen in de komende en lopende veilingen
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {/* <Link to={"/AvailableCars"}> */}
          {"Klik boven en dan volgende" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
          {/* </Link> */}
        </strong>
      ),
    },
    target: "span.carpassport-form-description-subtitle",
    placement: "right",
    disableBeacon: true,
    disableOverlayClose: false,
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 99999,
      },
    },
  },

  {
    content: (
      <>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Via beschikbare voertuigen vind je een overzicht van alle beschikbare
          voertuigen in de komende en lopende veilingen
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="close" className="back_container page-change">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Klik boven en dan volgende" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: "a#auction-available-cars",
    // placement: "left",
    disableBeacon: true,
    disableOverlayClose: false,
    stepIndex: 10,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
          Stap 9 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          De veiling van deze auto is nog niet gestart. Klik op het kaartje om
          meer details van de auto te bekijken zoals andere foto's of eventuele
          schadeinformatie.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
    },
    target: ".ant-card.ant-card-bordered.upcoming",
    placement: "left",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 10 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Een geplaatst bod kun je gemakkelijk voor het einde van de veiling
          wijzigen. Klik op de plus of min of typ een nieuw bedrag in.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="back_container.page-chang">
          <Link
            to={{
              pathname: `/update/vehicleById/${linkStateData?.vehicleId}`,
              state: linkStateData,
            }}
          >
            {"Carpassport" + " "}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "-1px" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
                fill="white"
              />
            </svg>
          </Link>
        </strong>
      ),
    },
    target: ".ant-card.ant-card-bordered.update",

    placement: "right",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 11 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Plaats een bod of wijzig een lopende bieding. Dit kan via de plus en
          de min of door het invoeren van een aangepast bedrag.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container-input">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {/* <Link to={`/AvailableCars`}> */}
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
          {/* </Link> */}
        </strong>
      ),
    },
    target: ".update-save-button",
    placement: "bottom",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 12 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Met de knop intrekken kun je de lopend bieding annuleren.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {/* <Link to={`/AvailableCars`}> */}
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
          {/* </Link> */}
        </strong>
      ),
    },
    target: "button.update-btn-default-submit.btn-danger.btn.btn-primary",
    placement: "bottom",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 13 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Beoordeel het voertuig en plaats direct een bod of klik op het kaartje
          voor meer informatie zoals foto's of eventuele schadeinformatie.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container-input">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      last: (
        <strong aria-label="next" className="next_container">
          {"My Bids" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: ".ant-card.ant-card-bordered.running",
    placement: "right",
  },

  {
    content: (
      <>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Via beschikbare voertuigen vind je een overzicht van alle beschikbare
          voertuigen in de komende en lopende veilingen
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="close" className="back_container page-change">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Klik boven en dan volgende" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: "a#mybid",
    // placement: "left",
    disableBeacon: true,
    disableOverlayClose: false,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    stepIndex: 15,
    disableScrolling: false,
    onclick: window.scroll(0, 100),
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 14 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          In dit overzicht kun je snel de status zien van alle biedingen. Zolang
          de veiling loopt, kun je hier je biedingen aanpassen of intrekken. Is
          de veiling gesloten dan krijgen de biedingen de status "In
          Behandeling". Als de resultaten bekend zijn, zal de status veranderen
          in "Gewonnen" of "Niet gewonnen".{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container-input">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),
      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: ".mybid-table",
    placement: "top",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 15 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          De veiling is nog niet gesloten. Je kunt je bieding nog wijzigen of
          annuleren.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: "a.running-status-option",
    placement: "left",
    disableScrolling: true,
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 16 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Pas hier direct je bod aan.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: ".available-update-button.mybids",
    placement: "left",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 17 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Trek hier je bod in om deze te annuleren. Nadat de annulering is
          bevestigd kun je het bod verplaatsen naar het archief.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
    },
    target: "span.cancel_runing",
    placement: "bottom",
  },

  {
    content: (
      <>
        <Typography variant="body1" margin="dense" className="step-container">
        Stap 18 of 18
        </Typography>
        <Typography
          variant="body1"
          margin="dense"
          className="step-container-subtitle"
        >
          Oude biedingen worden bewaard in het archief. Dit geldt voor
          geannuleerde, gewonnen of verloren biedingen.{" "}
        </Typography>
      </>
    ),
    locale: {
      back: (
        <strong aria-label="back" className="back_container">
          <svg
            width="8"
            height="12"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.51 1.86998L10.73 0.0999756L0.839996 9.99998L10.74 19.9L12.51 18.13L4.38 9.99998L12.51 1.86998Z"
              fill="#90A0B7"
            />
          </svg>{" "}
          <span className="back_subtitle">Terug</span>
        </strong>
      ),
      skip: (
        <strong aria-label="skip" className="skip_container">
          Annuleer
        </strong>
      ),

      close: (
        <strong aria-label="close" className="skip_container">
          close
        </strong>
      ),
      next: (
        <strong aria-label="next" className="next_container">
          {"Volgend" + " "}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "-1px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.867676 14.0975L2.19518 15.4249L9.62018 7.99995L2.19518 0.574951L0.867676 1.90245L6.96518 7.99995L0.867676 14.0975H0.867676Z"
              fill="white"
            />
          </svg>
        </strong>
      ),
      last: (
        <strong aria-label="last" className="next_container">
          <Link
          to=""
            onClick={() => {
              window.location.href = "/AuctionDashboard";
            }}
            // to={"/AuctionDashboard"}
          >
            {"Finish"}
          </Link>
        </strong>
      ),
    },
    target: ".mybid-archival-total-value",
    placement: "bottom",
  },
];
