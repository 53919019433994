import React, { useState, useEffect } from "react";
import _ from "lodash";
import CarousalIcon from "../../../../images/bovag_1.png";
import ArrowPrevCarpassportCarousal from "../../../Icons/arrowPrevCarpassportCarousal";
import ArrowNextCarpassportCarousal from "../../../Icons/arrowNextCarpassportCarousal";
import SliderZoomIcon from "../../../../images/fullscreen.svg";

import "./carpassportcarousal.css";

import Slider from "react-slick";

function CarpassportCarousal(props) {
  const testURL = process.env.REACT_APP_TESTAPI_URL;

  let medias = props?.mediaProp;

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [nav3, setNav3] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [slider3, setSlider3] = useState(null);
  const [index, setIndex] = useState(0);

  const onNextClick = () => {
    // medias?.map((media, index) => setIndex(index + 1));
  };
  const onPrevClick = () => {
    // medias?.map((media, index) => setIndex(index - 1));
  };

  const showModalProp = () => {
    props?.showModal();
  };

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    setNav3(slider3);
  });

  let prevArrow = {
    position: "absolute",
    top: "111%",
    zIndex: "100",
  };

  let nextArrow = {
    position: "absolute",
    top: "111%",
    zIndex: "100",
  };

  let prevArrowModal = {
    height: "80px",
    zIndex: "1000",
    width: "80px",
    position: "absolute",
    top: "52%",
    left: "0px",
    backgroundImage: "linear-gradient(to right,#ffffff, transparent)",
    textAlign: "center",
    padding: "16px",
  };

  let nextArrowModal = {
    height: "80px",
    zIndex: "1000",
    width: "80px",
    position: "absolute",
    top: "52%",
    right: "0px",
    backgroundImage: "linear-gradient(to left,#ffffff, transparent)",
    textAlign: "center",
    padding: "16px",
  };

  let settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-nav",
    prevArrow: (
      <ArrowPrevCarpassportCarousal
        onClick={onPrevClick}
        modal={props?.isModalVisible}
        styleProp={props?.isModalVisible !== true ? prevArrow : prevArrowModal}
      />
    ),
    nextArrow: (
      <ArrowNextCarpassportCarousal
        onClick={onNextClick}
        modal={props?.isModalVisible}
        styleProp={props?.isModalVisible !== true ? nextArrow : nextArrowModal}
      />
    ),
  };

  const settingsThumbs = {
    slidesToShow: props?.mediaProp?.length > 5 ? 5: props?.mediaProp?.length,
    slidesPerRow: 1,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    centerMode: true,
    dots: true,
    draggable: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    swipeToSlide: true,
    arrows: false,
  };
  const settingsFooter = {
    arrows: props?.isModalVisible === true ? true : false,
    speed: 0,
    prevArrow: <ArrowPrevCarpassportCarousal onClick={onPrevClick} />,
    nextArrow: <ArrowNextCarpassportCarousal onClick={onNextClick} />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div className="slider-wrapper">
        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          {!_.isEmpty(medias) ? (
            medias?.map((media, index) => {
              return (
                <div
                  id={index}
                  className={
                    props?.isModalVisible !== true
                      ? "slick-slide"
                      : "slick-slide-modal"
                  }
                  key={index}
                >
                  <div className="slider-zoom-icon-container">
                    {props?.isModalVisible === true ? null : (
                      <img
                        className="zoom-icon-img-block"
                        onClick={showModalProp}
                        src={SliderZoomIcon}
                        alt="foto beschikbaar"
                      ></img>
                    )}
                  </div>
                  {_.isEmpty(props?.isModalVisible) &&
                  props?.isModalVisible === true ? (
                    <p className="slider-modal-modelType">
                      {props?.modelType}'s Photos
                    </p>
                  ) : null}
                  <div
                    className={
                      !_.isEmpty(props?.isModalVisible)
                        ? "carousalIcon-modal-container"
                        : "carousalIcon-container"
                    }
                  >
                    <img src={CarousalIcon} alt="carousalIcon" />
                  </div>
                  <img
                    className="slick-slide-image-big"
                    src={testURL + `${media?.urlFullSize}`}
                    onClick={showModalProp}
                    alt="foto beschikbaar"
                  />
                  
                </div>
              );
            })
          ) : (
            <span>No data found</span>
          )}
          
          
        </Slider>
        <div className="thumbnail-slider-wrap">
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
          >
            {!_.isEmpty(medias) ? (
              medias?.map((media, index) => {
                return (
                  <React.Fragment>
                    <div id={index} className="slick-slide" key={index}>
                      <img
                        className="slick-slide-image-small"
                        src={testURL + `${media?.url}`}
                        alt="foto beschikbaar"
                      />
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <span>No data found</span>
            )}
          </Slider>
        </div>
        {props?.isModalVisible === true ? (
          <Slider
            asNavFor={nav1}
            ref={(slider) => setSlider3(slider)}
            {...settingsFooter}
          >
            {!_.isEmpty(medias)
              ? medias?.map((media, index) => {
                  return (
                    
                    // <input type="hidden" value="Photos"></input>
                    <div className="no-of-photos-container">
            {_.isEmpty(props?.isModalVisible) &&
            props?.isModalVisible === true ? (
              <span className="carpassport-carousal-modal-no-of-photos">
                Fotos <strong>{index + 1}</strong> van {" "}
                <strong>{medias?.length}</strong>
              </span>
            ) : null}
          </div>
                    
                  );
                })
              : null}
          </Slider>
        ) : null}
      </div>
    </div>
  );
}

export default CarpassportCarousal;
