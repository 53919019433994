import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PopoverPopupState from "../popoverPopupState";

import "./place-a-bid.css";
import "../topHeader.css";

/**
 * need to declare outside as a module scoped global variable,
 * else will get reset during rerender causing issue, else clearTimeout wont work
 *
 */

let setPopupWarningMessageToNullCancelToken = null;
let setErrorMessageToNullCancelToken = null;

function PlaceABid({
  accesstoken,
  userid,
  catalogID,
  lotID,
  vehicleId,
  transmission,
  bidAmount,
  bidStatus,
  openForBidding,
  enddayofbidding,
  selectedVehicleIdProp,
  minBid,
  vehiclesDetail 
}) {
 
  const locationHistory = window.location.search;
  const history = useHistory();
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  let [errorMessage, setErrorMessage] = useState(null);
  const [bidInputDisableFlag, setBidInputDisableFlag] = useState(false);
 
  minBid = (!_.isUndefined(minBid) && !_.isNull(minBid) && minBid >0)  ? minBid :0;
 
  const [biddata, setBidData] = useState([]);
  const [bidValue, setBidValue] = useState();

  let [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
    success: "success",
  });
  const [snackMessage, setSnackMessage] = useState("");

  const minBidTitle = "Minimum bod";
  const minBidAmount = minBid !== undefined ? minBid : 0;
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
  const ErrorMessage = `Plaats een bod van minimaal  ${formatter.format(
    minBidAmount
  )}`;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onHandleChange = (e) => {
    let minbidInputCurrentValue = e.target.value;
    if (_.isEmpty(minbidInputCurrentValue) || minbidInputCurrentValue < minBidAmount) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }
    setBidValue(minbidInputCurrentValue);
  };

  const clearExistingTimeout = () => {
    if (setPopupWarningMessageToNullCancelToken) {
      clearTimeout(setPopupWarningMessageToNullCancelToken);
      setPopupWarningMessageToNullCancelToken = null;
    }
    if (setErrorMessageToNullCancelToken) {
      clearTimeout(setErrorMessageToNullCancelToken);
      setErrorMessageToNullCancelToken = null;
    }
  };
  /** onchangeInput*/
  const onHandleErrorChange = (e) => {
    // setErrorMessage(null);
    // setPopupWarningMessage(null);

    /**
     * we have to use e.target.value
     * everywhere instead of saving in variable to resolve
     * wrong message issue due to setTimeout storing value
     */
    if (e.target.value >= minBidAmount) {
      clearExistingTimeout();
      setErrorMessage(null);
    } else if (e.target.value < minBidAmount) {
      clearExistingTimeout();
      setErrorMessage(ErrorMessage);
      setErrorMessageToNullCancelToken = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  };

  const debouncedChangeHandler = _.debounce(onHandleErrorChange, 1000);

  const onSubmit = () => {
    setDisableSaveButton(true);
    setBidInputDisableFlag(true);
    if (bidValue < minBidAmount) {
      setErrorMessage(ErrorMessage);
      setBidValue("");
      return;
    }
    const params = {
      caseTypeID: "BVE-DMP-BidMgmt-Work-BidMgmt",
      processID: "pyStartCase",
      content: {
        CaseData: {
          bidAmount: bidValue ? Number(bidValue) : bidAmount,
          // UserID: userid,
          CatalogID: catalogID,
          VehicleId: vehicleId,
          LotID: lotID,
          BidStatus: bidStatus,
          AuctionStartDtTime: openForBidding,
          AuctionEndDtTime: enddayofbidding,
          minBid: minBid,
        },
      },
    };
    const requestOptionsvalue = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accesstoken,
      },
      body: JSON.stringify(params),
    };
    const hostURL = process.env.REACT_APP_SERVER_URL;
    fetch(hostURL + `/api/bidmanager/Bids/Placebid`, requestOptionsvalue)
      .then((response) => response.json())
      .then((placebiddata) => {
        setBidData(placebiddata);

        const bidId = placebiddata.ID;
        setState({ open: true, success: "success" });
        setSnackMessage("Bid has been placed successfully...");
        vehiclesDetail = vehiclesDetail.map((vehicle)=>{
          if(vehicle.itemInfo.id === vehicleId){
             vehicle.bidInfoPage = {bidAmount: Number(bidValue),
             bidStatus:"AWAITING RESULTS-P"
           }
          }
          return vehicle;
         });
        const timer = setTimeout(() => {
          history.push({
            pathname: `/carpassportslider/vehicleById/${vehicleId}`,
            state: {
              accesstoken,
              BidAmount: Number(bidValue),
              userid,
              openForBidding,
              catalogID,
              lotID,
              vehicleId,
              bidStatus,
              bidId,
              transmission,
              enddayofbidding,
              selectedVehicleIdProp,
              minBid,
              vehiclesDetail 
            },
          });
          window.location.reload(true);
        }, 5000);
      });
  };

  const handleClose = () => {
    setState({ open: false });
  };

  return (
    <>
      <div className="placeABidBidForm">

        <Typography
          variant="paragraph"
          gutterBottom
          component="paragraph"
          className="minBidInputTitle"
        >
          <span>PLAATS JE BOD</span>
          <span className="carpassport-form-popover-description-subtitle"> <PopoverPopupState/></span>

        
        </Typography>
        <div className="minBidForm">
          <TextField
            id="filled-basic"
            placeholder={`${minBidTitle} ${formatter.format(minBidAmount)}`}
            variant="filled"
            onKeyUp={(e) => debouncedChangeHandler(e)}
            onChange={(e) => onHandleChange(e)}
            value={bidValue}
            required={true}
            disabled={bidInputDisableFlag}
            min={minBidAmount}
            InputLabel={() => undefined}
            type="number"
            InputProps={{
              endAdornment:
                errorMessage ? (
                  <>
                    <InputAdornment
                      position="end"
                      className={`endInputSection ${
                        errorMessage
                          ? "errorIconSection"
                          : null
                      }`}
                    >
                      <div className="errorIconTextbox">
                        <Tooltip
                          open={
                            errorMessage ? true : false
                          }
                          arrow
                          classes={{
                            tooltip: "customTooltip",
                            arrow: "customToolTipArrow",
                          }}
                          placement="top"
                          title={
                            errorMessage ? errorMessage : null
                          }
                        >
                          <ReportProblemIcon />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  </>
                ) : null,
              inputProps: {
                style: {
                  padding: "8px 0px 8px 8px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontFamily: "GTWalsheimPro Medium",
                },
              },

              startAdornment: bidValue ? (
                <InputAdornment position="start">
                  <span className="static-value">€</span>
                </InputAdornment>
              ) : null,
            }}
          />
          <div className="bidButtonWrapper">
            <button
              type="submit"
              className="place-a-bid"
              disabled={disableSaveButton}
              onClick={() => onSubmit()}
            >
              <span className="place-a-bid-text">Bod plaatsen</span>
            </button>
          </div>

          
        </div>
        <Snackbar
          open={state.open}
          onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            key={state.vertical + state.horizontal}
          >
            <Alert severity={state.success} sx={{ width: "100%" }}>
              {snackMessage}
            </Alert>
          </Snackbar>
      </div>
    </>
  );
}

export default PlaceABid;
